import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, HostListener } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { HttpClient } from '@angular/common/http';
declare var $: any;
import { DomSanitizer } from '@angular/platform-browser';
import { AppCouponTourService } from 'src/app/shared/services/app-coupon-tour.service';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
	selector: 'app-store-coupons',
	templateUrl: './store-coupons.component.html',
	styleUrls: ['./store-coupons.component.scss'],
	providers: [DatePipe]
})
export class StoreCouponsComponent implements OnInit {
	name = 'Jquery Integration With Angular!';
	isJqueryWorking: any;
	store_name_slug: any;
	categories_list: any = [];
	branch_list: any = [];
	store_data: any;
	current_store_uid: any;
	current_layout_data: any;
	serializedData: any
	current_bundle_data: any;
	serializedBundleData: any =[];
	serializedMenuData: any =[];
	combinedData: any=[];
	current_menu_data: any;
	iframe_src: any;
	coupons_list: any;
	isDisplay = false;
	current_list_id: any;
	current_list_data: any;
	counter_img = ''
	qr_elementType: 'url' | 'canvas' | 'img' = 'url';
	qr_value: any;
	cart_ids = []
	cartItem = []
	showHelpIcon = true;
	show_add_item = false;
	filtered_items = [];
	serializedIds = [];
	grid: any;
	is_list_view = false;
	display_print_content = false
	selected_cart_items = [];
	isAllSelected = false
	textColor = 'white';
	all_items = [];
	all_product_items = [];
	no_content_display = false
	store_id: any
	show_loader = false
	isPreview: any
	display_social_icons = { "facebook": false, "instagram": false, "twitter": false, "youtube": false }
	show_dynamic_header_coupon: any;
	validCoupon: boolean;
	@ViewChild(HeaderComponent, { static: false }) headerComponent: HeaderComponent;
	displayCouponPage: boolean;
	show_dynamic_header: string;
	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		public sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private afStorage: AngularFireStorage,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private _snackBar: MatSnackBar,
		private http: HttpClient,
		private datePipe: DatePipe,
		private analytics: AngularFireAnalytics,
		private appCouponTourService: AppCouponTourService
	) { }

	// code for swipe left event (open shopping list)
	private startX: number;
	private startY: number;
	countLeft: number = 0;

	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:touchmove', ['$event'])
	onTouchMove(event: MouseEvent | TouchEvent) {
		if (document.documentElement.clientWidth <= 767) {
			if (this.startX === null || this.startY === null) {
				return;
			}

			const currentX = this.getCurrentX(event);
			const currentY = this.getCurrentY(event);
			const deltaX = currentX - this.startX;
			const deltaY = currentY - this.startY;

			if (Math.abs(deltaX) > 40) {
				const directionX = deltaX > 0 ? 'right' : 'left';
				if (directionX == 'left') {
					this.countLeft = this.countLeft + 1;
				}
				if (this.countLeft > 20) {
					// $("#ShopDropdown").animate({
					// 	right: "0"
					// });
					// $('.chervon-down-icon').addClass('d-none');
					// $('.chervon-up-icon').removeClass('d-none');
					this.countLeft = 0;
				}
			}
		}
	}

	onTouchStart(event: MouseEvent | TouchEvent) {
		this.startX = this.getCurrentX(event);
		this.startY = this.getCurrentY(event);
		this.countLeft = 0;
	}

	onTouchEnd(event: MouseEvent | TouchEvent) {
		this.startX = null;
		this.startY = null;
	}

	private getCurrentX(event: MouseEvent | TouchEvent): number {
		if (event instanceof MouseEvent) {
			return event.clientX;
		} else if (event instanceof TouchEvent) {
			return event.touches[0].clientX;
		}

		return 0;
	}

	private getCurrentY(event: MouseEvent | TouchEvent): number {
		if (event instanceof MouseEvent) {
			return event.clientY;
		} else if (event instanceof TouchEvent) {
			return event.touches[0].clientY;
		}

		return 0;
	}

	async ngOnInit() {
		localStorage.setItem("printButton",'NotDisplay');

		if (localStorage.getItem('showAppList') == 'true') {
			if (window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://')) {
				window.location.href = localStorage.getItem('appRedirectUrl');
				localStorage.setItem('showAppList', 'false');
				localStorage.removeItem('appRedirectUrl');
			} else {
			}
		} else {
			if (!(window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://'))) {
				this.route.data.subscribe(data => {
					if (data.showAppList === true) {
						localStorage.setItem('showAppList', 'true');
						localStorage.setItem('appRedirectUrl', window.location.href);
					} else {
						localStorage.setItem('showAppList', 'false');
						localStorage.removeItem('appRedirectUrl');
					}
				})
			}
		}

		this.store_name_slug = this.route.snapshot.params['store_name'];
		if (this.router.url == '/' + this.store_name_slug + '/coupons' || this.router.url == '/' + this.store_name_slug + '/coupons?preview=true') {
			this.showHelpIcon = false;
		}

		if (this.route.snapshot.params?.list_id) {
			this.current_list_id = this.route.snapshot.params?.list_id
		} else {
			this.current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
		}
		$(document).ready(() => {
			const body = document.body;
			const scrollUp = "scroll-up";
			const scrollDown = "scroll-down";
			let lastScroll = 0;

			window.addEventListener("scroll", () => {
				const currentScroll = window.pageYOffset;
				if (currentScroll <= 200) {
					body.classList.remove(scrollUp);
					body.classList.remove(scrollDown);
					return;
				}

				if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
					// down
					body.classList.remove(scrollUp);
					body.classList.add(scrollDown);

				} else if (
					currentScroll < lastScroll &&
					body.classList.contains(scrollDown)
				) {
					// up
					body.classList.remove(scrollDown);
					body.classList.add(scrollUp);

				}
				lastScroll = currentScroll;
			});

			const LocalURL = 'http://localhost:4200';
			const FireBaseURL = 'https://digital-circular-pro-admin.web.app';
			if (document.location.href == FireBaseURL + '/home') {
				$('.browse-menu').addClass('menu-active');
			}
			if (document.location.href == FireBaseURL + '/coupon-list') {
				$('.Coupons').addClass('menu-active');
				$('.bottom-header-section').addClass('d-none');
			}
			if (document.location.href == FireBaseURL + '/recipe-list') {
				$('.Recipes').addClass('menu-active');
				$('.bottom-header-section').addClass('d-none');
			}
		});

		await this.afs.collection('stores', ref => ref.where('company_name_slug', '==', this.store_name_slug)).snapshotChanges().subscribe(async (data: any) => {
			this.store_data =await  data.map(e => {
				if (e.payload.doc.data()['coupon_header_include'] === 1) {
					this.show_dynamic_header_coupon = 'show_dynamic_header';
				} else {
					this.show_dynamic_header_coupon = '';
				}
				return {
					uid: e.payload.doc.id,
					access_coupon: e.payload.doc.data()['access_coupon'],
					access_layout: e.payload.doc.data()['access_layout'],
					access_recipe: e.payload.doc.data()['access_recipe'],
					address: e.payload.doc.data()['address'],
					zip_code: e.payload.doc.data()['zip_code'],
					company_name_slug: e.payload.doc.data()['company_name_slug'],
					company_name: e.payload.doc.data()['company_name'],
					email: e.payload.doc.data()['email'],
					menu1_link: e.payload.doc.data()['menu1_link'] ? e.payload.doc.data()['menu1_link'] : "",
					menu1_name: e.payload.doc.data()['menu1_name'],
					menu2_name: e.payload.doc.data()['menu2_name'],
					menu2_link: e.payload.doc.data()['menu2_link'],
					menu3_name: e.payload.doc.data()['menu3_name'],
					menu3_link: e.payload.doc.data()['menu3_link'],
					menu4_name: e.payload.doc.data()['menu4_name'],
					menu4_link: e.payload.doc.data()['menu4_link'],
					menu5_name: e.payload.doc.data()['menu5_name'],
					menu5_link: e.payload.doc.data()['menu5_link'],
					menu6_name: e.payload.doc.data()['menu6_name'],
					menu6_link: e.payload.doc.data()['menu6_link'],
					menu7_name: e.payload.doc.data()['menu7_name'],
					menu7_link: e.payload.doc.data()['menu7_link'],
					menu8_name: e.payload.doc.data()['menu8_name'],
					menu8_link: e.payload.doc.data()['menu8_link'],
					menu9_name: e.payload.doc.data()['menu9_name'],
					menu9_link: e.payload.doc.data()['menu9_link'],
					parent_id: e.payload.doc.data()['parent_id'],
					type: e.payload.doc.data()['type'],
					store_logo: e.payload.doc.data()['store_logo'],
					phone: e.payload.doc.data()['phone'],
					social_facebook_url: e.payload.doc.data()['social_facebook_url'],
					social_twitter_url: e.payload.doc.data()['social_twitter_url'],
					social_instagram_url: e.payload.doc.data()['social_instagram_url'],
					social_youtube_url: e.payload.doc.data()['social_youtube_url'],
					theme_base_color: e.payload.doc.data()['theme_base_color'],
					branch_name_color: e.payload.doc.data().branch_name_color || "#666666",
					theme_text_color: e.payload.doc.data()['theme_text_color'],
					coupon_header_include: e.payload.doc.data()['coupon_header_include'],
					coupon_header_text: e.payload.doc.data()['coupon_header_text'],
					override_noad_layout: e.payload.doc.data().override_noad_layout ? e.payload.doc.data().override_noad_layout : false,
					displayCircularPage: e.payload.doc.data().displayCircularPage ? e.payload.doc.data().displayCircularPage : false,
					displayBundlePage: e.payload.doc.data().displayBundlePage ? e.payload.doc.data().displayBundlePage : false,
					displayMenuPage: e.payload.doc.data().displayMenuPage ? e.payload.doc.data().displayMenuPage : false,
					displayCouponPage: e.payload.doc.data().displayCouponPage ? e.payload.doc.data().displayCouponPage : false,
					displayRecipePage: e.payload.doc.data().displayRecipePage ? e.payload.doc.data().displayRecipePage : false,				};
			});
			if(this.store_data[0]?.displayCouponPage){
				this.displayCouponPage = true
				if(this.store_data[0]?.company_name){
					document.title = `Coupons - ${this.store_data[0]?.company_name}`;
					this.analytics.logEvent('page_view', { page_title:`Coupons - ${this.store_data[0]?.company_name}` });	
				}
			}else{
				this.displayCouponPage = false
				if(this.store_data[0]?.displayCircularPage){
					this.router.navigate(['/' + this.store_name_slug ]);
				}else if (this.store_data[0]?.displayBundlePage){
					this.router.navigate(['/' + this.store_name_slug + '/bundles']);
				}else if (this.store_data[0]?.displayMenuPage){
					this.router.navigate(['/' + this.store_name_slug + '/menu']);
				}else if (this.store_data[0]?.displayRecipePage){
					this.router.navigate(['/' + this.store_name_slug + '/recipes']);
				}
			}		  
			this.textColor = this.store_data[0]?.theme_text_color ? this.store_data && this.store_data[0]?.theme_text_color : 'white';
			if (this.textColor === 'white' || this.textColor === '#ffffff') {
				this.counter_img = "../../../assets//imgs/white-counter.svg"
			} else {
				this.counter_img = '../../../assets//imgs/black-counter.svg'
			}
			this.isDisplay = true;
			if (this.store_data[0].social_facebook_url !== "" && this.store_data[0].social_facebook_url !== undefined) { this.display_social_icons.facebook = true }
			if (this.store_data[0].social_twitter_url !== "" && this.store_data[0].social_twitter_url !== undefined) { this.display_social_icons.twitter = true }
			if (this.store_data[0].social_instagram_url !== "" && this.store_data[0].social_instagram_url !== undefined) { this.display_social_icons.instagram = true }
			if (this.store_data[0].social_youtube_url !== "" && this.store_data[0].social_youtube_url !== undefined) { this.display_social_icons.youtube = true }

			if (this.store_data && this.store_data.length > 0) {
				this.current_store_uid = this.store_data[0]?.uid;
				this.afs.collection('/stores', ref => ref.where('parent_id', '==', this.store_data[0]?.parent_id).orderBy('created_at', 'asc')).snapshotChanges().subscribe(data => {
					this.branch_list = data.map(e => {
						return {
							uid: e.payload.doc.id,
							branch_name: e.payload.doc.data()['branch_name'] ? e.payload.doc.data()['branch_name'] : "",
							branch_name_slug: e.payload.doc.data()['branch_name_slug'] ? e.payload.doc.data()['branch_name_slug'] : ""
						};
					});
				});

				this.afs.collection('/coupons', ref => ref.where('store_uid', '==', this.current_store_uid).where('is_publish', '==', true).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
					this.validCoupon = false;
					this.coupons_list = data.map(e => {
						const validTillTimestamp = e.payload.doc.data()['valid_till'].seconds * 1000;
						const validTillDate = new Date(validTillTimestamp);
						validTillDate.setHours(0, 0, 0, 0);
						const currentDate = new Date();
						currentDate.setHours(0, 0, 0, 0);
						if (validTillDate >= currentDate) {
							this.validCoupon = true;
							return {
								uid: e.payload.doc.id,
								coupon_image: e.payload.doc.data()['coupon_image'] ? e.payload.doc.data()['coupon_image'] : "https://via.placeholder.com/300x200/2e2e2e/2e2e2e",
								upc: e.payload.doc.data()['upc'],
								coupon_description: e.payload.doc.data()['coupon_description'],
								label: e.payload.doc.data()['label'],
								coupon_code: e.payload.doc.data()['coupon_code'],
								valid_from: e.payload.doc.data()['valid_from'],
								valid_till: e.payload.doc.data()['valid_till'],
							};
						} else {
							return {
								uid: e.payload.doc.id,
								valid_till: 'expired'
							}
						}
					});
					this.serializedIds = this.coupons_list.map(s => s.uid);
					if (this.coupons_list.length > 0) {
						this.showHelpIcon = true;
						if (this.router.url == '/' + this.store_name_slug + '/coupons') {
							//   this.appCouponTourService.startTour();	
						}
					}
					// this.headerComponent.ngOnInit()
				});
				this.current_bundle_data = await this.getBundleData();
				this.current_menu_data = await this.getMenuData();
				let start_date = new Date();
				if (!this.store_data[0].override_noad_layout) {
					this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).snapshotChanges().subscribe(data => {
						this.current_layout_data = []
						data.forEach((docs: any) => {
							const layout_start_date = docs.payload.doc.data().start_date.toDate()
							const endDate = docs.payload.doc.data().end_date.toDate()
							if (layout_start_date <= start_date && endDate > start_date && (docs.payload.doc.data().layout_json.length > 0 || docs.payload.doc.data().layout_type == 'page')) {
								this.current_layout_data.push(docs.payload.doc.data())
							}
						})
						this.serializedData = this.current_layout_data[0]?.layout_json;
						this.serializedMenuData = this.current_menu_data[0]?.menu_json;
						this.serializedBundleData = this.current_bundle_data[0]?.bundle_json;
						this.combinedData = [];
						if(this.serializedData?.length>0){
							this.combinedData.push(...this.serializedData);
						   }
						if(this.serializedBundleData?.length>0){
							this.combinedData.push(...this.serializedBundleData);
						   }
						if(this.serializedMenuData?.length>0){
							this.combinedData.push(...this.serializedMenuData);
						  }
						console.log(this.serializedData, "serializedData")
						console.log(this.serializedMenuData, "serializedMenuData")
						console.log(this.serializedBundleData, "serializedBundleData")
						this.serializedIds = this.combinedData?.map(s => s.id)
						this.store_id = this.current_layout_data[0]?.store_uid;

						if (this.current_layout_data[0]?.layout_type == "grid") {
							this.iframe_src = this.sanitizer.bypassSecurityTrustResourceUrl("assets/dclive.html?store_uid=" + this.current_store_uid + "&template_id=" + this.current_layout_data[0]?.uid + "&r=11");
						} else {
							this.iframe_src = "";
						}
					});
				}
				else {
					this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid)).snapshotChanges().subscribe((data: any) => {
						this.current_layout_data = data.map(e => {
							return {
								uid: e.payload.doc.id,
								selected_product_id: e.payload.doc.data().selected_product_id ? e.payload.doc.data().selected_product_id : '',
								layout_json: e.payload.doc.data().layout_json ? e.payload.doc.data().layout_json : [],
								layout_type: e.payload.doc.data().layout_type ? e.payload.doc.data().layout_type : '',
								include_header_checkbox: e.payload.doc.data().include_header_checkbox,
								start_date: e.payload.doc.data().start_date,
								end_date: e.payload.doc.data().end_date,
								header_text: e.payload.doc.data().header_text,
								terms: e.payload.doc.data().terms ? e.payload.doc.data().terms : '',
								layout_json_temp: e.payload.doc.data().layout_json_temp ? e.payload.doc.data().layout_json_temp : '',
								is_publish: e.payload.doc.data().is_publish ? e.payload.doc.data().is_publish : '',
								page1_type: e.payload.doc.data().page1_type ? e.payload.doc.data().page1_type : '',
								page2_type: e.payload.doc.data().page2_type ? e.payload.doc.data().page2_type : '',
								page3_type: e.payload.doc.data().page3_type ? e.payload.doc.data().page3_type : '',
								page4_type: e.payload.doc.data().page4_type ? e.payload.doc.data().page4_type : '',
								page5_type: e.payload.doc.data().page5_type ? e.payload.doc.data().page5_type : '',
								page1_url: e.payload.doc.data().page1_url ? e.payload.doc.data().page1_url : '',
								page2_url: e.payload.doc.data().page2_url ? e.payload.doc.data().page2_url : '',
								page3_url: e.payload.doc.data().page3_url ? e.payload.doc.data().page3_url : '',
								page4_url: e.payload.doc.data().page4_url ? e.payload.doc.data().page4_url : '',
								page5_url: e.payload.doc.data().page5_url ? e.payload.doc.data().page5_url : '',
								last_save_timestamp: e.payload.doc.data().last_save_timestamp,
								last_publish_timestamp: e.payload.doc.data().last_publish_timestamp,
								store_uid: e.payload.doc.data().store_uid
							};
						});
						this.serializedData = this.current_layout_data[0]?.layout_json;
						this.serializedMenuData = this.current_menu_data[0]?.menu_json;
						this.serializedBundleData = this.current_bundle_data[0]?.bundle_json;
						this.combinedData = [];
						if(this.serializedData?.length>0){
							this.combinedData.push(...this.serializedData);
						   }
						if(this.serializedBundleData?.length>0){
							this.combinedData.push(...this.serializedBundleData);
						   }
						if(this.serializedMenuData?.length>0){
							this.combinedData.push(...this.serializedMenuData);
						  }
						console.log(this.serializedData, "serializedData")
						console.log(this.serializedMenuData, "serializedMenuData")
						console.log(this.serializedBundleData, "serializedBundleData")
						this.serializedIds = this.combinedData?.map(s => s.id)
					})
				}
			}
		});
		this.add_item_change();
		setTimeout(() => {
			if (this.is_list_view) {
				this.ShowShoppingList()
			}
			this.display_print_content = true
		}, 3500)
		this.loadCartItem()

		this.afs.collection('/lookup-categories').snapshotChanges().subscribe(data => {
			this.categories_list = data.map(e => {
				return {
					uid: e.payload.doc.id,
					value: e.payload.doc.data()['value'],
				};
			});
		});
	}
	async getBundleData() {
		const start_date = new Date();
		const layout_data = await this.afs.collection('/bundles', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

		const layouts = layout_data.docs.map(doc => doc.data());

		const current_layout = layouts.filter((docs: any) => {
			if (docs.include_header_checkbox) {
				this.show_dynamic_header = 'show_dynamic_header';
			} else {
				this.show_dynamic_header = '';
			}

			const layout_start_date = docs.start_date.toDate();
			const endDate = docs.end_date.toDate();

			return layout_start_date <= start_date && endDate > start_date;
		});
		return current_layout;
	}
	async getMenuData() {
		const start_date = new Date();
		const layout_data = await this.afs.collection('/menus', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

		const layouts = layout_data.docs.map(doc => doc.data());

		const current_layout = layouts.filter((docs: any) => {
			if (docs.include_header_checkbox) {
				this.show_dynamic_header = 'show_dynamic_header';
			} else {
				this.show_dynamic_header = '';
			}

			const layout_start_date = docs.start_date.toDate();
			const endDate = docs.end_date.toDate();

			return layout_start_date <= start_date && endDate > start_date;
		});
		return current_layout;
	}
	hendle_Add_item(type) {
		if (type === 'add') {
			this.show_add_item = true
		}
		if (type === 'close') {
			this.show_add_item = false
			this.filtered_items = []
		}
	}

	showLoader(val) {
		this.show_loader = val
	}

	async add_to_list(item) {
		if (this.cart_ids.includes(item.uid)) {
			this._snackBar.open('Selected item is already present in your list', '', {
				duration: 2000,
			});
		} else {
			item.hide_addtolist_label = item.hide_addtolist_label ? item.hide_addtolist_label : ''
			item.price_format_value1 = item.price_format_value1 ? item.price_format_value1 : ''
			item.price_format_value2 = item.price_format_value2 ? item.price_format_value2 : ''
			item.price_format_value3 = item.price_format_value3 ? item.price_format_value3 : ''
			item.price_label_format = item.price_label_format ? item.price_label_format : ''
			this.show_loader = true
			let msg = this.make_price_message(item?.price_label_format, item?.price_format_value1, item?.price_format_value2, item?.price_format_value3)
			this.cartItem.push({ product: item, count: 1, static: false, priceMessage: msg })
			this.cart_ids.push(item?.uid)
			if (this.current_list_id !== '') {
				await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
				});
			}
			else {
				this.createCartproduct();
			}
			this.show_loader = false
			if (this.serializedIds.includes(item.uid)) {
				let main_item = document.getElementById(item.uid)
				let title_bar: any = document.getElementById(item.uid).parentElement.childNodes[3]
				let delete_icon = main_item.parentElement.lastElementChild.getElementsByTagName('img')
				delete_icon[0].src = '../../../assets/imgs/right.png'
				title_bar.classList.add("test")
			}
			this.filtered_items = []
			this._snackBar.open('Item added in your list', '', {
				duration: 2000,
			});
		}
	}

	createCartproduct() {
		this.afs.collection('/list').add({ items: this.cartItem }).then(docRef => {
			const itemRefx2 = this.afs.doc('/list/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			localStorage.setItem('list_id', docRef.id)
			this.current_list_id = docRef.id
			this.qr_value = window.location.href + '/list/' + this.current_list_id;
		});
	}
	make_price_message(formate, value1, value2, value3) {
		if ((value1 !== null || value1 !== '') && (value2 !== null || value2 !== '')) {
			if (formate === 'format6') {
				return `Buy ${value1} Get ${value2} ${value3}`
			}
			if (formate === 'format2' || formate === 'format3' || formate === 'format4') {
				return `$${value1}.${value2}`
			}
			if (formate === 'format5') {
				return `${value1} for $${value2}`
			}
		}
		if (formate === 'format1' && (value1 !== null || value1 !== '')) {
			return `${value1}`
		}
		if ((formate == '' || formate == null) && (value1 == null || value1 == '')) {
			return ''
		}
	}

	copy_link() {
		var textarea = document.createElement("textarea");
		textarea.textContent = window.location.href + '/list/' + this.current_list_id;
		textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			document.execCommand("copy");  // Security exception may be thrown by some browsers.
		}
		catch (ex) {
			console.warn("Copy to clipboard failed.", ex);
		}
		finally {
			document.body.removeChild(textarea);
		}
	}

	async add_item_change() {
		if (this.all_items.length < 1) {
			await this.afs.collection('/coupons').snapshotChanges().subscribe(data => {
				this.all_items = data.map(e => {
					return {
						uid: e.payload.doc.id,
						coupon_image: e.payload.doc.data()['coupon_image'] ? e.payload.doc.data()['coupon_image'] : "https://via.placeholder.com/300x200/2e2e2e/2e2e2e",
						upc: e.payload.doc.data()['upc'],
						coupon_description: e.payload.doc.data()['coupon_description'],
						label: e.payload.doc.data()['label'],
						coupon_code: e.payload.doc.data()['coupon_code'],
						valid_from: e.payload.doc.data()['valid_from'],
						valid_till: e.payload.doc.data()['valid_till'],
					};
				});

			})
		}

	}
	async remove_one_from_cart(i, type) {
		this.show_loader = true
		if (type === 'one') {
			this.cartItem.splice(this.cartItem.indexOf(i), 1)
			if (!i.static && this.serializedIds.includes(i.product.uid)) {
				let title_bar: any = document.getElementById(i.product.uid).parentElement.childNodes[3]
				title_bar.classList.remove("test")
				this.cart_ids.splice(this.cart_ids.indexOf(i.product.uid), 1)
				document.getElementById(i.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
			}
		}
		if (type == 'selected') {
			this.cartItem = this.cartItem.filter(itm => {
				if (!this.selected_cart_items.includes(itm)) {
					return true
				} else {
					this.cart_ids.splice(this.cart_ids.indexOf(i.product.uid), 1)
					if (this.serializedIds.includes(itm.product.uid)) {
						let title_bar: any = document.getElementById(itm.product.uid).parentElement.childNodes[3]
						title_bar.classList.remove("test")
						document.getElementById(itm.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
						return false
					}
				}
			})
		}
		if (type === 'all') {
			this.cartItem.forEach(itm => {
				if (!itm.static && this.serializedIds.includes(itm.product.uid)) {
					let title_bar: any = document.getElementById(itm.product.uid).parentElement.childNodes[3]
					title_bar.classList.remove("test")
					this.cart_ids.splice(this.cart_ids.indexOf(itm.product.uid), 1)
					document.getElementById(itm.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
				}
			})
			this.cartItem = []
		}
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
		this.show_loader = false
	}

	sendMail() {
		if (this.current_list_id) {
			let sub = 'Digital Circular Shopping List'
			let body = 'Here is a link to your Digital Circular Shopping List:' + window.location.href + '/list/' + this.current_list_id;
			return `mailto:?subject=${sub}&body=${body}`
		}
	}

	async increase_product(i) {
		this.show_loader = true
		let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
		temp_itm.count += 1
		this.cartItem[this.cartItem.indexOf(i)] = temp_itm
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
		this.show_loader = false
		// localStorage.setItem('cart-item', JSON.stringify(this.cartItem))
	}

	async decrease_product(i) {
		this.show_loader = true
		let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
		if (temp_itm.count > 1) {
			temp_itm.count -= 1
			this.cartItem[this.cartItem.indexOf(i)] = temp_itm
		} else if (temp_itm.count === 1) {
			this.cartItem.splice(this.cartItem.indexOf(i), 1)
			if (!temp_itm.static && this.serializedIds.includes(i.product.uid)) {
				let title_bar: any = document.getElementById(i.product.uid).parentElement.childNodes[3]
				title_bar.classList.remove("test")
				this.cart_ids.splice(this.cart_ids.indexOf(i.product.uid), 1)
				document.getElementById(i.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
			}
		}
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
		this.show_loader = false
	}
	product_select_change(event, type, product) {
		if (event.target.checked && (type === 'all')) {
			this.selected_cart_items = this.cartItem
			this.isAllSelected = true
		} else if (!event.target.checked && (type === 'all')) {
			this.selected_cart_items = []
			this.isAllSelected = false
		} else if (event.target.checked && (type === 'single') && this.serializedIds.includes(product.product.uid)) {
			this.selected_cart_items.push(product)
			let title_bar: any = document.getElementById(product.product.uid).parentElement.childNodes[3]
			title_bar.classList.add("test")
			document.getElementById(product.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/right.png"
		} else if (!event.target.checked && (type === 'single') && this.serializedIds.includes(product.product.uid)) {
			this.selected_cart_items.splice(this.selected_cart_items.indexOf(product), 1)
			let title_bar: any = document.getElementById(product.product.uid).parentElement.childNodes[3]
			title_bar.classList.remove("test")
			this.cart_ids.splice(this.cart_ids.indexOf(product.product.uid), 1)
			document.getElementById(product.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
		}
	}
	async loadCartItem() {
		if (this.current_list_id !== '') {
			this.qr_value = window.location.href + '/list/' + this.current_list_id;
			await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
				this.current_list_data = data.map(e => {
					return {
						uid: e.payload.doc.id,
						items: e.payload.doc.data().items,
					};
				});
				this.current_list_data = this.current_list_data[0]
				this.cartItem = this.current_list_data.items
				this.current_list_data.items.forEach(i => {
					if (!i.static) {
						this.cart_ids.push(i.product.uid)
					}
				})
			});
		}
	}

	getImage(val) {
		if (!this.cartItem.some(x => x?.product?.uid == val)) {
			return '../../assets/imgs/plus.png'
		}
		else {
			return '../../assets/imgs/right.png'
		}
	}

	async addToCart(val) {
		if (this.current_list_id != '') {
			if (!this.cartItem.some(x => x.product.uid == val.uid)) {
				let data = { ...val }
				let valid_from1 = data.valid_from.seconds * 1000
				let valid_till1 = data.valid_till.seconds * 1000
				data.valid_from = this.datePipe.transform(valid_from1, 'MM/dd/yyyy')
				data.valid_till = this.datePipe.transform(valid_till1, 'MM/dd/yyyy')
				data.isExpired = "No"
				this.cartItem.push({ product: data, count: 1, static: false, isCoupon: true })
				this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
				});
				this.loadCartItem();
			} else {
				this.cartItem = this.cartItem.filter((data) => {
					return data.product.uid !== val.uid.toString()
				});
				this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
				});
				await this.loadCartItem();
			}
		}
		else {
			await this.createCart(val)
		}
		this.analytics.logEvent('@shoppingList_addCoupon_count', {
			message: 'Coupon add successfully.'
		})
	}

	async createCart(val) {
		let data = { ...val }
		let valid_from1 = data.valid_from.seconds * 1000
		let valid_till1 = data.valid_till.seconds * 1000
		data.valid_from = this.datePipe.transform(valid_from1, 'MM/dd/yyyy')
		data.valid_till = this.datePipe.transform(valid_till1, 'MM/dd/yyyy')
		data.isExpired = "No"
		this.cartItem.push({ product: data, count: 1, static: false, isCoupon: true })
		await this.afs.collection('/list/').add({ items: this.cartItem }).then(docRef => {
			const itemRefx2 = this.afs.doc('/list/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			localStorage.setItem('list_id', docRef.id)
			this.current_list_id = docRef.id
			this.qr_value = window.location.href + '/list/' + this.current_list_id;
		});
		await this.loadCartItem();
	}

	async ShowShoppingList() {
		$("#ShopDropdown").animate({
			right: "0"
		});
		$('.chervon-down-icon').addClass('d-none');
		$('.chervon-up-icon').removeClass('d-none');

	}
	CloseShoppingList() {
		$("#ShopDropdown").animate({
			right: "-100%"
		});
		$('.chervon-up-icon').addClass('d-none');
		$('.chervon-down-icon').removeClass('d-none');
		this.filtered_items = []
	}
	goToHome() {
		this.router.navigate(['./home']);
	}
	goToCouponPage() {
		this.router.navigate(['./coupon-list']);
	}
	goToRecipePage() {
		this.router.navigate(['./recipe-list']);
	}
	allSelection() {
		$('.all-selection-btn a').addClass('current');
		$('.coupons-only-selection-btn a').removeClass('current');
		$('.items-list').removeClass('d-none');
	}
	couponSelection() {
		$('.all-selection-btn a').removeClass('current');
		$('.coupons-only-selection-btn a').addClass('current');
		$('.items-list').addClass('d-none');
		$('.with-coupon').removeClass('d-none');
		$('.with-coupon').addClass('d-block');
	}
	bottomSliderDiv() {
		$('.slider').toggleClass('close');
	}

	openHomePage() {
		this.router.navigate(['/' + this.store_name_slug]);
	}

	openCouponsPage() {
		this.router.navigate(['/' + this.store_name_slug + '/coupons']);
	}

	openRecipesPage() {
		this.router.navigate(['/' + this.store_name_slug + '/recipes']);
	}


	ShowNationalCoupons() {
		$(".national-coupon").addClass("active");
		$(".store-coupon").removeClass("active");
		$(".NationalCoupons").removeClass("d-none");
		$(".StoreCoupons").addClass("d-none");
	}
	ShowStoreCoupons() {
		$(".store-coupon").addClass("active");
		$(".national-coupon").removeClass("active");
		$(".NationalCoupons").addClass("d-none");
		$(".StoreCoupons").removeClass("d-none");
	}

}
