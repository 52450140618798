import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../../shared/services/auth.service";
import { Observable } from 'rxjs';
import { MatSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard  {

currenturl:any;
  constructor(
    public authService: AuthService,
    public router: Router,
    private _snackBar: MatSnackBar
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot, 
    state: RouterStateSnapshot): any {
    console.log('reachead gurard');
    console.log('AUTH SERVICE ---->>',this.authService);

    if(this.authService)
    {
        if(this.authService.isLoggedIn == true) {
            console.log('going to auth service - logged in - in guared');
            let user = JSON.parse(localStorage.getItem('userrole'));
            console.log('in guard',user);
            if(next.data.roles && next.data.roles.indexOf(user.role) === -1){
            this.router.navigate(["/sign-in"])
            }else{
              return true
             }
        }else{
          this.router.navigate(["/login"])
        }
    }
  }

}
