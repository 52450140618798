import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Injectable, NgZone } from '@angular/core';
import { User } from "../../shared/services/user";
// compat packages are API compatible with namespaced code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from "@angular/router";
import { MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
 
loading:boolean = false;
login_email = 0;
send_otp = 0;
userData: any; // Save logged in user data
userRole:any;
googleauthemail:any;  
loguserrole:any;
temp_user:any;
userdatatemp:any;
//loading:boolean = false;
show_email_error:boolean = false;
show_pass_error:boolean = false;
@ViewChild("mycheckbox") mycheckbox;

public currentUser: any;
	isChecked: any;
  constructor(
	public authService: AuthService,
	public afs: AngularFirestore,   // Inject Firestore service
	public afAuth: AngularFireAuth, // Inject Firebase auth service
	public router: Router,
	public ngZone: NgZone, // NgZone service to remove outside scope warning
	private _snackBar: MatSnackBar
  ) { 
	  this.afAuth.authState.subscribe(user => {
	  if (user) {
		this.userData = user;
		this.userRole = user;

		localStorage.setItem('user', JSON.stringify(this.userData));
		JSON.parse(localStorage.getItem('user'));
	  } else {
		localStorage.setItem('user', null);
		JSON.parse(localStorage.getItem('user'));
	  }
	})


  }

  ngOnInit() { } 

  signin(){
	this.loading = true;
	console.log('fdd');
  }


  SignIn(email, password) {
	console.log(email,password,"email & password")
	this.loading = true;
	if(email == "" && password == "")
	{
		this.show_email_error = true;
		this.show_pass_error = true;
		this.loading  = false;
		return false;
	}
	if(email == "" && password !="")
	{
		this.show_email_error = true;
		this.show_pass_error = false;
		this.loading  = false;
		return false;
	}
	if(password == "" && email !="")
	{
		this.show_email_error = false;
		this.show_pass_error = true;
		this.loading = false;
		return false;
	}
	return this.afAuth.signInWithEmailAndPassword(email, password)
	  .then((result) => {
		console.log(result,"resuly")
	  	this.show_pass_error = false;
	  	this.show_email_error =false;
		this.ngZone.run(() => {
			console.log("THEEMAIL LOGIN----",result.user.email);
		 this.temp_user = this.afs.collection("users").ref.where("email", "==", result.user.email).onSnapshot(snap =>{
		  snap.forEach(userRef => {
			console.log("userRef - snapshot----", userRef.data());
			//this.currentUser = userRef.data();
			//setUserStatus
			this.currentUser = userRef.data();
			this.SetUserData(userRef.data());
			console.log('normal uss',userRef.data());
			//this.setUserStatus(this.currentUser)
			
			// if(userRef.data()['role'] == "admin") {

			// 	console.log('admin here');
			// 	this.router.navigate(["dashboard"]); 

			// } else if (userRef.data()['role'] == "manager") {

			// 	console.log('manager here');
			// 	this.router.navigate(["manager/dashboard"]);

			// }else if(userRef.data()['role'] == "group"){
				
			// 	console.log('group owner here');
			// 	this.router.navigate(["store/dashboard"]);
			// }
			// else if(userRef.data()['role'] == "store"){
			// 	if(userRef.data()['can_login'] == "0")
			// 	{
			// 		this._snackBar.open("Error: Your account is blocked. Please contact admin for more info.", '', {
			// 	      duration: 5000,
			// 	    });
			// 	    this.loading= false;	

			// 	    this.authService.SignOut();		
			// 	    this.router.navigate(["/"]);	  
			// 		return false;
			// 	} 
			// 	if(userRef.data()['can_login'] == "1")
			// 	{
			// 		console.log('store owner here');
			// 		this.router.navigate(["store/dashboard"]);
			// 	}
				
			// }
			// else{
				
			// 	console.log('noooo');
			// 	this.router.navigate(["/"]);
			// }
			 this.router.navigate(["store-owner"]); //navigate to blank-user is not admin
			
		  })
		});
		  //this.router.navigate(['dashboard']);


		});
	  
	  }).catch((error) => {
	  	this.show_pass_error = false;
	  	this.show_email_error =false;
		console.log(error);
		let msg = 'Error';
		if(error.code=='auth/wrong-password')
		{
		  msg = 'Looks like the password is incorrect';
		} else if(error.code== 'auth/user-not-found'){
			msg = 'No user found with this details';
		} else {
		  msg = error.message;
		}
		this._snackBar.open("Error: "+msg, '', {
				      duration: 5000,
				    });
		this.loading = false;
	  })
  }

  

  SetUserData(user) {
   
	const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
	const userData = {
	  uid: user.uid,
	  email: user.email,
	  displayName: user.displayName?user.displayName:'NA',
	  photoURL: user.photoURL?user.photoURL:'',
	  emailVerified: user.emailVerified?user.emailVerified:true,
	  role:user.role?user.role:'admin',
	  can_login: user.can_login?user.can_login:"1",
	  store_uid: user.store_uid?user.store_uid:"",
	  group_uid: user.group_uid?user.group_uid:""
	}

	localStorage.setItem('userrole', JSON.stringify(userData));
	this.userRole = userData;
	return userRef.set(userData, {
	  merge: true
	})
  }

  isShow(){
	this.isChecked = this.mycheckbox.nativeElement.checked
  }
  AuthLogin(provider) {
	return this.afAuth.signInWithPopup(provider)
	.then((result) => {
	  console.log('result from google after login here',result);
	   this.ngZone.run(() => {
		  this.router.navigate(['dashboard']);
		})
	  this.SetUserDataGoogle(result.additionalUserInfo.profile,result.user,result.additionalUserInfo.isNewUser);
	console.log('g mail ',result.additionalUserInfo.profile);
	this.googleauthemail = result.additionalUserInfo.profile['email'];
	console.log('emailllll',this.googleauthemail);
	this.afs.collection("admins").ref.where("email", "==", this.googleauthemail).onSnapshot(snap =>{
		  snap.forEach(userRef => {

			this.currentUser = userRef.data();
			this.loguserrole = userRef.data()['role']; 
			// this.SetUserData(userRef.data());
			console.log('the g role >>>'+userRef.data()['role']+"<<<<");
		   
			  console.log('nwww', this.loguserrole);
				if(this.loguserrole == "admin") {
				  this.router.navigate(["/dashboard"]);
				// this.ngZone.run(() => this.router.navigate(["/dashboard"]));
				}else if(this.loguserrole == "consultant"){
				  this.router.navigate(["/consultant/dashboard"]);
				  //this.ngZone.run(() => this.router.navigate(["/consultant/dashboard"]));
				}else if(this.loguserrole == "dieter"){
				  this.router.navigate(["/register-as-consultant"]);
				  //this.ngZone.run(() => this.router.navigate(["/register-as-consultant"]));
				}
				else{
				  //no role
				  this.router.navigate(["/register-as-consultant"]);
				  // this.ngZone.run(() => this.router.navigate(["/register-as-consultant"])); 
				}
			

		  })
		});

	}).catch((error) => {
	  window.alert(error)
	})
  }

  GoogleAuth() {
	console.log('in google');
	return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

   SetUserDataGoogle(user,result,isNewUser) {
	console.log('resss',user);
	console.log('new',isNewUser);
	let role = "";

	this.afs.collection("users").ref.where("email", "==", user.email).onSnapshot(snap =>{
		  snap.forEach(userRef => {
			  role = userRef.data()['role'];
		  })
		});
	if(isNewUser){
	  role = "dieter";
	}
	let currenttimestamp = + new Date();
	const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.uid}`);
	const userData: any = {
	  uid: result.uid,
	  email: user.email,
	  displayName: user.name,
	  photoURL: user.picture?user.picture:'',
	  emailVerified: user.verified_email,
	}

	localStorage.setItem('userrole', JSON.stringify(userData));
	this.userRole = userData;
	return userRef.set(userData, {
	  merge: true
	})
  }




}