import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { EmptyLayoutComponent } from './core/layouts/empty/empty-layout.component';
import { DynamicLayoutComponent } from './core/layouts/dynamic/dynamic-layout.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { UsersComponent } from './components/users/users.component';


import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthGuard } from "./shared/guard/auth.guard";
import { ProfileComponent } from './components/profile/profile.component';
import { UserViewComponent } from './components/user-view/user-view.component';

////////////////////////


//ADMIN ROUTES
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StoreOwnersComponent as AdminStoreOwnersComponent } from './components/store-owners/store-owners/store-owners.component';
import { GroupOwnersComponent as AdminGroupOwnersComponent } from './components/admin/group-owners/group-owners.component';
import { SavedTemplatesComponent as AdminSavedTemplatesComponent } from './components/admin/saved-templates/saved-templates.component';
import { GroupOwnerDetailsComponent as AdminGroupOwnerDetailsComponent } from './components/admin/group-owner-details/group-owner-details.component';
import { CreateLogTemplateComponent as AdminCreateLogTemplateComponent } from './components/admin/create-log-template/create-log-template.component';
import { LogTemplateViewComponent as AdminLogTemplateViewComponent } from './components/admin/log-template-view/log-template-view.component';



//GROUP ROUTES
import { DashboardComponent as GroupDashboardComponent } from './components/group/dashboard/dashboard.component';
import { StaffComponent as GroupStaffComponent } from './components/group/staff/staff.component';
import { LogsComponent as GroupLogsComponent } from './components/group/logs/logs.component';
import { IncidentReportsComponent as GroupIncidentReportsComponent } from './components/group/incident-reports/incident-reports.component';

//STORE OWNER ROUTES
import { DashboardComponent as StoreDashboardComponent } from './components/store/dashboard/dashboard.component';
import { StaffComponent as StoreStaffComponent } from './components/store/staff/staff.component';
import { LogsComponent as StoreLogsComponent } from './components/store/logs/logs.component';
import { IncidentReportsComponent as StoreIncidentReportsComponent } from './components/store/incident-reports/incident-reports.component';


//GROUP OWNER ROUTES
import { DashboardComponent as ManagerDashboardComponent } from './components/manager/dashboard/dashboard.component';
import { StaffComponent as ManagerStaffComponent } from './components/manager/staff/staff.component';
import { LogsComponent as ManagerLogsComponent } from './components/manager/logs/logs.component';
import { IncidentReportsComponent as ManagerIncidentReportsComponent } from './components/manager/incident-reports/incident-reports.component';



//DCP START HERE
import { AddNewStoreComponent } from './components/admin/add-new-store/add-new-store.component';
import { StoreBranchViewComponent } from './components/admin/store-branch-view/store-branch-view.component';
import { AddNewBranchComponent } from './components/admin/add-new-branch/add-new-branch.component';
import { BranchEditComponent } from './components/admin/branch-edit/branch-edit.component';
import { StoreEditComponent } from './components/admin/store-edit/store-edit.component';
import { AllItemsComponent as AdminAllItemsComponent } from './components/admin/all-items/all-items.component';
import { ProductImagesComponent } from './components/admin/product-images/product-images.component';
import { ProductImageDetailComponent } from './components/admin/product-image-detail/product-image-detail.component';

import { DigitalCircularComponent as StoreDigitalCircularComponent  } from './components/store/digital-circular/digital-circular.component';
import { SavedItemsComponent as StoreSavedItemsComponent  } from './components/store/saved-items/saved-items.component';
import { CouponsComponent as StoreCouponsComponent  } from './components/store/coupons/coupons.component';
import { RecipesComponent as StoreRecipesComponent  } from './components/store/recipes/recipes.component';
import { StoreInfoComponent  } from './components/store/store-info/store-info.component';
import { RecipeAddComponent as StoreRecipeAddComponent  } from './components/store/recipe-add/recipe-add.component';
import { RecipeViewComponent as StoreRecipeViewComponent } from './components/store/recipe-view/recipe-view.component';
import { AllItemsComponent as StoreAllItemsComponent } from './components/store/all-items/all-items.component';

import { DigitalCircularComponent as GroupDigitalCircularComponent } from './components/group/digital-circular/digital-circular.component';
import { SavedItemsComponent as GroupSavedItemsComponent } from './components/group/saved-items/saved-items.component';
import { CouponsComponent as GroupCouponsComponent } from './components/group/coupons/coupons.component';
import { RecipesComponent as GroupRecipesComponent } from './components/group/recipes/recipes.component';
import { StoreInfoComponent as GroupStoreInfoComponent } from './components/group/store-info/store-info.component';
import { RecipeAddComponent as GroupRecipeAddComponent } from './components/group/recipe-add/recipe-add.component';
import { RecipeViewComponent as GroupRecipeViewComponent } from './components/group/recipe-view/recipe-view.component';

import { DigitalCircularNoadComponent as StoreDigitalCircularNoadComponent } from './components/store/digital-circular-noad/digital-circular-noad.component';
import { DigitalCircularUpcomingComponent as StoreDigitalCircularUpcomingComponent } from './components/store/digital-circular-upcoming/digital-circular-upcoming.component';
import { RecipeApiTestComponent as StoreRecipeApiTestComponent } from './components/store/recipe-api-test/recipe-api-test.component';
import { RecipeApiTestDetailComponent as StoreRecipeApiTestDetailComponent } from './components/store/recipe-api-test-detail/recipe-api-test-detail.component';

import { HomeComponent } from './components/home/home.component';
import { StoreHomeComponent } from './components/store-home/store-home.component';
import { StoreRecipesComponent  as FrontRecipesComponent} from './components/store-recipes/store-recipes.component';
import { StoreCouponsComponent as FrontCouponsComponent } from './components/store-coupons/store-coupons.component';
import { StoreBundlesComponent } from './components/store-bundles/store-bundles.component';
import { StoreMenuComponent } from './components/store-menu/store-menu.component';
import { StoreCircularComponent } from './components/store-circular/store-circular.component';
import { EditStoreProductComponent } from './components/store-owners/edit-store-product/edit-store-product.component';
import { EditBundleProductComponent } from './components/store-owners/edit-bundle-product/edit-bundle-product.component';
import { EditMenuProductComponent } from './components/store-owners/edit-menu-product/edit-menu-product.component';
// import { StoreCouponsPreviewComponent } from './components/preview/store-coupons-preview/store-coupons-preview.component';
// import { StoreRecipesPreviewComponent } from './components/preview/store-recipes-preview/store-recipes-preview.component';

const routes: Routes = [
 
  { path: '', component: HomeComponent },
  { path: 'login', component: SignInComponent },
  { path: 'store-owner', component: AdminStoreOwnersComponent , canActivate: [AuthGuard]},
  { path: ':store_name', component: StoreCircularComponent, data: { showHeaderStore: true } },
  { path: ':store_name/edit', component: EditStoreProductComponent , canActivate: [AuthGuard]},
  { path: ':store_name/bundles/edit', component: EditBundleProductComponent , canActivate: [AuthGuard]},
  { path: ':store_name/menu/edit', component: EditMenuProductComponent , canActivate: [AuthGuard]},
  { path: ':store_name/bundles', component: StoreBundlesComponent, data: { showHeaderBundle: true } },
  { path: ':store_name/menu', component: StoreMenuComponent, data: { showHeaderBundle: true } },
  // { path: ':store_name/circular', component: StoreCircularComponent, data: { showHeaderStore: true } },
  { path: ':store_name/coupons', component: FrontCouponsComponent, data: { showHeaderCoupon: true } },
  { path: ':store_name/recipes', component: FrontRecipesComponent },
  { path: ':store_name/recipes/:recipe_categories', component: FrontRecipesComponent},
  { path: ':store_name/list/:list_id', component: StoreHomeComponent, data: { showAppList: true }},
  { path: ':store_name/coupons/list/:list_id', component: FrontCouponsComponent },
  { path: ':store_name/recipes/list/:list_id', component: FrontRecipesComponent},
  { path: ':store_name/:id', component: StoreCircularComponent },
  // { path: ':store_name/circular/:id', component: StoreCircularComponent },
  { path: ':store_name/bundles/:id', component: StoreBundlesComponent },
  { path: ':store_name/menu/:id', component: StoreMenuComponent },

  // { path: ':store_name/coupons/:id', component: StoreCouponsPreviewComponent },
  // { path: ':store_name/recipes/:id', component: StoreRecipesPreviewComponent },

  { path: 'sign-in', component: SignInComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'admin/add-new-store', component: AddNewStoreComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
  { path: 'admin/add-new-branch/:id', component: AddNewBranchComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
  { path: 'admin/store-edit/:id',component: StoreEditComponent, data: {roles:["admin"]},canActivate: [AuthGuard] },
  { path: 'admin/branch-edit/:id',component: BranchEditComponent,data: {roles: ["admin"]},canActivate:[AuthGuard] },

  {
    path: '',
    component: DynamicLayoutComponent,
    children: [
     
      { path: 'dashboard', component: DashboardComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/dashboard', component: DashboardComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/store-owners', component: AdminStoreOwnersComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/group-owners', component: AdminGroupOwnersComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/saved-templates', component: AdminSavedTemplatesComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/group-owners/:id', component: AdminGroupOwnerDetailsComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/create-log-template', component: AdminCreateLogTemplateComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/log-template-view/:id', component: AdminLogTemplateViewComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/all-items', component: AdminAllItemsComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},
      { path: 'admin/product-images', component: ProductImagesComponent, data:{roles:["admin"]},canActivate:[AuthGuard]},
      { path: 'admin/product-image-detail', component: ProductImageDetailComponent, data:{roles:["admin"]},canActivate:[AuthGuard]},
      { path: 'admin/store-branch-view/:id', component: StoreBranchViewComponent ,data: {roles: ["admin"]}, canActivate: [AuthGuard]},


      { path: 'group/dashboard', component: GroupDashboardComponent ,data: {roles: ["group"]}, canActivate: [AuthGuard]},
      { path: 'group/staff', component: GroupStaffComponent ,data: {roles: ["group"]}, canActivate: [AuthGuard]},
      { path: 'group/logs', component: GroupLogsComponent ,data: {roles: ["group"]}, canActivate: [AuthGuard]},
      { path: 'group/incidents', component: GroupIncidentReportsComponent ,data: {roles: ["group"]}, canActivate: [AuthGuard]},

      { path: 'group/digital-circular',component: GroupDigitalCircularComponent,data: {roles:["group","admin"]},canActivate: [AuthGuard]},
      { path: 'group/saved-items', component: GroupSavedItemsComponent, data:{roles:["group","admin"]},canActivate: [AuthGuard]},
      { path: 'group/coupons', component: GroupCouponsComponent,data:{roles:["group","admin"]},canActivate:[AuthGuard]},
      { path: 'group/recipes', component: GroupRecipesComponent,data:{roles:["group","admin"]},canActivate: [AuthGuard]},
      { path: 'group/store-info',component: GroupStoreInfoComponent,data:{roles:["group","admin"]},canActivate:[AuthGuard]},
      { path: 'group/recipe-add',component: GroupRecipeAddComponent,data:{roles:["group","admin"]},canActivate:[AuthGuard]},
      { path: 'group/recipe-view/:id',component: GroupRecipeViewComponent,data:{roles:["group","admin"]},canActivate:[AuthGuard]},

      { path: 'manager/dashboard', component: ManagerDashboardComponent , data: {roles: ["manager"]}, canActivate: [AuthGuard]},
      { path: 'manager/staff', component: ManagerStaffComponent ,data: {roles: ["manager"]}, canActivate: [AuthGuard]},
      { path: 'manager/logs', component: ManagerLogsComponent ,data: {roles: ["manager"]}, canActivate: [AuthGuard]},
      { path: 'manager/incidents', component: ManagerIncidentReportsComponent ,data: {roles: ["manager"]}, canActivate: [AuthGuard]},

      {
        path: 'tables',
        loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
      },
       {
        path: 'charts',
        loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
      },
      
      {
        path: 'utilities',
        loadChildren: () => import('./utilities/utilities.module').then(m => m.UtilitiesModule)
      },
      {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
      }
    ]
  },
  /*
    Undefined routes (should redirect to a 404 page)
  */
  { path: '**', redirectTo: 'sign-in' }
];

@NgModule({
  // Only call RouterModule.forRoot() in the root AppRoutingModule (or the AppModule if that's where you register
  // top level application routes). In any other module, you must call the RouterModule.forChild method to register additional routes.
  imports: [
    RouterModule.forRoot(routes, {
    // If you want to preload all lazy routes when the app loads, uncomment the following line
    // preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    // This value is required for server-side rendering to work.
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
