import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PwaService } from 'src/app/shared/services/pwa.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppTourService } from 'src/app/shared/services/app-tour.service';
import { AppRecipeTourService } from 'src/app/shared/services/app-recipe-tour.service';
import { AppCouponTourService } from 'src/app/shared/services/app-coupon-tour.service';
import { AppShoppinglistTourService } from '../services/app-shoppinglist-tour.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
declare var $: any;
let deferredPrompt: any;
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	providers: [DatePipe]
})
export class HeaderComponent implements OnInit, OnDestroy {

	@Input() store_data: any = [
		{
			theme_base_color: 'rgb(139, 195, 74)'
		}
	];
	@Input() branch_list: any;
	store_name_slug;
	item_name: any;
	current_list_id: any
	current_layout_id: any;
	is_list_view: any;
	@Input() cartItem: any
	@Input() past_item: any
	@Input() textColor: any
	@Input() current_layout_data: any
	@Input() store_category: any;
	@Input() serializedIds: any;
	@Input() cart_ids = []
	@Input() packDetails = []
	@Input() selected_cart_items = [];
	selected_past_cart_items = []
	@Input() serializedData: any
	@Input() serializedBundleData: any
	@Input() serializedMenuData: any
	@Input() coupons_list: any;
	@Input() showHelpIcon: boolean = true;
	current_list_data: any
	@Output() increaseProduct = new EventEmitter;
	@Output() decreaseProduct = new EventEmitter;
	@Output() categoryList = new EventEmitter;
	show_add_item = false
	no_content_display = false
	@Input() all_items: any;
	@Output() addToList = new EventEmitter;
	@Output() productChange = new EventEmitter<{ event: any, type: any, product: any }>();
	@Input() categories_list: any
	@Output() removeFilter = new EventEmitter;
	@Output() showLoader = new EventEmitter;
	@Output() closeRecipeboxPanel: EventEmitter<void> = new EventEmitter<void>();

	tabName = ''
	onlyCoupon = false
	isRecipe = false
	onlyRecipe = false
	isAllSelected = false
	isAllPastSelected = false
	qr_value = ''
	urlParam: any;
	printCart = false;
	isCoupon = false
	filtered_items = []
	store_background_color = `#76b101`
	groupList: any;
	groupBranch: any = [];
	@ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
	isIos_hide_button: any;
	isIos_hide_button_chIOS: any;
	setInstallable: boolean = false;
	subscription: Subscription
	isAndroid: boolean;
	isIos: boolean;
	showHeaderStore: boolean = false;
	showHeaderCoupon: boolean = false;
	showHeaderBundle:boolean = false;
	store_item: any;
	private tourSeenFlag = 'tourSeen';
	private recipeTourSeenFlag = 'RecipeTourSeen';
	private CouponTourSeenFlag = 'CouponTourSeen';
	private BundlesTourSeenFlag = 'BundleTourSeen';
	private MenuTourSeenFlag = 'MenuTourSeen';
	private shoppingTourSeenFlag = 'shoppingTourSeen';
	currentCategory: any;
	list: any;
	apck: Subscription;
	gridItemsArray: Element[];
	gridContent: string;
	print: boolean = false;
	matching_items: any;
	grid: any;
	createdCart: any;
	printDisplay: string;
	current_store_uid: any;
	store_data1: any;
	displayRecipePage:boolean=false;
	displayCouponPage:boolean=false;
	displayCircularPage:boolean=false;
	displayBundlePage:boolean=false;
	displayMenuPage:boolean=false;
	combinedData: any=[];
	showCategory: boolean = true;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public afs: AngularFirestore,
		private _snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private datePipe: DatePipe,
		public dialog: MatDialog,
		private pwaService: PwaService,
		private appTourService: AppTourService,
		private appRecipeTourService: AppRecipeTourService,
		private appCouponTourService: AppCouponTourService,
		private appShoppingListTourService: AppShoppinglistTourService,
		private analytics: AngularFireAnalytics,
	) { }

	// code for swipe right event (close shopping list)
	private startX: number;
	private startY: number;
	countRight: number = 0;

	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:touchmove', ['$event'])
	onTouchMove(event: MouseEvent | TouchEvent) {
		if (document.documentElement.clientWidth <= 767) {
			if (this.startX === null || this.startY === null) {
				return;
			}

			const currentX = this.getCurrentX(event);
			const currentY = this.getCurrentY(event);
			const deltaX = currentX - this.startX;
			const deltaY = currentY - this.startY;

			if (Math.abs(deltaX) > 40) {
				const directionX = deltaX > 0 ? 'right' : 'left';
				if (directionX == 'right') {
					this.countRight = this.countRight + 1;
				}
				if (this.countRight > 15) {
					this.CloseShoppingList();
					this.countRight = 0;
				}
			}
		}
	}

	onTouchStart(event: MouseEvent | TouchEvent) {
		this.startX = this.getCurrentX(event);
		this.startY = this.getCurrentY(event);
		this.countRight = 0;
	}

	onTouchEnd(event: MouseEvent | TouchEvent) {
		this.startX = null;
		this.startY = null;
	}

	private getCurrentX(event: MouseEvent | TouchEvent): number {
		if (event instanceof MouseEvent) {
			return event.clientX;
		} else if (event instanceof TouchEvent) {
			return event.touches[0].clientX;
		}

		return 0;
	}

	private getCurrentY(event: MouseEvent | TouchEvent): number {
		if (event instanceof MouseEvent) {
			return event.clientY;
		} else if (event instanceof TouchEvent) {
			return event.touches[0].clientY;
		}

		return 0;
	}

	async ngOnInit() {
		this.applyDefaultColor();
		(window as any).openPWA = this.openPWA.bind(this);
		if (localStorage.getItem('showAppList') == 'true') {
			if (window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://')) {
				window.location.href = localStorage.getItem('appRedirectUrl');
				localStorage.setItem('showAppList', 'false');
				localStorage.removeItem('appRedirectUrl');
			} else {
			}
		} else {
			if (!(window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://'))) {
				this.route.data.subscribe(data => {
					if (data.showAppList === true) {
						localStorage.setItem('showAppList', 'true');
						localStorage.setItem('appRedirectUrl', window.location.href);
					} else {
						localStorage.setItem('showAppList', 'false');
						localStorage.removeItem('appRedirectUrl');
					}
				})
			}
		}

		// code for disable enable recipe page
		this.store_name_slug = this.route.snapshot.params.store_name;
		if (this.router.url == '/' + this.store_name_slug + '/bundles'|| this.router.url == '/' + this.store_name_slug + '/bundles' + '/edit') {
			this.showCategory = false
		}else{
			this.showCategory = true
		}
			this.store_data1 = await this.afs.collection('/stores', ref => ref.where('company_name_slug', '==', this.store_name_slug)).get().toPromise()
			.then(storeData => {
				return storeData.docs.map(doc => doc.data());
			});
		console.log(this.store_data1, "store_data1");
		if(this.store_data1[0]?.displayRecipePage){
			this.displayRecipePage = true
		}else{
			this.displayRecipePage = false
		}
		if(this.store_data1[0]?.displayCouponPage){
			this.displayCouponPage = true
		}else{
			this.displayCouponPage = false
		}
		if(this.store_data1[0]?.displayCircularPage){
			this.displayCircularPage = true
		}else{
			this.displayCircularPage = false
		}
		if(this.store_data1[0]?.displayMenuPage){
			this.displayMenuPage = true
		}else{
			this.displayMenuPage = false
		}
		if(this.store_data1[0]?.displayBundlePage){
			this.displayBundlePage = true
		}else{
			this.displayBundlePage = false
		}


			// this.afs.collection('/stores', ref => ref.where('company_name_slug', '==', this.store_name_slug))
        // .snapshotChanges()
        // .subscribe(async storeData => {
        //     this.store_data1 = await storeData.map(change => change.payload.doc.data());
        //     console.log(this.store_data1, "store_data1");
		// 	if(this.store_data1[0]?.displayRecipePage){
		// 		this.displayRecipePage = true
		// 	}else{
		// 		this.displayRecipePage = false
		// 	}
		// 	if(this.store_data1[0]?.displayCouponPage){
		// 		this.displayCouponPage = true
		// 	}else{
		// 		this.displayCouponPage = false
		// 	}
        //     console.log(this.store_data1[0]?.displayCouponPage, this.displayCouponPage, "couponPage");
        //     console.log(this.store_data1[0]?.displayRecipePage, this.displayRecipePage, "recipePage");
        // });
		this.route.data.subscribe(data => {
			if (data.showHeaderStore) {
				this.showHeaderStore = data.showHeaderStore;
			}
			if (data.showHeaderCoupon) {
				this.showHeaderCoupon = data.showHeaderCoupon;
			}
			if (data.showHeaderBundle) {
				this.showHeaderBundle = data.showHeaderBundle;
			}
		})
		this.isAndroid = /Android/i.test(navigator.userAgent);
		this.isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
		this.isIos_hide_button = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		this.isIos_hide_button_chIOS = /CriOS/i.test(navigator.userAgent);
		this.subscription = this.pwaService.isInstallable.subscribe((res: boolean) => {
			this.setInstallable = res
		})

		if (this.isIos == true) {
			this.setInstallable = true;
		}
		this.current_store_uid = this.store_data[0]?.uid;
		if (localStorage.getItem('layout_uid')) {
			this.current_layout_id = localStorage.getItem('layout_uid')
		}
		const hostname = window.location.origin || "https://store-digitalcircular.web.app";
		const startURL = hostname + '/' + this.store_data[0]?.company_name_slug
		const obj = {
			"fileName": this.store_data[0]?.company_name_slug + ".json",
			"data": {
				"name": this.store_data[0]?.company_name,
				"short_name": this.store_data[0]?.company_name,
				"theme_color": "#026e00",
				"background_color": "#333",
				"display": "standalone",
				"scope": startURL,
				"start_url": startURL,
				"icons": [

					{
						"src": this.store_data[0].icons && this.store_data[0].icons[0] || environment.ServerURL + "/public/assets/icons/icon-192x192.png",
						"sizes": "192x192",
						"type": "image/png",
						"purpose": "maskable any"
					},
					{
						"src": this.store_data[0].icons && this.store_data[0].icons[1] || environment.ServerURL + "/public/assets/icons/icon-512x512.png",
						"sizes": "512x512",
						"type": "image/png",
						"purpose": "maskable any"
					}
				]
			}
		}
		this.pwaService.updateManifestFile(obj).subscribe((res) => {
			const linkURL = environment.ServerURL + res.url
			setTimeout(() => {
				this.pwaService.createLinkTag(linkURL)
				this.pwaService.chnageAppleTouchIcon(this.store_data[0].icons && this.store_data[0].icons[1] || environment.ServerURL + "/public/assets/icons/icon-512x512.png");
			}, 3000);

		})

		this.store_background_color = this.store_data[0]?.theme_base_color ? this.store_data[0]?.theme_base_color : `#76b101`
		if (this.route.snapshot.params?.list_id) {
			this.current_list_id = this.route.snapshot.params?.list_id
			this.is_list_view = true
		} else {
			if (localStorage.getItem('list_ids')) {
				const list_ids = JSON.parse(localStorage.getItem('list_ids'))
				if (list_ids.hasOwnProperty(this.store_data[0]?.company_name_slug)) {
					const value = list_ids[this.store_data[0]?.company_name_slug];
					localStorage.setItem('list_id', value)
					this.current_list_id = value
				} else {
					localStorage.removeItem('list_id')
					this.current_list_id = ''
				}
			} else {
				this.current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
			}
		}

		const store_itemData = await this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.store_data[0].uid).orderBy('created_at', 'desc')).get().toPromise();
		const convertArrayToString = (value) => {
			return Array.isArray(value) ? value.join('') : value;
		};
		this.store_item = store_itemData.docs.map((e: any) => {
			return {
				upc_code: e.data()['upc_code'],
				product_description: e.data()['product_description'],
				product_name_slug: e.data().product_name_slug,
				pack: e.data()['pack'],
				price_label_format: e.data()['price_label_format'],
				price_label_value1: convertArrayToString(e.data()['price_label_value1'] ? e.data()['price_label_value1'] : ""),
				price_label_value2: convertArrayToString(e.data()['price_label_value2'] ? e.data()['price_label_value2'] : ""),
				price_label_value3: convertArrayToString(e.data()['price_label_value3'] ? e.data()['price_label_value3'] : ""),
				price_label_highlight: e.data()['price_label_highlight'] ? e.data()['price_label_highlight'] : "",
				product_url: e.data()['product_url'] ? e.data()['product_url'] : "",
				hide_price_label: e.data()['hide_price_label'] ? e.data()['hide_price_label'] : false,
				hide_addtolist_label: e.data()['hide_addtolist_label'] ? e.data()['hide_addtolist_label'] : false,
				hide_productname_label: e.data()['hide_productname_label'] ? e.data()['hide_productname_label'] : false,
				cost: e.data()['cost'] ? e.data()['cost'] : "",
				srp: e.data()['srp'] ? e.data()['srp'] : "",
				storeInMaster: e.data().storeInMaster ? e.data().storeInMaster : '',
				category: e.data()['category'] ? e.data()['category'] : "",
				offerLabel: e.data().offerLabel ? e.data().offerLabel : '',
				labelSide: e.data().labelSide ? e.data().labelSide : '',
				uid: e.id,
				product_image: e.data().product_image ? e.data().product_image : 'https://via.placeholder.com/30/cecece/cecece',
				price_label_svg: e.data().price_label_svg ? e.data().price_label_svg : '',
				sale_price_label_format: e.data()['sale_price_label_format'] ? e.data()['sale_price_label_format'] : "",
				sale_price_label_value1: e.data()['sale_price_label_value1'] ? e.data()['sale_price_label_value1'] : "",
				sale_price_label_value2: e.data()['sale_price_label_value2'] ? e.data()['sale_price_label_value2'] : "",
				sale_price_label_value3: e.data()['sale_price_label_value3'] ? e.data()['sale_price_label_value3'] : "",
				final_price_label_format: e.data()['final_price_label_format'] ? e.data()['final_price_label_format'] : "",
				final_price_label_value1: e.data()['final_price_label_value1'] ? e.data()['final_price_label_value1'] : "",
				final_price_label_value2: e.data()['final_price_label_value2'] ? e.data()['final_price_label_value2'] : "",
				final_price_label_value3: e.data()['final_price_label_value3'] ? e.data()['final_price_label_value3'] : "",
				digital_coupon_price_label_format: e.data()['digital_coupon_price_label_format'] ? e.data()['digital_coupon_price_label_format'] : "",
				digital_coupon_price_label_value1: e.data()['digital_coupon_price_label_value1'] ? e.data()['digital_coupon_price_label_value1'] : "",
				digital_coupon_price_label_value2: e.data()['digital_coupon_price_label_value2'] ? e.data()['digital_coupon_price_label_value2'] : "",
				digital_coupon_price_label_value3: e.data()['digital_coupon_price_label_value3'] ? e.data()['digital_coupon_price_label_value3'] : "",
				note: e.data()['note'] ? e.data()['note'] : "",
				link: e.data()['link'] ? e.data()['link'] : "",
				usage_limit: e.data()['usage_limit'] ? e.data()['usage_limit'] : "",
				digital_coupon: e.data()['digital_coupon'] ? e.data()['digital_coupon'] : false,
				sale_price_label_svg: e.data().sale_price_label_svg ? e.data().sale_price_label_svg : '',
				sale_content_price_label_svg: e.data().sale_content_price_label_svg ? e.data().sale_content_price_label_svg : '',
				final_price_label_svg: e.data().final_price_label_svg ? e.data().final_price_label_svg : '',
				final_content_price_label_svg: e.data().final_content_price_label_svg ? e.data().final_content_price_label_svg : '',
				digital_coupon_price_label_svg: e.data().digital_coupon_price_label_svg ? e.data().digital_coupon_price_label_svg : '',
				digital_content_coupon_price_label_svg: e.data().digital_content_coupon_price_label_svg ? e.data().digital_content_coupon_price_label_svg : '',
				is_saved: e.data().is_saved ? e.data().is_saved : '',
				store_uid: e.data().store_uid ? e.data().store_uid : '',
				isVideo: e.data().isVideo ? e.data().isVideo : false,
				master_uid: e.data().master_uid ? e.data().master_uid : '',
			};
		});
		this.store_name_slug = this.route.snapshot.params.store_name;
		const url: any = this.route.url;
		this.urlParam = new URLSearchParams(window.location.search)
		let temp_url = url._value[1];
		if (temp_url != undefined) {
			if (url._value[1].path == 'coupons') {
				this.isCoupon = true
				this.tabName = 'coupons'
			}
			else if (url._value[1].path == 'recipes') {
				this.isRecipe = true
				this.tabName = 'recipes'
			}else if (url._value[1].path == 'bundles') {
				this.tabName = 'bundles'
			}else if (url._value[1].path == 'circular') {
				this.tabName = 'circular'
			}else if (url._value[1].path == 'menu') {
				this.tabName = 'menu'
			}else {
				this.tabName = 'circular'
			}
		}
		if (this.router.url == '/' + this.store_name_slug) {
			this.tabName = 'circular'
		}
	// 	if (this.current_list_id !== '') {
	//    console.log(this.serializedBundleData,this.serializedData,"this.serializedBundleData");
	//    this.combinedData=[]
	//    if(this.serializedData?.length>0){
	// 	this.combinedData.push(...this.serializedData);
	//    }
	//    if(this.serializedBundleData?.length>0){
	// 	this.combinedData.push(...this.serializedBundleData);
	//    }
	//    if(this.serializedMenuData?.length>0){
	// 	this.combinedData.push(...this.serializedMenuData);
	//    }		

	// 		this.qr_value = window.location.href + '/list/' + this.current_list_id;

	// 		try {
	// 			const currentListSnapshot = await this.afs.collection('/list').doc(this.current_list_id).get().toPromise();
	// 			const currentListData: any = currentListSnapshot.data();

	// 			this.cartItem = currentListData?.items;
	// 			let finalCurrentListData;
	// 			if (currentListData?.past_items) {
	// 				finalCurrentListData = [...currentListData?.items, ...currentListData?.past_items];
	// 			} else {
	// 				finalCurrentListData = currentListData?.items;
	// 			}

	// 			const commonItems = [];
	// 			console.log(this.combinedData,"combinedData")
	// 			for (const storeItem of this.combinedData) {
	// 				const storeItemUid = storeItem.id;
	// 				if (storeItem?.id) {
	// 					const isCommon = finalCurrentListData.some(cartItem => {
	// 						return cartItem.product.id == storeItem.id || cartItem.product.uid == storeItem.id;
	// 					});
	// 					if (isCommon) {
	// 						commonItems.push(storeItem);
	// 					}
	// 				}
	// 			}
	// 			for (const storeItem of this.store_item) {
	// 				const storeItemUid = storeItem.id;
	// 				if (storeItem?.uid) {
	// 					const isCommon = finalCurrentListData.some(cartItem => {
	// 						return cartItem.product.uid == storeItem.uid;
	// 					});
	// 					if (isCommon) {
	// 						commonItems.push(storeItem);
	// 					}
	// 				}
	// 			}

	// 			this.past_item = [];
	// 			this.cartItem = [];

	// 			for (const currentCartItem of finalCurrentListData) {
	// 				let storeItem
	// 				if (currentCartItem.product.id) {
	// 					storeItem = commonItems.find(item => item.id == currentCartItem.product.id);
	// 				} else {
	// 					storeItem = commonItems.find(item => item.uid == currentCartItem.product.uid);
	// 				}
	// 				if (storeItem && (
	// 					currentCartItem?.product?.price_label_value1 !== storeItem?.price_label_value1 ||
	// 					currentCartItem?.product?.price_label_value2 !== storeItem?.price_label_value2 ||
	// 					currentCartItem?.product?.price_label_value3 !== storeItem?.price_label_value3 ||
	// 					currentCartItem?.product?.sale_price_label_value1 !== storeItem?.sale_price_label_value1 ||
	// 					currentCartItem?.product?.sale_price_label_value2 !== storeItem?.sale_price_label_value2 ||
	// 					currentCartItem?.product?.sale_price_label_value3 !== storeItem?.sale_price_label_value3)

	// 				) {
	// 					this.past_item.push(currentCartItem);
	// 				} else {
	// 					this.cartItem.push(currentCartItem);
	// 				}
	// 			}
	// 			console.log(this.cartItem,this.past_item,"checkheder")

	// 			await this.afs.collection('/list').doc(this.current_list_id).update({
	// 				items: this.cartItem,
	// 				past_items: this.past_item
	// 			});
	// 		} catch (error) {
	// 			console.error("Error:", error);
	// 		}
	// 		if (this.current_list_id !== '') {
	// 			this.afs.collection('/coupons', ref => ref.where('store_uid', '==', this.current_store_uid).where('is_publish', '==', true).orderBy('created_at', 'desc')).snapshotChanges().subscribe(async data => {
	// 				this.coupons_list = data.map(e => {
	// 					const validTillTimestamp = e.payload.doc.data()['valid_till'].seconds * 1000;
	// 					const validTillDate = new Date(validTillTimestamp);
	// 					validTillDate.setHours(0, 0, 0, 0);
	// 					const currentDate = new Date();
	// 					currentDate.setHours(0, 0, 0, 0);
	// 					if (validTillDate >= currentDate) {
	// 						return {
	// 							uid: e.payload.doc.id,
	// 							coupon_image: e.payload.doc.data()['coupon_image'] ? e.payload.doc.data()['coupon_image'] : "https://via.placeholder.com/300x200/2e2e2e/2e2e2e",
	// 							upc: e.payload.doc.data()['upc'],
	// 							coupon_description: e.payload.doc.data()['coupon_description'],
	// 							label: e.payload.doc.data()['label'],
	// 							coupon_code: e.payload.doc.data()['coupon_code'],
	// 							valid_from: e.payload.doc.data()['valid_from'],
	// 							valid_till: e.payload.doc.data()['valid_till'],
	// 						};
	// 					} else {
	// 						return {
	// 							uid: e.payload.doc.id,
	// 							valid_till: 'expired'
	// 						}
	// 					}
	// 				});
	// 				console.log(this.combinedData,"combinedData")
	// 			this.serializedIds = this.combinedData?.map(s => s.id)

	// 			const currentListSnapshot = await this.afs.collection('/list').doc(this.current_list_id).get().toPromise();
	// 			const currentListData: any = currentListSnapshot.data();
	// 			console.log(currentListSnapshot.data(),"currentListSnapshot.data()")

	// 			if (currentListData?.items) {
	// 				console.log(currentListData,"currentListData")
	// 				const updatedCartItems = [];
	// 				const updatedPastItems = [];
	// 				const CouponItems = currentListData.items.filter((a) => a.isCoupon == true)
	// 				const RecipeItems = currentListData.items.filter((a) => a.isRecipe == true)
	// 				const filterCartItems = currentListData.items.filter((a) => a.isRecipe != true && a.isCoupon != true);
	// 				const filterPastItems = currentListData.past_items.filter((a) => a.isRecipe != true && a.isCoupon != true);
	// 				updatedCartItems.push(...RecipeItems)
	// 				CouponItems.forEach(currentCartItem => {
	// 					if (currentCartItem.isCoupon) {
	// 						this.coupons_list?.forEach(item => {
	// 							if (item?.uid == currentCartItem?.product?.uid) {
	// 							   const validTillDate = new Date(currentCartItem.product.valid_till);
	// 							   validTillDate.setHours(0, 0, 0, 0);
	// 							   const currentDate = new Date();
	// 							   currentDate.setHours(0, 0, 0, 0);
	// 								if (item.valid_till == 'expired' || validTillDate < currentDate) {
	// 									currentCartItem.product = {
	// 										...currentCartItem.product,
	// 										isExpired: 'Yes'
	// 									};
	// 									updatedCartItems.push(currentCartItem)
	// 								} else {
	// 									currentCartItem.product = {
	// 										...currentCartItem.product,
	// 										isExpired: 'No'
	// 									};
	// 									updatedCartItems.push(currentCartItem)
	// 								}
	// 							}
	// 						})

	// 					}
	// 				})
	// 				for (const currentCartItem of filterCartItems) {
	// 					if (currentCartItem.product.id) {
	// 						console.log(currentCartItem.product.id,"currentCartItem.product.id",this.serializedIds,"id")
	// 						if (!this.serializedIds.includes(currentCartItem.product.id) && !currentCartItem.static) {
	// 							updatedPastItems.push(currentCartItem);
	// 						} else {
	// 							updatedCartItems.push(currentCartItem);
	// 						}
	// 					} else {
	// 						if (!this.serializedIds.includes(currentCartItem.product.uid) && !currentCartItem.static) {
	// 							updatedPastItems.push(currentCartItem);
	// 						} else {
	// 							updatedCartItems.push(currentCartItem);
	// 						}
	// 					}
	// 				}
	// 				for (const currentPastItem of filterPastItems) {
	// 					updatedPastItems.push(currentPastItem);
	// 				}
	// 				console.log(updatedCartItems,updatedPastItems,"cjclheadet")

	// 				await this.afs.collection('/list').doc(this.current_list_id).update({
	// 					items: updatedCartItems,
	// 					past_items: updatedPastItems
	// 				});
	// 			}
	// 		});
	// 		}
	// 		await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
	// 			console.log(this.serializedBundleData,this.serializedData,this.serializedMenuData,"dataLISTSNAPAHOTCHANGESSS))))))))))))*************")
	// 			this.current_list_data = data.map(e => {
	// 				return {
	// 					uid: e.payload.doc.id,
	// 					items: e.payload.doc.data().items ? e.payload.doc.data().items : [],
	// 					past_items: e.payload.doc.data().past_items ? e.payload.doc.data().past_items : []
	// 				};
	// 			});
	// 			this.current_list_data = this.current_list_data[0]
	// 			this.cartItem = this.current_list_data?.items
	// 			this.past_item = this.current_list_data?.past_items;
	// 			this.cart_ids = []
	// 			this.current_list_data?.items.forEach(i => {
	// 				if (i?.product?.id) {
	// 					this.cart_ids.push(i?.product?.id)
	// 				} else {
	// 					this.cart_ids.push(i?.product?.uid)
	// 				}
	// 			});
	// 		});
	// 	}
        console.log(this.store_data[0]?.company_name,"this.store_data[0]?.company_name")
		await this.afs.collection('/group_list', ref => ref.where('store', 'array-contains', this.store_data[0]?.company_name)).snapshotChanges().subscribe((data: any) => {
			this.groupList = data.map(e => {
				return {
					uid: e.payload.doc.id,
					groupName: e.payload.doc.data().groupName,
					store: e.payload.doc.data().store,
				};
			});

			this.groupList.forEach(i => {
				i.store.forEach(j => {
					this.groupBranch.push({ slug: j.replace(/\s/g, ''), name: j })
				})
			})
			this.groupBranch = [...new Map(this.groupBranch.map((item) =>
				[item["name"], item])).values()]
		});

		setTimeout(() => {
			this.scrolldiv();
		}, 2000);
		$(window).resize(() => {
			this.scrolldiv();
		})

		this.currentCategory = this.route.snapshot.params['recipe_categories'];

		const url1 = this.router.url
		const parts = url1.split("/"); // Split the URL by "/"
		const Store_id = parts[parts.length - 1].split("?")[0];
		if (this.router.url == '/' + this.store_name_slug) {
			this.print = true;
			localStorage.setItem("printButton",'Display')
		} else if (this.router.url == '/' + this.store_name_slug + '/' + Store_id + '?preview=true') {
			if (Store_id == 'coupons' || Store_id == 'recipes') {
				this.print = false;
			} else {
				this.print = true;
			}
		} else if (this.router.url == '/' + this.store_name_slug + '/' + Store_id + '?preview=true' + '&type=noad') {
			if (Store_id == 'coupons' || Store_id == 'recipes') {
				this.print = false;
			} else {
				this.print = true;
			}
		}
		this.printDisplay =localStorage.getItem('printButton')
	}
	openSignPage(){
		this.router.navigate(['/login']);
	}
	QrModelClick(){
		this.analytics.logEvent('@shoppingList_qr_click_count', {
			message: 'ShoppingList Qr Button  Clicked.'
		});
	}
	applyDefaultColor() {
		const root = document.documentElement;
		root.style.setProperty('--theme-base-color', this.store_data[0]?.theme_base_color || 'rgb(139, 195, 74)');
		root.style.setProperty('--branch-name-color', this.store_data[0]?.branch_name_color || 'rgb(139, 195, 74)');
	}

	openPWA() {
		if (/iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase())) {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.openDialog_ios();
				this.analytics.logEvent('@pwa_mobile_icon_install_click_count', {
					message: 'PWA install successfully.'
				  });
			}
		} else {
			this.setInstallable = false;
			this.pwaService.isInstallable.next(false)
			// Show the install prompt
			this.pwaService.deferredPrompt.prompt();
			// Wait for the user to respond to the prompt
			this.pwaService.deferredPrompt.userChoice.then((choiceResult: any) => {				
				if (choiceResult.outcome === 'accepted') {
					this.analytics.logEvent('@pwa_mobile_icon_install_click_count', {
						outcome: choiceResult.outcome, // 'accepted' or 'dismissed'
						platform: choiceResult.platform, // Platform used to accept or dismiss
					  });
				} 
			});
		}
	}
	startTour() {
		const url1 = this.router.url
		const parts = url1.split("/"); // Split the URL by "/"
		const Store_id = parts[parts.length - 1].split("?")[0];
		if (Store_id !== 'coupons' && Store_id !== 'recipes' && Store_id !== this.currentCategory) {
			if (this.router.url == '/' + this.store_name_slug || this.router.url == '/' + this.store_name_slug + '/' + Store_id + '?preview=true' + '&type=noad' || this.router.url == '/' + this.store_name_slug + '/' + Store_id + '?preview=true') {
				this.CloseShoppingList();
				this.appTourService.cancelTour();
				localStorage.setItem(this.tourSeenFlag, 'false');
				this.appTourService.startTour();
			}
		} else if (this.router.url == '/' + this.store_name_slug + '/recipes' || this.router.url == '/' + this.store_name_slug + '/recipes?preview=true' || this.router.url == '/' + this.store_name_slug + '/recipes/' + this.currentCategory || this.router.url == '/' + this.store_name_slug + '/recipes?preview=true' + '&type=noad') {
			this.closeRecipeboxPanel.emit();
			this.CloseShoppingList();
			this.appRecipeTourService.cancelTour();
			localStorage.setItem(this.recipeTourSeenFlag, 'false');
			this.appRecipeTourService.startTour();
		}
		else if (this.router.url == '/' + this.store_name_slug + '/coupons' || this.router.url == '/' + this.store_name_slug + '/coupons?preview=true' || this.router.url == '/' + this.store_name_slug + '/coupons?preview=true' + '&type=noad') {
			this.CloseShoppingList();
			this.appCouponTourService.cancelTour();
			localStorage.setItem(this.CouponTourSeenFlag, 'false');
			this.appCouponTourService.startTour();
		}
		// else if (this.router.url == '/' + this.store_name_slug + '/bundles' || this.router.url == '/' + this.store_name_slug + '/bundles?preview=true' ){
		// 	this.CloseShoppingList();
		// 	this.appCouponTourService.cancelTour();
		// 	localStorage.setItem(this.BundlesTourSeenFlag, 'false');
		// 	this.appCouponTourService.startTour();
		// }else if (this.router.url == '/' + this.store_name_slug + '/menu' || this.router.url == '/' + this.store_name_slug + '/menu?preview=true' ) {
		// 	this.CloseShoppingList();
		// 	this.appCouponTourService.cancelTour();
		// 	localStorage.setItem(this.MenuTourSeenFlag, 'false');
		// 	this.appCouponTourService.startTour();
		// }
		
	}
	startShoppingListTour() {
		this.appShoppingListTourService.cancelTour();
		localStorage.setItem(this.shoppingTourSeenFlag, 'false');
		this.appShoppingListTourService.startTour();
	}

	openDialog_ios() {
		this.dialog.open(DialogAnimationsExampleDialog, {
			width: "500px",
		});
	}

	openDialog_android() {
		this.dialog.open(DialogAnimationsExampleDialogAndroid, {
			width: "500px",
		});
	}

	addtohomeScreen() {
		if (/iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase())) {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.openDialog_ios();
			}
		} else {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				this.openDialog_android();
			}
		}
	}

	// ngOnChanges() {
	// }

	scrolldiv() {
		var div = document.getElementById('food_menu');
		var hasHorizontalScrollbar = div?.scrollWidth > div?.clientWidth;
		if (hasHorizontalScrollbar) {
			$('.scroll-arrow').show();
		} else {
			$('.scroll-arrow').hide();
		}
	}
	public scrollRight(): void {
		this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 150), behavior: 'smooth' });
	}
	public scrollLeft(): void {
		this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 150), behavior: 'smooth' });
	}

	add_to_list(item) {
		if (!this.isCoupon) {
			this.addToList.emit(item)
		}
		else if (this.isCoupon) {
			this.addToList.emit(item)
		}
		setTimeout(() => {
			this.show_add_item = false
			this.filtered_items = []
			this.item_name = ''
		}, 0);
	}

	async increase_product(i) {
		let main_item
		let layout_product_qnt
		const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
		if (view == "listView" && window.innerWidth <= 768) {
			if(i?.product?.isBundleProduct){
				if (i?.product?.id) {
					main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement?.parentElement;
				} else {
					main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement?.parentElement;
				}
			}else{
				if (i?.product?.id) {
					main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement;
				} else {
					main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement;
				}
			}
			layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
		} else {
			if(i?.product?.isBundleProduct){
				if (i?.product?.id) {
					if (document.getElementById(i?.product?.id)) {
						if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.id)?.parentElement
						} else {
							main_item = document.getElementById(i?.product?.id)?.parentElement
						}
					}
				} else {
					if (document.getElementById(i?.product?.uid)) {
						if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.uid)?.parentElement
						} else {
							main_item = document.getElementById(i?.product?.uid)?.parentElement
						}
					}
				}
			}else{
				if (i?.product?.id) {
					if (document.getElementById(i?.product?.id)) {
						if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.id)
						} else {
							main_item = document.getElementById(i?.product?.id)?.parentElement
						}
					}
				} else {
					if (document.getElementById(i?.product?.uid)) {
						if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.uid)
						} else {
							main_item = document.getElementById(i?.product?.uid)?.parentElement
						}
					}
				}
			}
			layout_product_qnt = main_item?.parentElement.getElementsByClassName("layout_product_qnt")[0]
		}
		let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
		temp_itm.count += 1
		this.cartItem[this.cartItem.indexOf(i)] = temp_itm
		if (!this.current_list_id) {
			this.current_list_id = localStorage.getItem('list_id')
		}
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
		if(layout_product_qnt?.innerHTML){
		layout_product_qnt.innerHTML = i?.count
		}
	}

	async decrease_product(i) {
		let main_item
		let delete_icon
		let layout_product_qnt
		let layout_count_product: HTMLElement
		const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
		if (view == "listView" && window.innerWidth <= 768) {
           if(i?.product?.isBundleProduct){
			if (i?.product?.id) {
				main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement?.parentElement;
			} else {
				main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement?.parentElement;
			}
           }else{
			if (i?.product?.id) {
				main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement;
			} else {
				main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement;
			}
		   }
			delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
			layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
			layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
		} else {
			if(i?.product?.isBundleProduct){
				if (i?.product?.id) {
					if (document.getElementById(i?.product?.id)) {
						if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.id)?.parentElement
						} else {
							main_item = document.getElementById(i?.product?.id)?.parentElement
						}
					}
				} else {
					if (document.getElementById(i?.product?.uid)) {
						if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.uid)?.parentElement
						} else {
							main_item = document.getElementById(i?.product?.uid)?.parentElement
						}
					}
				}
			}else{
				if (i?.product?.id) {
					if (document.getElementById(i?.product?.id)) {
						if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.id)
						} else {
							main_item = document.getElementById(i?.product?.id)?.parentElement
						}
					}
				} else {
					if (document.getElementById(i?.product?.uid)) {
						if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
							main_item = document.getElementById(i?.product?.uid)
						} else {
							main_item = document.getElementById(i?.product?.uid)?.parentElement
						}
					}
				}
			}
			layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
			layout_count_product = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
			delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
		}
		let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
		if (temp_itm.count > 1) {
			temp_itm.count -= 1
			this.cartItem[this.cartItem.indexOf(i)] = temp_itm
		if(layout_product_qnt?.innerHTML){
			layout_product_qnt.innerHTML = temp_itm?.count
		}
		} else if (temp_itm.count === 1) {
			if (!this.serializedIds && this.serializedIds == undefined) {
				this.serializedIds = this.combinedData.map(s => s.id)
			}
			this.cartItem.splice(this.cartItem.indexOf(i), 1)
			if (i?.product?.id) {
				if (!temp_itm.static && this.serializedIds.includes(i?.product?.id)) {
					let title_bar: any = main_item?.parentElement.childNodes[1]
					title_bar?.classList?.remove("test")
		   if(layout_product_qnt?.innerHTML){
					layout_product_qnt.innerHTML = ''
	         }
			 if (layout_count_product?.style?.visibility) {
				layout_count_product.style.visibility = 'hidden'
		 	}
					this.cart_ids.splice(this.cart_ids.indexOf(i?.product?.id), 1)
	        if(delete_icon){
				delete_icon.src = "../../../assets/imgs/plus.png"	
			}				
			}
			}
		} else {
			if (!temp_itm.static && this.serializedIds.includes(i?.product?.uid)) {
				let title_bar: any = main_item?.parentElement.childNodes[1]
				title_bar?.classList?.remove("test")
				if(layout_product_qnt?.innerHTML){
					layout_product_qnt.innerHTML = ''
		        }	
				if (layout_count_product?.style?.visibility) {
					layout_count_product.style.visibility = 'hidden'
				}			
				this.cart_ids.splice(this.cart_ids.indexOf(i?.product?.uid), 1)
               if(delete_icon){
				delete_icon.src = "../../../assets/imgs/plus.png"	
				}			
			}
		}
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
	}
	categoryProd(val) {
		sessionStorage.setItem('is_all_clicked', 'true');
		this.categoryList.emit(val)
	}
	product_select_change(event, type, product) {
		if (!this.serializedIds) {
			this.serializedIds = this.combinedData.map(s => s.id)
		}
		if (event.target.checked && (type === 'all')) {
			this.selected_cart_items = this.cartItem
			this.isAllSelected = true
		} else if (!event.target.checked && (type === 'all')) {
			this.selected_cart_items = []
			this.isAllSelected = false
		} else if (event.target.checked && (type === 'single') && (this.serializedIds.includes(product.product.uid) || product.isCoupon || this.isCoupon)) {
			this.selected_cart_items.push(product)
			if (!product.isCoupon && !this.isCoupon) {
				let a = document.getElementById(product.product.uid)?.parentElement?.lastElementChild?.getElementsByTagName('img')[0].src
				a = "../../../assets/imgs/right.png"
			}
		} else if (!event.target.checked && (type === 'single') && this.serializedIds.includes(product.product.uid)) {
			this.selected_cart_items.splice(this.selected_cart_items.indexOf(product), 1)
			this.cart_ids.splice(this.cart_ids.indexOf(product.product.uid), 1)
			let a = document.getElementById(product.product.uid)?.parentElement?.lastElementChild?.getElementsByTagName('img')[0].src
			a = "../../../assets/imgs/plus.png"
		} else if (event.target.checked && (type === 'single') && (this.serializedIds.includes(product.product.id) || product.isCoupon || this.isCoupon)) {
			this.selected_cart_items.push(product)
			if (!product.isCoupon && !this.isCoupon) {
				let a = document.getElementById(product.product.id)?.parentElement?.lastElementChild?.getElementsByTagName('img')[0]?.src
				a = "../../../assets/imgs/right.png"
			}
		} else if (!event.target.checked && (type === 'single') && this.serializedIds.includes(product.product.id)) {
			this.selected_cart_items.splice(this.selected_cart_items.indexOf(product), 1)
			this.cart_ids.splice(this.cart_ids.indexOf(product.product.id), 1)
			let a = document.getElementById(product.product.id)?.parentElement?.lastElementChild?.getElementsByTagName('img')[0].src
			a = "../../../assets/imgs/plus.png"
		}
	}

	product_past_select_change(event, type, product) {
		if (!this.serializedIds) {
			this.serializedIds = this.combinedData.map(s => s.id)
		}
		if (event.target.checked && (type === 'all')) {
			this.selected_past_cart_items = this.past_item
			this.isAllPastSelected = true
		} else if (!event.target.checked && (type === 'all')) {
			this.selected_past_cart_items = []
			this.isAllPastSelected = false
		} else if (event.target.checked && (type === 'single')) {
			this.selected_past_cart_items.push(product)
		} else if (!event.target.checked && (type === 'single')) {
			this.selected_past_cart_items.splice(this.selected_past_cart_items.indexOf(product), 1)
			this.cart_ids.splice(this.cart_ids.indexOf(product.product.uid), 1)
		} else if (event.target.checked && (type === 'single')) {
			this.selected_past_cart_items.push(product)
		} else if (!event.target.checked && (type === 'single')) {
			this.selected_past_cart_items.splice(this.selected_past_cart_items.indexOf(product), 1)
			this.cart_ids.splice(this.cart_ids.indexOf(product.product.id), 1)
		}
	}

	async remove_from_past_cart(i, type) {
		if (type === 'one') {
			this.past_item.splice(this.past_item.indexOf(i), 1);
			if (!i.static && this.serializedIds.includes(i?.product?.uid) && !this.isCoupon && !this.isRecipe) {
				let main_item
				let title_bar
				let delete_icon
				let layout_product_qnt
				let layout_count_product: HTMLElement
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view == "listView" && window.innerWidth <= 768) {
					if(i?.product?.isBundleProduct){
						main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement?.parentElement;
					}else{
						main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement;
					}
					delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
					layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
				} else {
					if(i?.product?.isBundleProduct){
						if (document.getElementById(i?.product?.uid)) {
							if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
								if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
									main_item = document.getElementById(i?.product?.id)?.parentElement
								} else {
									main_item = document.getElementById(i?.product?.id)?.parentElement
								}
							} else {
								main_item = document.getElementById(i?.product?.uid)?.parentElement
							}
						}
					}else{
						if (document.getElementById(i?.product?.uid)) {
							if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
								if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
									main_item = document.getElementById(i?.product?.id)?.parentElement
								} else {
									main_item = document.getElementById(i?.product?.id)
								}
							} else {
								main_item = document.getElementById(i?.product?.uid)?.parentElement
							}
						}
					}

					layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
					layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
					title_bar = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
				}
				if(layout_product_qnt?.innerHTML){
				layout_product_qnt.innerHTML = ''
		          }	
				  if (layout_count_product?.style?.visibility) {
				layout_count_product.style.visibility = 'hidden'
				}			
				this.cart_ids.splice(this.cart_ids.indexOf(i?.product?.uid), 1)
               if(delete_icon){
				delete_icon.src = "../../../assets/imgs/plus.png"	
				}			
			} else if (!i.static && this.serializedIds.includes(i?.product?.id) && !this.isCoupon && !this.isRecipe) {
				let main_item
				let title_bar
				let delete_icon
				let layout_product_qnt
				let layout_count_product: HTMLElement
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view == "listView" && window.innerWidth <= 768) {
					if(i?.product?.isBundleProduct){
						main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement?.parentElement;
					}else{
						main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement;
					}
					delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
					layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
				} else {
				  if(i?.product?.isBundleProduct){
					if (document.getElementById(i?.product?.id)) {
						if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
							if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
								main_item = document.getElementById(i?.product?.id)?.parentElement
							} else {
								main_item = document.getElementById(i?.product?.id)?.parentElement
							}
						} else {
							main_item = document.getElementById(i?.product?.id)?.parentElement
						}
					  }
				    }else{
						if (document.getElementById(i?.product?.id)) {
							if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
								if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
									main_item = document.getElementById(i?.product?.id)?.parentElement
								} else {
									main_item = document.getElementById(i?.product?.id)
								}
							} else {
								main_item = document.getElementById(i?.product?.id)?.parentElement
							}
						  }
					}
					layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
					layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
					title_bar = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
				}
			if(layout_product_qnt?.innerHTML){
				layout_product_qnt.innerHTML = ''
	       }
		   if (layout_count_product?.style?.visibility) {
				layout_count_product.style.visibility = 'hidden'
			   }
				this.cart_ids.splice(this.cart_ids.indexOf(i?.product?.id), 1)
              if(delete_icon){
				delete_icon.src = "../../../assets/imgs/plus.png"	
			  }			
			}
		}
		if (type == 'selected') {
			this.past_item = this.past_item?.filter(itm => {
				if (!this.selected_past_cart_items.includes(itm)) {
					return true
				} else {
					if (this.serializedIds.includes(itm?.product?.uid) && !this.isCoupon) {
						let main_item
						let title_bar
						let count_icon_plus: HTMLElement
						let count_icon_minus: HTMLElement
						let delete_icon
						let layout_product_qnt
						let layout_count_product: HTMLElement
						const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
						if (view == "listView" && window.innerWidth <= 768) {
							if(itm?.product?.isBundleProduct){
								main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement?.parentElement;
							}else{
								main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement;
							}							delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
							layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
							count_icon_plus = main_item?.getElementsByClassName("count-products")[0]?.firstChild as HTMLElement;
							count_icon_minus = main_item?.getElementsByClassName("count-products")[0]?.lastChild as HTMLElement;
						} else {
							if(itm?.product?.isBundleProduct){
								if (document.getElementById(itm?.product?.uid)) {
									if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
										if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										} else {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										}
									} else {
										main_item = document.getElementById(itm?.product?.uid)?.parentElement
									}
								}
							}else{
								if (document.getElementById(itm?.product?.uid)) {
									if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
										if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										} else {
											main_item = document.getElementById(itm?.product?.id)
										}
									} else {
										main_item = document.getElementById(itm?.product?.uid)?.parentElement
									}
								}
							}
							layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
							layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
							title_bar = main_item?.parentElement?.childNodes[1]
							title_bar?.classList.remove("test")
						}
					if(layout_product_qnt?.innerHTML){
						layout_product_qnt.innerHTML = ''
			          }
					  if (layout_count_product?.style?.visibility) {
						layout_count_product.style.visibility = 'hidden'
					}
						this.cart_ids.splice(this.cart_ids.indexOf(itm?.product?.id), 1)
	                if(delete_icon){
						delete_icon.src = "../../../assets/imgs/plus.png"
			        }						
				   if(layout_product_qnt?.innerHTML){
						layout_product_qnt.innerHTML = ''
			          }
						return false
					} else if (this.serializedIds.includes(itm?.product?.id) && !this.isCoupon) {
						let main_item
						let title_bar
						let count_icon_plus: HTMLElement
						let count_icon_minus: HTMLElement
						let delete_icon
						let layout_product_qnt
						let layout_count_product: HTMLElement
						const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
						if (view == "listView" && window.innerWidth <= 768) {
							if(itm?.product?.isBundleProduct){
								main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement?.parentElement;
							} else {
								main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement;
							}
							delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
							layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
							count_icon_plus = main_item?.getElementsByClassName("count-products")[0]?.firstChild as HTMLElement;
							count_icon_minus = main_item?.getElementsByClassName("count-products")[0]?.lastChild as HTMLElement;
						} else {
                             if(itm?.product?.isBundleProduct){
						     	if (document.getElementById(itm?.product?.id)) {
						     		if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
						     			if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
						     				main_item = document.getElementById(itm?.product?.id)?.parentElement
						     			} else {
						     				main_item = document.getElementById(itm?.product?.id)?.parentElement
						     			}
						     		} else {
						     			main_item = document.getElementById(itm?.product?.id)?.parentElement
						     		}
						     	}
						     }else{
						     	if (document.getElementById(itm?.product?.id)) {
						     		if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
						     			if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
						     				main_item = document.getElementById(itm?.product?.id)?.parentElement
						     			} else {
						     				main_item = document.getElementById(itm?.product?.id)
						     			}
						     		} else {
						     			main_item = document.getElementById(itm?.product?.id)?.parentElement
						     		}
						     	}
						     }
							layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
							layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
							title_bar = main_item?.parentElement?.childNodes[1]
							title_bar?.classList.remove("test")
						}
					if(layout_product_qnt?.innerHTML){
						layout_product_qnt.innerHTML = ''
			          }
					  if (layout_count_product?.style?.visibility) {
						layout_count_product.style.visibility = 'hidden'
				    	}
						this.cart_ids.splice(this.cart_ids.indexOf(itm?.product?.id), 1)
		           if(delete_icon){
					delete_icon.src = "../../../assets/imgs/plus.png"
				    }						
				    if(layout_product_qnt?.innerHTML){
				    	layout_product_qnt.innerHTML = ''
				    }
						return false
					}
				}
			})
		}

		this.current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
		await this.afs.doc('/list/' + this.current_list_id).set({ past_items: this.past_item }, { merge: true });
	}
	async frontend_print() {
		this.analytics.logEvent('@Circular_print_click_count', {
			message: 'Circular Print Button Clicked.'
		})
		var printWindow = window.open("", "_blank", "top=0,lefheight=100%,width=auto");
		var loader = document.createElement('div');
		loader.classList.add('loader');
		loader.innerHTML = '<img src="assets/imgs/loading2.gif" alt="Loading....">';
		loader.style.position = 'fixed';
		loader.style.top = '50%';
		loader.style.left = '50%';
		loader.style.transform = 'translate(-50%, -50%)';
		// Append loader to print window document
		printWindow.document.body.appendChild(loader);

		// Function to remove loader
		function removeLoader() {
			loader.remove();
		}

		// Event listener to remove loader when content is loaded
		printWindow.onload = removeLoader;
		var gridItems = document.querySelectorAll('.grid-stack-item');
		var gridContent = '';
		var DIGITalCoupon = '';
		var bannerImg = this.store_data[0]?.banner_image;
		var header = document.querySelector('.dcp-logo').innerHTML;
		var footer = document.querySelector('.price-goods').innerHTML;
		var gridItemsArray = Array.from(gridItems).reverse();

		// Create an array to store promises
		const promises = [];

	
		// Wait for all promises to resolve
		await Promise.all(promises);

		// Concatenate grid content
		gridItemsArray.forEach(item => {
			const digitalCoupon = item.querySelector('.Digital_price_main');
			const div = item.querySelector('.grid-stack-item-content')
			if (digitalCoupon) {
				div.classList.add('digital_coupon_div');
				DIGITalCoupon += item.innerHTML;
			} else {
				var fbVideos = item.getElementsByClassName("fb-video");
				if (fbVideos.length == 0) {
					gridContent += item.innerHTML;
				}
			}
		});

		printWindow.document.write(`
       <html>
          <head>
	          <link rel="stylesheet" href="https://gridstackjs.com/demo/demo.css"/>
	          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/gridstack.js/10.1.0/gridstack-extra.min.css"/>
	          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/gridstack.js/10.1.0/gridstack.css"/>
	     <title>Print Preview</title>
         <style>
		         .grid-stack{
			       background-color:white !important;
			       margin-left:75px ;
		         }
				 .wrapper-main{
					visibility:visible !important;
					display:none !important;
				 }
				 .banner-img-width{
	               height:auto !important;
				 }
				.fb-video >span >iframe{
				   width:203px !important;
				   height:133px !important;
				   }
				.banner-text{
                     position: absolute;
                     top: 50%;
                     left: 50%;
                     width: 100%;
                     -ms-transform: translate(-50%, -50%);
                     transform: translate(-50%, -50%);
					}
				 .digital_coupon_section >.digital_title{
					text-align:center;
					margin-top: 20px;
					margin-bottom: 20px;
					font-size:17px !important;
				  }
				 .grid-stack-item-content.digital_coupon_div { 
					width:290px !important ;
					height:290px !important;
				 }
				//  .digital_coupon_section{
				// 	page-break-before :always !important;
				//  }
				 .grid-stack-item-content.digital_coupon_div >.product-title{
					top: -335px !important;
					height:50px !important;
					padding-left:25px !important;
					display:flex !important;
					flex-direction: column !important;
					justify-content: center !important;
				  }
				  .grid-stack-item-content.digital_coupon_div >.product-title p{
				   font-size:13px !important;
				  }
				  .grid-stack-item-content.digital_coupon_div >.product-title >.packDetailsName{
					font-size:13px !important;
				   }
				  .grid-stack-item-content.digital_coupon_div >.pack-info-container {
					bottom: 18px !important;
					font-size: 14px !important;
				   }
			    .grid-stack-item-content { 
				  background-color: white; 
				  border: 1px solid #ccc; 
				  position:relative !important;		
				  text-align: center; 
				  width:120px;
	              height:130px;
				  padding:36px 10px  !important;
				  overflow:hidden !important;

			   }

			  .grid-stack-item-content img{
				width:60px;
                height:50px;
			  }

			  .product-title{
				background-size: cover;
				position: relative !important;
				top: -170px !important;
				background-color: #fff;
				color: #000 !important;
				font-weight: 1 !important;
                height: 36px;
				color: #000;
				padding-left:0 !important;
				padding-right: 0 !important;
				left:0 !important;
				background-color:transparent !important;
			  }
			  .product-title p{
				font-size:10px !important;
				-webkit-line-clamp:2 !important;
				line-height:1.2 !important;
				margin-bottom:0 !important;
			  }
			  .packDetailsName{
				color: black !important;
				font-size:10px !important;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
				line-height:1 !important;
				margin-bottom:0 !important;
					}
			  .pricelabel {
				position: relative !important;
				bottom: 115px !important;
				left: -7px !important;
				width: 6vw !important;
                height: 6vw !important;
				font-size: 2.5vw !important;
			  }

		     .grid-stack > .grid-stack-item > .grid-stack-item-content {
			   margin: 0;
			   position: absolute;
			//    width: auto;
			   overflow-x: hidden;
			   overflow-y: auto;
			  inset: 2px !important;
		     }

		     .grid-stack-item-content > img {
			 object-fit: cover !important;
			 max-width: 100% !important;
			 height: 100% !important;
			//  cursor: pointer;
		     }
			 .grid-stack-item-content >.fill_video >video{
				object-fit: cover !important;
				max-width: 100% !important;
				height: 100% !important;
			   //  cursor: pointer;
				}
			 .grid-stack-item-content.digital_coupon_div > img {
				object-fit: cover !important;
				max-width: 100% !important;
				height: 100% !important;
			   //  cursor: pointer;
				}
   

		     .banner-img-width{
			   width: 100% !important;
		     }

		     .grid-stack-item-content {
			   padding-top: 36px;
			   background-color: #fff !important;
		     }
		  
		  
		    //  .test {
			//    background-color: #76b101 !important;
			//    color: white !important;
		    //  }
		  
		     .label .pricelabel svg {
               height: 150px !important;
			   width: 150px !important;
		     }
		  
		     .gifImg {
		 	   width: 100% !important;
			   object-position: center !important;
		     }

		     .count-products i {
			   font-size: 19px;
			   color: #000;
			   cursor: pointer;
		     }
         
             .count-products span {
	           color: black;
	           font-weight: 600;
             }  

             .add_cart_icon, .deleteicon {
	           display:none !important;
             }

             .layout_product_qnt.product_qnt{
               display:none !important;
             }

             .circular-card-deal {
		       width: 5.5vw !important;
		       height: 5.5vw !important;
		       font-size: 3vw !important;
	           padding: 12%;
	           z-index: 300;
	           border: 4px solid ${this.store_data[0]?.theme_base_color || 'rgb(139, 195, 74)'};
	           border-radius: 50%;
	           background-color: #fff;
	           color: ${this.store_data[0]?.theme_base_color || 'rgb(139, 195, 74)'};
	           font-weight: 700;
	           line-height: 1;
	           font-family: "Oswald", sans-serif;
	           text-transform: uppercase;
             }
  
             .circular-card.size-11 .circular-card-deal {
	           padding: 5% 8%;
             }
  
             .circular-card.standard .circular-card-deal {
	           background-color: #fff;
	           color: rgb(139, 195, 74);
	           border-color: rgb(139, 195, 74);
             }
  
             .price-container {
	           display: -webkit-box;
	           display: -webkit-flex;
	           display: -ms-flexbox;
	           display: flex;
	           width: 100%;
	           height: 100%;
	           max-width: 100%;
	           max-height: 100%;
	           -webkit-box-pack: center;
	           -webkit-justify-content: center;
	           -ms-flex-pack: center;
	           justify-content: center;
	           -webkit-box-align: center;
	           -webkit-align-items: center;
	           -ms-flex-align: center;
	           align-items: center;
	           -webkit-box-orient: vertical;
	           -webkit-box-direction: normal;
	           -webkit-flex-direction: column;
	           -ms-flex-direction: column;
	           flex-direction: column;
             }

             .deleteicon{
	           display:none !important;
             }
  
             .circular-card-deal-content {
               display: -webkit-box;
               display: -webkit-flex;
               display: -ms-flexbox;
               display: flex;
	           -webkit-box-orient: vertical;
	           -webkit-box-direction: normal;
	           -webkit-flex-direction: column;
	           -ms-flex-direction: column;
	           flex-direction: column;
	           -webkit-box-align: center;
	           -webkit-align-items: center;
	           -ms-flex-align: center;
	           align-items: center;
	           -webkit-box-pack: center;
	           -webkit-justify-content: center;
	           -ms-flex-pack: center;
	           justify-content: center;
	           height: 100%;
             }
  
             .circular-card-price {
	           display: -webkit-box;
	           display: -webkit-flex;
	           display: -ms-flexbox;
	           display: flex;
	           -webkit-box-orient: vertical;
	           -webkit-box-direction: normal;
	           -webkit-flex-direction: column;
	           -ms-flex-direction: column;
	           flex-direction: column;
	           -webkit-box-align: center;
	           -webkit-align-items: center;
	           -ms-flex-align: center;
	           align-items: center;
             }
  
             .circular-card-price .top-line,
             .circular-card-price .bottom-line {
	           display: -webkit-box;
	           display: -webkit-flex;
	           display: -ms-flexbox;
	           display: flex;
             }
  
             .circular-card-price .dollar-sign,
             .circular-card-price .bignum,
             .circular-card-price .price-unit {
	           position: relative;
             }
  
             .dollar-sign,
             .percent-sign {
	          font-size: 50%;
             }
  
             .dollar-amount,
             .big-cents {
	          line-height: 85%;
             }
			 .pack-info-container {
				FONT-VARIANT: JIS04;
				position: relative !important;
				bottom: 120px !important;
				font-size: 14px !important;
				line-height:1 !important;
				left: 7%;	
				word-wrap: break-word;
				width: 80%;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: visible;
				z-index:9999 !important;
			 }
			
             .circular-card-price .price-unit-wrapper {
	           display: -webkit-box;
	           display: -webkit-flex;
	           display: -ms-flexbox;
	           display: flex;
	           -webkit-box-orient: vertical;
	           -webkit-box-direction: normal;
	          -webkit-flex-direction: column;
	          -ms-flex-direction: column;
	          flex-direction: column;
             }
  
             .price-unit-wrapper,
              .paint-green .circular-card-price {
	            -webkit-align-self: flex-start;
	            -ms-flex-item-align: start;
	            align-self: flex-start;
              }
  
              .cents-sign,
              .cents-amount,
              .bottom-line {
             	font-size: 60%;
              }
  
              #format3 .price-unit,
              #format4 .price-unit {
             	font-size: 30%;
              }
  
              #format5 .num-to-cart,
              #format6 .num-to-cart {
	           -webkit-box-pack: center;
	           -webkit-justify-content: center;
	           -ms-flex-pack: center;
	           justify-content: center;
	           display: -webkit-box;
	           display: -webkit-flex;
	           display: -ms-flexbox;
	           display: flex;
             }
  
              #format5 .dollar-amount,
              #format5 .pricing-num-for,
              #format6 .pricing-num-to-cart,
              #format6 .pricing-num-for {
	            padding-right: 2px;
	            padding-bottom: 2px;
              }
  
              #format5 .dollar-amount,
              #format5 .third-amount,
              #format5 .pricing-for,
              #format6 .pricing-buy-get,
              #format6 .dollar-amount,
              #format6 .cents-amount,
              #format6 .third-amount {
	            font-size: 36%;
             }
  
             .pricing-buy-get,
             .pricing-num-for {
	           padding-right: 4px;
	           padding-bottom: 0px;
             }   
               
            .grid-stack {
	          display: flex; 
	          flex-wrap: wrap; 
	          width: 100%; 
             } 

            .grid-stack.simple_pro >.grid-stack-item-content { 
              flex: 0 0 15%; 
             } 

             .dcp-logo{
               height:auto !important;
	           display:flex;
	           justify-content:space-evenly;
	           margin-top: 20px;
	           margin-bottom: 5px;
             }

             .price-goods{
	           display:flex;
	           justify-content:end;
	           background-color:${this.store_data[0]?.theme_base_color || 'rgb(139, 195, 74)'};
	           margin-top: 10px;
               margin-left: 10px;
	           margin-right:10px;
             }

             .price-goods span {
	           padding: 0px 5px;
	           color: #fff;
	           font-weight: 500;
              }




             .gs-id-0 > .grid-stack-item > .grid-stack-item-content {
               inset: 10px;
             }

             .grid-stack > .grid-stack-item > .grid-stack-item-content {
               margin: 0;
               position: absolute;
               width: auto;
               overflow-x: hidden;
               overflow-y: auto;
             }

              .grid-stack-item-content:has(.hide_bar) {
                padding-top: 36px !important;
              }
		      .grid-stack-item-content > img.hide_bar {
				object-fit: fill !important;
				max-width: 95% !important;
				height: 95% !important;
				    position:absolute !important;
				   top:-5px !important;
				   left:5px !important;
				}
			 .grid-stack-item-content >.fill_video >video.hide_bar{
				object-fit: fill !important;
				max-width: 95% !important;
				height: 95% !important;
			    position:absolute !important;
				top:-5px !important;
				left:5px !important;
				}
			   	.grid-stack-item-content >iframe.hide_bar{
				object-fit: fill !important;
				max-width: 95% !important;
				height: 100% !important;
				}
			   	.grid-stack-item-content >.iframe >iframe.hide_bar{
				object-fit: fill !important;
				max-width: 100% !important;
				height: 100% !important;
				}
			  hr{
				margin-bottom:50px !important;
				margin-top:40px !important;
				margin-left:11px !important;
				margin-right:18px !important;
				width:98% !important;
			 }
			 .offerLabel{
				width: auto !important;
				height:auto !important;
				top: 34px !important;
				display:unset !important;
				right:0px !important;
				left:0px !important;

			 }
			 .offerLabel span {   
				display: -webkit-box;
			   -webkit-line-clamp: 1;
			   -webkit-box-orient: vertical;
				overflow: hidden;
				word-wrap: normal;
				  font-size: 11px !important;
				line-height: -1px !important;
				right:0 !important;
				padding-top: 4px !important;
				width: 60% !important;
				left: 10px !important;
			   }

			 .offerLabel img{
			   width:70% !important;
			  }
			 .sell-price{
				display: flex;
			  }
			  .price_format_1,.price_format_2,.digital_coupon{
				display: flex;
				position: relative;
			  }
			  /* .price_format_1{
				align-items: center;
			  } */
			  .price_format_wrapper,.final_price_format_wrapper{
				display: flex;
				flex-direction: column;
			  }
			  /* .price_format_wrapper{
			  padding-left: 14px;
			  } */
			  .price_format_data_2{
				margin-top: -4px;
			  }
			  .for_title,.price_cutout{
				display: flex;
				flex-direction: column;
			  }
			  .digital_coupon_data{
				display: flex;
				position: relative;
			  }
			  .Digital_price_main{
			   border: 3px solid black;
			  width: fit-content;
			  position: absolute;
			  right: 12px;
			  bottom: 5px;
			  
			  border-top-left-radius: 10px;
			  border-bottom-right-radius: 10px;
			  }
			  .scissors_img{
				width: 50px !important;
			  }
			  .use_up_to_wrapper{
				border: 3px solid black;
			  width: fit-content;
			  border-top-left-radius: 10px;
			  border-bottom-right-radius: 10px;
			  position: absolute;
			  bottom: 5px;
			  left: 5px;
			  max-width: 112px;
			  }
			  .use_up_to_wrapper h2{
				background: #ffcf16;
				color: red;
				font-size: 16px;
				line-height: 1.5;
				margin: 0;
				text-align: center;
				font-weight: 700;
				border-top-left-radius: 10px;
			  }
			  .price_main_wrapper_cut{
				background-color: #d82027;
			  }
			  .price_main_wrapper_cut{
				display: flex;
			  gap: 5px;
			  justify-content: center;
			  align-items: center;
			  }
			  .price_main_wrapper_cut h3{
				font-size: 50px;
				line-height: 50px;
				color: white;
				margin: 0;
			  font-weight: 800;
			  }
			  .price_cutout span{
				font-size: 14px;
				line-height: 1;
				color: white;
				font-weight: 500;
				padding-top: 2px;
			  
			  }
			  .price_format_1.final_price_value_wrapper{
				justify-content: center;
				padding-top: 4px;
			  }
			  /* .price_format_1.final_price_value_wrapper .price_format_data_1{
				font-size: 19px;
				line-height: 20px;
			  } */
			  .use_up_to_wrapper h6{
				background-color: #d82027;
				color: white;
				margin: 0;
				font-size:13px;
				font-weight: 600;
				line-height: 15px;
				padding: 2px;
				text-align: center;
				border-bottom-right-radius: 10px;
			  }
			  .price_link_data svg{ 
				width: 30px !important;
				fill: white;
				/* background-color: #f04451; */
				/* padding: 5px; */
				border-radius: 50%;
				height: 17px;
				line-height: 10px;
				margin-top: 5px;
			  }
			  .price_link_data{
				position: absolute;
				top: 2px;
				left: 11px;
				width: 34px;
				height: 32px;
				border-radius: 50px;
				background:  #d82027;}
			  .sell_title_wrapper{
				display: flex;
				background-color: white;
				justify-content: space-between;
				padding: 5px;
				border-top-left-radius: 10px;
			  
			  }
			  .digital_coupon{
				background: #ffcf16;
			  align-items: center;
				justify-content: space-between;
				padding: 0 2px;
			  max-height: 35px;
			  }
			  .sell_title {
				font-size: 11px;
				font-weight: 600;
				text-align: left;
				color: black;
				line-height: 1;
				text-transform: uppercase;
			  }
			  .sale_condition_change_format_2 .for_title, .sale_condition_change_format_2  .price_Format_2_dataprice, .sale_condition_change_format_2  .format_2_offer_value, .sale_condition_change_format_2  .format_2_doller_symbol, .sale_condition_change_format_2 .format_2_price_value{
				color: black;
			  }
			  .sale_condition_change_format_2 {
				padding: 0 !important; 
			  }
			  .sale_condition_change_format_2 .format_2_doller_symbol {
			   margin-top: 3px;
			   padding-left: 5px;
			  }
			  .sale_condition_change_format_2 .format_2_offer_value{
				margin-top: 3px !important;
			  }
			  .sale_condition_change_format_2 .for_title{
				font-size: 9px;
				line-height: 0.9;
			  }
			  .sell_price_format2_wrapper{
				max-height: 35px;
			  }
			  
			  .digital_coupon_title{
				font-size: 10px;
				font-weight: 400;
				text-transform: uppercase;
				text-align: left;
				line-height: 1;
				color: black;
			  }
			  .price_format_data_1 ,.price_format_data_3,.digital_coupon_doller, .off2_offer > div,.format_2_doller_symbol,.format_2_offer_value{
				font-size: 13px;
				font-weight: 700;
				color: black;
				line-height: 14px;
			  }
			  .price_format_data_2 ,.digital_coupon_price_value{
				font-size: 32px;
				color: black;
				line-height: 40px;
				font-weight: 800;}
				/* .price_format_data_3{
			  margin-left: -3px;
				} */
				.price_format_data_1,.price_format_data_3{margin-top: 4px;}
				.digital_coupon_dess{
			  display: flex;
			  align-items: center;
			  font-size: 210%;
			  font-weight: 400;
			  color: black;
			  
				}
				.off2_offer{
				  display: flex;
				  flex-direction: column;
				  align-items: baseline;
				  justify-content: center;
				}
				.off2_offer:last-child{
				  margin-top: 4px;
				}
				.final_price, .digital-note {
				  background: #d82027;
				  color: white;
			  }
			   .digital-note{
				font-weight: 600;
				font-size: 13px;
				line-height: 15px;
				border-bottom-right-radius:10px;
				padding-bottom: 3px;
				text-transform: uppercase;
			   }
			   .price_format_1.final_price_value_wrapper .price_format_data_2{
				font-size: 32px;
				line-height: 40px;
			   }
			  .final_price_title{
				color: white;
				font-weight: 700;
				font-size: 18px;
				  line-height: 24px;
			  }
			  .with_digital_coupon_sub {
				font-size: 12px;
				padding: 0 5px;
				color: white;
				line-height: 1;
				font-weight: 600;
				text-transform: capitalize;
			  }
			  .price_format_2 {
				padding: 5px;
				margin: 0 auto;
			  }
			  .for_title{
				font-size: 10px;
				color: white;
				font-weight: 600;
				line-height: 0.9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding-left: 4px;
			  padding-right: 4px;
			  }
			  .price_Format_2_dataprice,.format_2_price_value{
				color: white;
				font-size: 29px;
				line-height: 1;
				font-weight: 700;
			  
			  }
			  .sell_price_format2_wrapper
			  .price_format_1.final_price_value_wrapper {
				justify-content: center;
				padding-top: 5px;
			  }
			  .price_format_1.final_price_value_wrapper .price_format_data_1,.price_format_1.final_price_value_wrapper .price_format_data_2,.price_format_1.final_price_value_wrapper .price_format_data_3{
				color: white !important;
			  }
			  /* .price_format_1.final_price_value_wrapper .price_format_data_3{
				margin-left: 1px !important;
			  } */
			  
			  .format_2_doller_symbol, .format_2_offer_value{
				color: white;
				font-size: 14px;
				line-height:14px;
				margin-top: 2px;
			  }
			  .format_2_doller_symbol{
				padding-left: 10px;
			  }
			  
			  .price_cutout svg{
				fill: white;
				width: 30px;
			  }
			  /* .off2_offer:last-child{
				margin-top: -5px;
			  } */
			  .digital_coupon_price_value{
				font-size: 34px;
				display: flex;
				align-items: center;
				line-height: 35px;
			  }
			  .digital_coupon_doller{
			  display: flex;
			  align-items: center;
			  margin-top: -5px;
			  }
			  .sell_wrapper_format_1 .price_format_data_1,.sell_wrapper_format_1 .price_format_data_3{
				margin-top: 0;
				font-size: 16px;
			  }
			  
			  .offer_text_value .digital_coupon_doller{
				font-size: 15px;
				line-height: 15px;
			  }
			  .final_sale_value{
				align-items: normal;
			  }
			  
			  .offer_text_value {
				display: flex;
				align-items: baseline;
				gap: 2px;
			  }
			  .final_sale_value .price_format_data_2, .final_sale_value .price_format_data_1, .final_sale_value .price_format_data_3{
				margin-top: 0 !important;
			  }
			  .sell_wrapper_format_2 .format_2_doller_symbol{
				padding-left: 0;
			  }
			  .sell_wrapper_format_2{
				padding: 3px;
			  }
			  .final_price_wrraper_format2 .format_2_doller_symbol{    padding-left: 1px;
				padding-left: 1px;
				font-size: 17px;
				line-height: 22px;
				margin: 0;
				display: flex;
				align-items: center;}
				.final_price_wrraper_format2 .format_2_price_value, .final_price_wrraper_format2 .price_Format_2_dataprice{
				  font-size: 36px;
				  line-height: 40px;
				  font-weight: bolder;
				}
				.final_sale_value .price_format_data_3{
				  font-size: 36px;
				  line-height: 40px;
				}
				.off2_offer > div{
				  display: flex;
				  text-transform: uppercase;
				}
				.final_sale_value .price_format_data_1{
				  font-size: 18px;
				  line-height: 18px;
				  margin-top: -3px !important;
				  align-items: center;
				  display: flex;
				}
				.linkSymbol{
				  position: absolute;
				  top: 3px;
				}
				.digital_coupon_section{
					text-align:center;
				}
				.digital_coupon_section{
					text-align:center;
					margin-top: 10px;
					margin-bottom: 10px;
					font-weight: 540 !important
				  }
			  
              @media print { 
		        @page{
					margin-left:1px !important;
					margin-right:14px !important;
					margin-top:30px !important;
					margin-bottom:0px !important ;
				   }

				@page :first{
					margin:1px !important;
					margin-right:14px !important;
					margin-bottom:0px !important;
					margin-top:0px !important;
					padding-bottom:0 !important;
				}

				body {
					-webkit-print-color-adjust: exact; /* Chrome, Safari */
					color-adjust: exact; /* Firefox */
				}
				
		         .button.ytp-large-play-button.ytp-button.ytp-large-play-button-red-bg{
		           display:none !important;
		         }
		
		         .grid-stack.simple_pro {
		           display: grid;
 			       grid-template-columns: repeat(4, 1fr);
			       gap: 0px; 
				   margin-top:35px ;
		         } 
				   .break-bef{
				   page-break-inside:avoid !important;
				   }
				 .grid-stack{
					background-color:white !important;
					margin-left:10px ;
				  }
				 .digital_coupon_section{
					text-align:center;
				}
				 .grid-stack.digital_coupon_section {
					display: grid;
					 grid-template-columns: repeat(3, 1fr);
					gap: 0px; 
					margin-top:35px ;
				  } 
	             .grid-stack-item-content { 
		           width: 100%;
	             } 

				 .banner-img-width{
	               height:auto !important;
				 }
				 
				 .pack-info-container {
					FONT-VARIANT: JIS04;
					position: relative !important;
					bottom: 65px !important;
					font-size: 14px !important;
					line-height:1 !important;
					left: 10%;  
					word-wrap: break-word;
					width: 80%;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					overflow: hidden;
				   }
			      

		         .pricelabel {
			       position: relative !important;
				   bottom: 81px !important;
				   left: 2px !important;
			       width: 4vw !important;
	               height: 4vw !important;
			       font-size: 2.5vw !important;
		         }

		         .grid-stack-item-content { 
			       background-color: white; 
			       border: 1px solid #ccc; 
			       text-align: center; 
			       width:168px;
	               height:160px;
				   padding-top:3px !important;
				 padding-bottom:00px !important;
				  padding-left:0px !important
				  padding-right:0px !important;
				   position:relative !important;
				}
				.grid-stack-item-content.digital_coupon_div{ 
					background-color: white; 
					border: 1px solid #ccc; 
					position:relative !important;		
					text-align: center; 
				    width:355px !important;
					height:280px !important;
					padding:25px 10px  !important;
					overflow:hidden !important;
				 }
				.grid-stack-item-content > img {
					object-fit: fill !important;
					max-width: 100% !important;
					height: 100% !important;
				   //  cursor: pointer;
			   height:125px !important;
			   width:100% !important;
			   padding-top:40px;
					}

					.grid-stack-item-content >.fill_video >video{
						object-fit: fill !important;
						max-width: 100% !important;
						height: 100% !important;
					   //  cursor: pointer;
				   height:125px !important;
				   width:100% !important;
				   padding-top:40px;
						}
				.grid-stack-item-content.digital_coupon_div > img {
				object-fit: fill !important;
				max-width: 100% !important;
				height: 100% !important;
				}
			    .grid-stack-item-content.digital_coupon_div  >.fill_video >video{
				object-fit: fill !important;
				max-width: 100% !important;
				height: 100% !important;
			   //  cursor: pointer;
				}
			     .packDetailsName{
			     color: black !important;
			     font-size:14.5px !important;
			     display: -webkit-box;
			     -webkit-line-clamp: 1;
			     -webkit-box-orient: vertical;
			     overflow: hidden;
			     line-height:1.1 !important;
			     margin-bottom:0 !important;
			    }
  
			// .fb-video >span >iframe{
			// 	   width:200px !important;
			// 	   height:150px !important;
			// 	   }
		         .product-title{
			       background-size: cover;
			    
			       top: -10px !important;
			       color: #000 !important;
			       font-weight: 1 !important;
	               height: 41px !important;
				   z-index: 9999 !important;
				position:absolute!important;
				   color: black !important;
			       color: #000;
			       padding-left:0 !important;
			       padding-right: 0 !important;
			       left:0 !important;
				   right:0 !important;
			       background-color:transparent !important;
				   overflow:visible !important;
				   	display:flex !important;
					flex-direction:column !important;
					justify-content:center !important;
		         }

		         .product-title p{
			      font-size:11px !important;
			       -webkit-line-clamp:2 !important;
				
		         }
				 .digital_coupon_section{
				 break-inside:avoid !important;
				 }
				 .digital_coupon_section >.digital_title{
					text-align:center;
					margin-top: 40px !important;
					margin-bottom: 30px;
					font-size:17px !important;
				  }
			  
				//  .grid-stack-item-content.digital_coupon_div { 
				// 	width:355px !important;
				// 	height:320px !important;
				//  }

				 .grid-stack-item-content.digital_coupon_div >.product-title{
					padding-left:30px !important;
					padding-right:0 !important;
					top: -10px !important;
					height:40px !important;
					width:88% !important;
					display:flex !important;
					flex-direction:column !important;
					justify-content:center !important;
				  }
 
				  .grid-stack-item-content.digital_coupon_div >.product-title p{
					font-size:15px !important;
					display: -webkit-box;
                    -webkit-box-orient: vertical;
                     overflow: hidden;
					 margin-left:1px !important;
					-webkit-line-clamp:2 !important;
				   }
				   .grid-stack-item-content.digital_coupon_div >.product-title >.packDetailsName{
				     line-height:1.1 !important;
					 font-size:18px !important;
					   display: -webkit-box;
                      -webkit-box-orient: vertical;
                      overflow: hidden;
					-webkit-line-clamp:2 !important;

					}
				  .grid-stack-item-content.digital_coupon_div >.pack-info-container {
					bottom: 18px !important;
					font-size: 15px !important;
				   }
				   .price_link_data{
					top: 5px !important;
					left: 2px !important;
				}

		         .extraSmall {
		           width: 4vw;
	               height: 4vw;
			       font-size: 2.5vw;
		         }

		        .dcp-logo{
	              height:70px !important;
			      display:flex;
				  justify-content:space-evenly;
			      text-align:center !important;
			      margin-top: 0px !important;
			      margin-bottom:5px !important;
				  align-items: center !important;
		         }
				 .dcp-logo img{
					height: auto !important;
		            margin-top:10px !important;
				 }
				.banner-image-home img{
				height:100%;
				margin-top:5px !important;
				}
				.banner-image-home{
				height:80px }
				 .logo_text{
					font-size:40px !important;
					height: 80px !important;
					margin-block-start:0px  !important;
					margin-block-end: 0px  !important;
					margin-top:30px !important;									}
				hr{
					margin-bottom:50px !important;
					margin-top:40px !important;
					margin-left:11px !important;
					margin-right:2px !important;
					width:100% !important;
				 }
				 
	             .price-goods{
	               margin-top: 10px;
                   margin-left: 10px;
		           margin-right:0px;
		           display: flex;
		           justify-content: flex-end;	
	               background-color:${this.store_data[0]?.theme_base_color || 'rgb(139, 195, 74)'};
		           align-items: flex-end; /* Align items at the bottom */

	             }	

	             .price-goods span {
		          padding: 0px 5px;
		          color: #fff;
		          font-weight: 500;
	             }

			   .grid-stack-item-content > img.hide_bar {
				object-fit: fill !important;
				max-width: 90% !important;
				height: 100% !important;
				position:absolute !important;
				top:-40px !important;
				left:10px !important;
				}
			   .grid-stack-item-content >.fill_video >video.hide_bar{
				object-fit: fill !important;
				max-width: 90% !important;
				height: 100% !important;
			    position:absolute !important;
				top:-40px !important;
				left:10px !important;
				}
			   	.grid-stack-item-content >.iframe >iframe.hide_bar{
				object-fit: fill !important;
				max-width: 90%!important;
				height: 60% !important;
			    position:absolute !important;
				top:38px !important;
				left:10px !important;
		        background-color:transparent !important;;
				}

	             .grid-stack-item-content:has(.hide_bar) {
		           padding-top: 5px !important;
	             }
				 .offerLabel{
					width: auto!important;
	                height:auto !important;
					top:40px !important;
					display:unset !important;
					right:0px !important;
					left:0px !important;
			     }
			     .offerLabel span {     
			    	display: -webkit-box;
			       -webkit-line-clamp: 1;
			       -webkit-box-orient: vertical;
			    	overflow: hidden;
			    	word-wrap: normal;
					  font-size: 12px !important;
			    	font-size: 25px !important;
			    	line-height: -1px !important;
			    	right:0 !important;
					  padding-top:7px !important;
					  width:55% !important;
					  left:5px !important;
					  font-weight:200 !important;
				   }

			     .offerLabel img{
			       width:70% !important;
			     }
	
                 .offerLabel span{
                    font-size:12px !important;
                 }
				 .wrapper-main{
					display:none !important;
				 }
			.circular-card-deal {
		       width: 7.5vw !important;
		       height: 7.5vw !important;
		       font-size: 4vw !important;
             }
			
            }
            </style>
             </head>
            <body onload="window.print();window.close();" style='font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'>
	        <div class="break-bef">
			<div class="dcp-logo">${header}</div>
				  <hr>
				    <div class="grid-stack simple_pro"> ${gridContent} </div>
					<div class="digital_coupon_section" ${DIGITalCoupon ? '' : 'style="display: none;"'}>
					<div class="digital_title"><h2> DIGITAL COUPON SECTION</h2> </div>
					<div class="grid-stack digital_coupon_1">${DIGITalCoupon}</div> </div>
					</div>
				    <div class ="price-goods">${footer}</div>
					</div>
              </body>
        </html>` );
		printWindow.document.close();
	}
	open_print() {
		this.analytics.logEvent('@ShoppingList_print_click_count', {
			message: 'ShoppingList Print Button Clicked.'
		})
		$(".print-head").removeClass("show-logo");
		this.showLoader.emit(true)
		setTimeout(() => {
			let printContents, popupWin;
			printContents = document.querySelector(".listingData").innerHTML;
			popupWin = window.open("", "_blank", "top=0,lefheight=100%,width=auto");
			popupWin.document.open();
			popupWin.document.write(`
			<html>
				<head>
				<style>
					.text-center {
					text-align: center!important;
					}

					.main-print .listingData {
		                height: auto;
						height: auto;
					}
					.mt-5, .my-5 {
						margin-top: 3rem!important;
					}
					.d-flex {
						display: flex!important;
					}
					.align-items-center {
						align-items: center!important;
					}
					.items-list{
						padding: 10px !important;
						border-bottom: 2px solid #ddd;
					}
					.main-print {
						width: 21cm;
						margin: 0 auto !important;
					}
					.print-listingData {
					background-color: white;
					}
					.print-itm-content {
					text-align: left;
					}
					.items-list.print-list {
					background-color: white;
					}
					.main-print .listingData {
	                height: auto;
					height: auto;
					}
					.main-print .cls-btn {
					justify-content: unset;
					}

					#print-div .product-list  div{
						justify-content:space-between !important;
					}
					#print-div .recipe-list   div{
						justify-content:space-between !important;
					}
					#print-div .coupen-list   div{
						justify-content:space-between !important;
					}
					.print-logo img {
						height: 90px;
						margin-top: 1rem;
					}
					.item-name{
						width:300px !important;
					}
					.print-head p {
					color: #6b6b6b;
						font-size: 14px;
						margin: 0;
					}
					.print-store-name {
					font-size: 1.17rem;
						margin: 10px 0px;
						color: #6b6b6b;
					}
					.item-img img, video,.select-all-with-checkbox span {
						padding-left: 10px;
						color: #fff;
						font-weight: 400;
						margin-left: 10px;
					}
					.item-img img {
		              height: 72px;
						width: 82px;
					}
					.item-img video{
						height: 72px;
						width: 82px;
					}
					img {
						vertical-align: middle;
						border-style: none;
					}
					.image-box {
						margin-right: 15px;
					}
					.item-img {
						display: flex;
						align-items: center;
					}
					.col-md-8{
						position: relative;
						width: 100%;
						height: 1px;
						padding-right: 15px;
						padding-left: 15px;
					}
					.item-name {
						align-items: flex-start;
						display: flex;
						flex-direction: column;
					}
					.item-title {
						font-size: 26px;
					}
					.item-content {
						color: #828282;
						font-size: small;
					}
					.print-itm-content {
						text-align: left;
					}
					.item-text-p {
						font-size: 20px !important;
					}
	                .item-content p {
                    margin: 5px 0 !important;
                    }
					.item-text-span {
						font-size: 20px !important;
					}
					.cls-btn, .current-item, .remove-btn {
						display: flex;
						align-content: center;
						align-items: center;
						justify-content: space-around !important;
					}
					.cls-btn{
						cursor: pointer;
					}
					.item-img input{
						display:none !important;
					}
					.d-none{
						display:none !important;
					}
					@page {
						size: A4 letter;
						margin: 10mm;
						margin-top: 12mm;
						@bottom-right {
							font-family: Arial, sans-serif;
							font-size: 12px;
							color: #949496;
							white-space: pre-line noWrap;
						}
						@bottom-left {
							font-family: Arial, sans-serif;
							font-size: 12px;
							color: #949496;
							white-space: pre-line;
						}
						#print-div .product-list  div{
							justify-content:space-between !important;
						}

						.item-name{
							width:300px !important;
						}
					}
				</style>
				</head>
            <body onload="window.print();window.close();" style='font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";'>
			<div class="main-print" id="print-div">${printContents}</div></body>
			</html>`);
			popupWin.document.close(
				this.closeDialog()
			);
		}, 2000);
	}

	closeDialog() {
		this.printCart = false;
		$(".print-head").addClass("show-logo");
		this.showLoader.emit(false)
	}

	async add_item_change(event) {
		let temp = []
		if (this.combinedData) {
			this.all_items.forEach(item => {
				const check = this.combinedData.some(l => l.id == item.uid)

				if (check) {
					item.isOnSale = true
					temp.push(item)
				} else {
					item.isMaster = true
					temp.push(item)
				}
			})
			this.all_items = temp
			this.filtered_items = this.all_items.filter(i => {
				let a = i?.product_description?.toLowerCase()
				return a?.includes(event?.target?.value?.toLowerCase())
			})
			if (event.target.value.length > 0 && this.filtered_items.length < 1) {
				this.no_content_display = true
			} else {
				this.no_content_display = false
			}
		} else {
			this.filtered_items = this.all_items.filter(i => {
				let a = i?.product_description.toLowerCase()
				return a.includes(event.target.value.toLowerCase())
			})
			if (event.target.value.length > 0 && this.filtered_items.length < 1) {
				this.no_content_display = true
			} else {
				this.no_content_display = false
			}
		}
	}

	createCart() {
		this.afs.collection('/list').add({ items: this.cartItem }).then(docRef => {
			const itemRefx2 = this.afs.doc('/list/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			localStorage.setItem('list_id', docRef.id)
			let list_ids;
			if (localStorage.getItem('list_ids')) {
				list_ids = JSON.parse(localStorage.getItem('list_ids'))
				list_ids[this.store_name_slug] = docRef.id;
				localStorage.setItem('list_ids', JSON.stringify(list_ids))
			} else {
				list_ids = {
					[this.store_name_slug]: docRef.id
				}
				localStorage.setItem('list_ids', JSON.stringify(list_ids))
			}
			this.current_list_id = docRef.id
			this.qr_value = window.location.href + '/list/' + this.current_list_id;
		});
	}
	generateRandom16DigitNumber() {
		let min = 1000000000000000; // Minimum 16-digit number
		let max = 9999999999999999; // Maximum 16-digit number
		return Math.floor(Math.random() * (max - min + 1)) + min;
	}

	async add_static() {
		let inpt: any = document.getElementById('addItem')
		if (inpt.value != '' && inpt.value != null) {
			let randomNumber = this.generateRandom16DigitNumber();
			let item = {
				product_description: inpt.value, // Assuming inpt.value contains the product name
				product_image: "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726",
				uid: randomNumber // Add the random number to the object
			}
			this.cartItem.push({ product: item, count: 1, static: true })
			if (this.current_list_id !== '') {
				await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
				});
			}
			else {
				if (!this.createdCart) {
					this.createdCart = true;
					await this.createCart();
				}
			}
			this.filtered_items = []
			inpt.value = ''
			this.no_content_display = false
			this._snackBar.open('Item added in your list', '', {
				duration: 2000,
			});
		} else {
			this._snackBar.open('Please add Item Name', '', {
				duration: 2000,
			});
		}
	}

	toDataURL(url, callback) {
		var xhr = new XMLHttpRequest();
		xhr.onload = function () {
			var reader = new FileReader();
			reader.onloadend = function () {
				callback(reader.result);
			}
			reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.send();
	}

	hendle_Add_item(type) {
		if (type === 'add') {
			this.show_add_item = true
		}
		if (type === 'close') {
			this.show_add_item = false
			this.filtered_items = []
		}
	}
	async remove_one_from_cart(i, type) {
		if (!this.serializedIds && this.serializedIds == undefined) {
			this.serializedIds = this.combinedData.map(s => s.id)
		}
		if (type === 'one') {
			this.cartItem.splice(this.cartItem.indexOf(i), 1)
			if (!i.static && this.serializedIds.includes(i?.product?.uid) && !this.isCoupon && !this.isRecipe) {
				let main_item
				let title_bar
				let delete_icon
				let layout_product_qnt
				let layout_count_product: HTMLElement
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view == "listView" && window.innerWidth <= 768) {
					if(i?.product?.isBundleProduct){
						main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement?.parentElement;
					}else{
						main_item = document.getElementById(i?.product?.uid)?.parentElement?.parentElement;
					}
					delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
					layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
				} else {
				    if(i?.product?.isBundleProduct){
				    	if (document.getElementById(i?.product?.uid)) {
				    		if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
				    			if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
				    				main_item = document.getElementById(i?.product?.id)?.parentElement
				    			} else {
				    				main_item = document.getElementById(i?.product?.id)?.parentElement
				    			}
				    		} else {
				    			main_item = document.getElementById(i?.product?.uid)?.parentElement
				    		}
				    	}
				    }else{
				    	if (document.getElementById(i?.product?.uid)) {
				    		if (document.getElementById(i?.product?.uid).tagName != 'VIDEO') {
				    			if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
				    				main_item = document.getElementById(i?.product?.id)?.parentElement
				    			} else {
				    				main_item = document.getElementById(i?.product?.id)
				    			}
				    		} else {
				    			main_item = document.getElementById(i?.product?.uid)?.parentElement
				    		}
				    	}
				    }
					layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
					layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
					title_bar = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
				}
			if(layout_product_qnt?.innerHTML){
				layout_product_qnt.innerHTML = ''
			}
			if (layout_count_product?.style?.visibility) {
				layout_count_product.style.visibility = 'hidden'
			}
				this.cart_ids.splice(this.cart_ids.indexOf(i?.product?.uid), 1)
			if(delete_icon){
				delete_icon.src = "../../../assets/imgs/plus.png"
			}
			} else if (!i.static && this.serializedIds.includes(i?.product?.id) && !this.isCoupon && !this.isRecipe) {
				let main_item
				let title_bar
				let delete_icon
				let layout_product_qnt
				let layout_count_product: HTMLElement
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view == "listView" && window.innerWidth <= 768) {
					if(i?.product?.isBundleProduct){
						main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement?.parentElement;
					}else{
						main_item = document.getElementById(i?.product?.id)?.parentElement?.parentElement;
					}
					delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
					layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
				} else {
					if(i?.product?.isBundleProduct){
						if (document.getElementById(i?.product?.id)) {
							if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
								if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
									main_item = document.getElementById(i?.product?.id)?.parentElement
								} else {
									main_item = document.getElementById(i?.product?.id)?.parentElement
								}
							} else {
								main_item = document.getElementById(i?.product?.id)?.parentElement
							}
						}
					}else{
						if (document.getElementById(i?.product?.id)) {
							if (document.getElementById(i?.product?.id).tagName != 'VIDEO') {
								if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
									main_item = document.getElementById(i?.product?.id)?.parentElement
								} else {
									main_item = document.getElementById(i?.product?.id)
								}
							} else {
								main_item = document.getElementById(i?.product?.id)?.parentElement
							}
						}
					}

					layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
					layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
					title_bar = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
				}
				if (layout_product_qnt) {
					layout_product_qnt.innerHTML = ''
				}
				if (layout_count_product?.style?.visibility) {
					layout_count_product.style.visibility = 'hidden'
				}
				this.cart_ids.splice(this.cart_ids.indexOf(i?.product?.id), 1)
            if(delete_icon){
				delete_icon.src = "../../../assets/imgs/plus.png"
			}			
			}
		}
		if (type == 'selected') {
			this.cartItem = this.cartItem?.filter(itm => {
				if (!this.selected_cart_items.includes(itm)) {
					return true
				} else {
					if (this.serializedIds.includes(itm?.product?.uid) && !this.isCoupon) {
						let main_item
						let title_bar
						let count_icon_plus: HTMLElement
						let count_icon_minus: HTMLElement
						let delete_icon
						let layout_product_qnt
						let layout_count_product: HTMLElement
						const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
						if (view == "listView" && window.innerWidth <= 768) {
							if(itm?.product?.isBundleProduct){
								main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement?.parentElement;
							}else{
								main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement;
							}
							delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
							layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
							count_icon_plus = main_item?.getElementsByClassName("count-products")[0]?.firstChild as HTMLElement;
							count_icon_minus = main_item?.getElementsByClassName("count-products")[0]?.lastChild as HTMLElement;
						} else {
							if(itm?.product?.isBundleProduct){
								if (document.getElementById(itm?.product?.uid)) {
									if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
										if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										} else {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										}
									} else {
										main_item = document.getElementById(itm?.product?.uid)?.parentElement
									}
								}
							}else{
								if (document.getElementById(itm?.product?.uid)) {
									if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
										if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										} else {
											main_item = document.getElementById(itm?.product?.id)
										}
									} else {
										main_item = document.getElementById(itm?.product?.uid)?.parentElement
									}
								}
							}

							layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
							layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
							title_bar = main_item?.parentElement?.childNodes[1]
							title_bar?.classList.remove("test")
						}
						if(layout_product_qnt?.innerHTML){
						layout_product_qnt.innerHTML = ''
				          }
						if (layout_count_product?.style?.visibility) {
						layout_count_product.style.visibility = 'hidden'
						}
						if(delete_icon){
						delete_icon.src = "../../../assets/imgs/plus.png"
						}
						return false
					} else if (this.serializedIds.includes(itm?.product?.id) && !this.isCoupon) {
						let main_item
						let title_bar
						let count_icon_plus: HTMLElement
						let count_icon_minus: HTMLElement
						let delete_icon
						let layout_product_qnt
						let layout_count_product: HTMLElement
						const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
						if (view == "listView" && window.innerWidth <= 768) {
							if(itm?.product?.isBundleProduct){
								main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement?.parentElement;
							}else{
								main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement;
							}
							delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
							layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
							count_icon_plus = main_item?.getElementsByClassName("count-products")[0]?.firstChild as HTMLElement;
							count_icon_minus = main_item?.getElementsByClassName("count-products")[0]?.lastChild as HTMLElement;
						} else {
							if(itm?.product?.isBundleProduct){
								if (document.getElementById(itm?.product?.id)) {
									if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
										if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										} else {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										}
									} else {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									}
								}
							}else{
								if (document.getElementById(itm?.product?.id)) {
									if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
										if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
											main_item = document.getElementById(itm?.product?.id)?.parentElement
										} else {
											main_item = document.getElementById(itm?.product?.id)
										}
									} else {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									}
								}
							}
							layout_product_qnt = main_item.parentElement.getElementsByClassName("layout_product_qnt")[0]
							layout_count_product = main_item.parentElement.getElementsByClassName("layout_count_product")[0] as HTMLElement;
							delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
							title_bar = main_item?.parentElement?.childNodes[1]
							title_bar?.classList.remove("test")
						}
					if(layout_product_qnt?.innerHTML){
						layout_product_qnt.innerHTML = ''
					}
					if (layout_count_product?.style?.visibility) {
						layout_count_product.style.visibility = 'hidden'
					}						
	              if(delete_icon){
						delete_icon.src = "../../../assets/imgs/plus.png"
				        }					
						return false
					}
				}
			})
		}
		if (type === 'all') {
			this.cartItem.forEach(itm => {
				let main_item
				let title_bar
				let count_icon_plus: HTMLElement
				let count_icon_minus: HTMLElement
				let delete_icon
				let layout_product_qnt
				let layout_count_product: HTMLElement
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view == "listView" && window.innerWidth <= 768) {
					if(itm?.product?.isBundleProduct){
						if (itm?.product?.id) {
							main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement?.parentElement;
						} else {
							main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement?.parentElement;
						}
					}else{
						if (itm?.product?.id) {
							main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement;
						} else {
							main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement;
						}
					}
					delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
					layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
					count_icon_plus = main_item?.getElementsByClassName("count-products")[0]?.firstChild as HTMLElement;
					count_icon_minus = main_item?.getElementsByClassName("count-products")[0]?.lastChild as HTMLElement;
				} else {
					if(itm?.product?.isBundleProduct){
						if (itm?.product?.id) {
							if (document.getElementById(itm?.product?.id)) {
								if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
									if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									}
								} else {
									main_item = document.getElementById(itm?.product?.id)?.parentElement
								}
							}
						} else {
							if (document.getElementById(itm?.product?.uid)) {
								if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
									if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									}
								} else {
									main_item = document.getElementById(itm?.product?.uid)?.parentElement
								}
							}
						}
					}else{
						if (itm?.product?.id) {
							if (document.getElementById(itm?.product?.id)) {
								if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
									if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(itm?.product?.id)
									}
								} else {
									main_item = document.getElementById(itm?.product?.id)?.parentElement
								}
							}
						} else {
							if (document.getElementById(itm?.product?.uid)) {
								if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
									if (itm?.product?.isVideo == 'youtube' || itm?.product?.isVideo == 'facebook') {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(itm?.product?.id)
									}
								} else {
									main_item = document.getElementById(itm?.product?.uid)?.parentElement
								}
							}
						}
					}
					layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
					layout_count_product = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
				}
				if (!itm.static && this.serializedIds.includes(itm?.product?.uid)) {
					let title_bar: any = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
				if(layout_product_qnt?.innerHTML){
					layout_product_qnt.innerHTML = ''
				  }
				if (layout_count_product?.style?.visibility) {
					layout_count_product.style.visibility = 'hidden'
				}
					this.cart_ids.splice(this.cart_ids.indexOf(itm?.product?.uid), 1)
                if(delete_icon){
					delete_icon.src = "../../../assets/imgs/plus.png"
			    }					
				if(layout_product_qnt?.innerHTML){
					layout_product_qnt.innerHTML = ''
				  }
				} else if (!itm.static && this.serializedIds.includes(itm?.product?.id)) {
					let title_bar: any = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
				if(layout_product_qnt?.innerHTML){
					layout_product_qnt.innerHTML = ''
				  }
				if (layout_count_product?.style?.visibility) {
					layout_count_product.style.visibility = 'hidden'
				}
					this.cart_ids.splice(this.cart_ids.indexOf(itm?.product?.id), 1)
	              if(delete_icon){
					delete_icon.src = "../../../assets/imgs/plus.png"
			     	}					
				  if(layout_product_qnt?.innerHTML){
					layout_product_qnt.innerHTML = ''
				  	  }
				}

			})
			this.past_item?.forEach(itm => {
				let main_item
				let title_bar
				let count_icon_plus: HTMLElement
				let count_icon_minus: HTMLElement
				let delete_icon
				let layout_product_qnt
				let layout_count_product: HTMLElement
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view == "listView" && window.innerWidth <= 768) {
					if (itm?.product?.isBundleProduct) {
						if (itm?.product?.id) {
							main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement?.parentElement;
						} else {
							main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement?.parentElement;
						}
					}else{
						if (itm?.product?.id) {
							main_item = document.getElementById(itm?.product?.id)?.parentElement?.parentElement;
						} else {
							main_item = document.getElementById(itm?.product?.uid)?.parentElement?.parentElement;
						}
					}
					delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
					layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
					count_icon_plus = main_item?.getElementsByClassName("count-products")[0]?.firstChild as HTMLElement;
					count_icon_minus = main_item?.getElementsByClassName("count-products")[0]?.lastChild as HTMLElement;
				} else {
					if (itm?.product?.isBundleProduct) {
						if (itm?.product?.id) {
							if (document.getElementById(itm?.product?.id)) {
								if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
									if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
										main_item = document.getElementById(i?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(i?.product?.id)?.parentElement
									}
								} else {
									main_item = document.getElementById(itm?.product?.uid)?.parentElement
								}
							}
						} else {
							if (document.getElementById(itm?.product?.uid)) {
								if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
									if (itm?.product?.isVideo == 'youtube' || itm?.product.isVideo == 'facebook') {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									}
								} else {
									main_item = document.getElementById(itm?.product?.uid)?.parentElement
								}
							}
						}
					}else{
						if (itm?.product?.id) {
							if (document.getElementById(itm?.product?.id)) {
								if (document.getElementById(itm?.product?.id).tagName != 'VIDEO') {
									if (i?.product?.isVideo == 'youtube' || i?.product?.isVideo == 'facebook') {
										main_item = document.getElementById(i?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(i?.product?.id)
									}
								} else {
									main_item = document.getElementById(itm?.product?.uid)?.parentElement
								}
							}
						} else {
							if (document.getElementById(itm?.product?.uid)) {
								if (document.getElementById(itm?.product?.uid).tagName != 'VIDEO') {
									if (itm?.product?.isVideo == 'youtube' || itm?.product.isVideo == 'facebook') {
										main_item = document.getElementById(itm?.product?.id)?.parentElement
									} else {
										main_item = document.getElementById(itm?.product?.id)
									}
								} else {
									main_item = document.getElementById(itm?.product?.uid)?.parentElement
								}
							}
						}
					}
					layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
					layout_count_product = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					delete_icon = main_item?.parentElement?.getElementsByClassName('deleteicon')[0]
				}
				if (!itm?.static && this.serializedIds.includes(itm?.product?.uid)) {
					let title_bar: any = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
					if (layout_product_qnt?.innerHTML) {
						layout_product_qnt.innerHTML = ''
					}
					if (layout_count_product) {
						layout_count_product.style.visibility = 'hidden'
					}
					this.cart_ids.splice(this.cart_ids.indexOf(itm?.product?.uid), 1)
					if (delete_icon) {
						delete_icon.src = "../../../assets/imgs/plus.png"
					}
				} else if (!itm?.static && this.serializedIds.includes(itm?.product?.id)) {
					let title_bar: any = main_item?.parentElement?.childNodes[1]
					title_bar?.classList?.remove("test")
					if (layout_product_qnt?.innerHTML) {
						layout_product_qnt.innerHTML = ''
					}
					if (layout_count_product) {
						layout_count_product.style.visibility = 'hidden'
					}
					this.cart_ids.splice(this.cart_ids.indexOf(itm?.product?.id), 1)
					if (delete_icon) {
						delete_icon.src = "../../../assets/imgs/plus.png"
					}
				}

			})
			this.cartItem = []
			this.past_item = []
		}
		this.current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem, past_items: this.past_item ? this.past_item : [] }, { merge: true });
		await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
			this.current_list_data = data.map(e => {
				return {
					uid: e.payload.doc.id,
					items: e.payload.doc.data().items ? e.payload.doc.data().items : [],
					past_items: e.payload.doc.data().past_items ? e.payload.doc.data().past_items : []
				};
			});
			this.current_list_data = this.current_list_data[0]
			this.cartItem = this.current_list_data.items
			this.past_item = this.current_list_data.past_items;
			this.cart_ids = []
			this.current_list_data.items.forEach(i => {
				if (i?.product?.id) {
					this.cart_ids.push(i?.product?.id)
				} else {
					this.cart_ids.push(i?.product?.uid)
				}
			});
		});

	}
	allSelection() {
		this.onlyCoupon = false
		$('.all-selection-btn a').addClass('current');
		$('.coupons-only-selection-btn a').removeClass('current');
		$('.recipes-only-selection-btn a').removeClass('current');
		$('.items-list').removeClass('d-none');
		$('.recipe-list').removeClass('d-none');
		$('.coupen-list').removeClass('d-none');
		$('.product-list').addClass('d-block');
	}
	couponSelection() {
		this.onlyCoupon = true
		$('.all-selection-btn a').removeClass('current');
		$('.coupons-only-selection-btn a').addClass('current');
		$('.recipes-only-selection-btn a').removeClass('current');
		$('.items-list').removeClass('d-none');
		$('.recipe-list').removeClass('d-block');
		$('.recipe-list').addClass('d-none');
		$('.coupen-list').removeClass('d-none');
		$('.coupen-list').addClass('d-block');
	}
	recipeSelection() {
		this.isCoupon = false
		$('.all-selection-btn a').removeClass('current');
		$('.coupons-only-selection-btn a').removeClass('current');
		$('.recipes-only-selection-btn a').addClass('current');
		$('.items-list').removeClass('d-none');
		$('.coupen-list').removeClass('d-block');
		$('.coupen-list').addClass('d-none');
		$('.recipe-list').removeClass('d-none');
		$('.recipe-list').addClass('d-block');
		$('.product-list').removeClass('d-block');
		$('.product-list').addClass('d-none');
	}
	bottomSliderDiv() {
		this.analytics.logEvent('@shoppingList_shareClick_count', {
			message: 'ShoppingList Share Button Clicked.'
		})
		$('.slider').toggleClass('close');
	}
	async ShowShoppingList() {
		if ($('.notification-container').hasClass('selected')) {
			$('.notification-container').removeClass('selected').addClass('dismiss');
			$("body").removeClass("OverlayBody");
			$(".sticky-nav").css("z-index", "1030");
		}
		$("#ShopDropdown").animate({
			right: "0"
		});
		$('.chervon-down-icon').addClass('d-none');
		$('.chervon-up-icon').removeClass('d-none');

		this.appTourService.cancelTour();

	}
	CloseShoppingList() {
		$("#ShopDropdown").animate({
			right: "-100%"
		});
		$('.chervon-up-icon').addClass('d-none');
		$('.chervon-down-icon').removeClass('d-none');
	}

	openHomePage() {
		const viewMode = localStorage.getItem('viewMode');
		if (viewMode !== 'null' && viewMode !== null) {
			if (this.is_list_view) {
				this.router.navigate(['/' + this.store_name_slug + '/list/' + this.current_list_id]);
			} else if (this.urlParam.has('preview') && this.urlParam.get('preview') === 'true' && viewMode !== 'undefined') {
				if (this.urlParam.has('type') && this.urlParam.get('type') === 'noad') {
					this.router.navigate(['/' + this.store_name_slug + '/' + viewMode], { queryParams: { preview: 'true', type: 'noad' } });
				} else {
					this.router.navigate(['/' + this.store_name_slug + '/' + viewMode], { queryParams: { preview: 'true' } });
				}
			} else {
				this.router.navigate(['/' + this.store_name_slug]);
			}
		} else {
			this.router.navigate(['/' + this.store_name_slug]);
		}
		this.removeFilter.emit();
	}
	openCircularPage() {
		const currentUrl = this.router.url;
		const viewMode = localStorage.getItem('viewMode');
		if (viewMode !== 'null' && viewMode !== null) {
			if (this.is_list_view) {
				this.router.navigate(['/' + this.store_name_slug + '/list/' + this.current_list_id]);
			} else {
				if(currentUrl.includes('/edit')){
					this.router.navigate(['/' + this.store_name_slug + '/edit']);
				}else{
					this.router.navigate(['/' + this.store_name_slug]);
				}
			}
		} else {
			this.router.navigate(['/' + this.store_name_slug]);
		}
	}
	openBundlePage() {
		const currentUrl = this.router.url;
		const viewMode = localStorage.getItem('viewMode');
		if (viewMode !== 'null' && viewMode !== null) {
			if (this.is_list_view) {
				this.router.navigate(['/' + this.store_name_slug + '/bundles' +'/list/' + this.current_list_id]);
			} else {
				if(currentUrl.includes('/edit')){
					this.router.navigate(['/' + this.store_name_slug + '/bundles'+ '/edit']);
				}else{
				this.router.navigate(['/' + this.store_name_slug + '/bundles']);
				}
			}
		} else {
			if(currentUrl.includes('/edit')){
				this.router.navigate(['/' + this.store_name_slug + '/bundles' + '/edit']);
			}else{
			this.router.navigate(['/' + this.store_name_slug + '/bundles']);
			}		}
	}
	openMenuPage() {
		const currentUrl = this.router.url;
		const viewMode = localStorage.getItem('viewMode');
		if (viewMode !== 'null' && viewMode !== null) {
			if (this.is_list_view) {
				this.router.navigate(['/' + this.store_name_slug + '/menu' +'/list/' + this.current_list_id]);
			} else {
				if(currentUrl.includes('/edit')){
					this.router.navigate(['/' + this.store_name_slug + '/menu' + '/edit']);
				}else{
				this.router.navigate(['/' + this.store_name_slug + '/menu']);
				}
			}
		} else {
			if(currentUrl.includes('/edit')){
				this.router.navigate(['/' + this.store_name_slug + '/menu' + '/edit']);
			}else{
			this.router.navigate(['/' + this.store_name_slug + '/menu']);
			}		}
	}
	openCouponsPage() {
		if (this.is_list_view) {
			this.router.navigate(['/' + this.store_name_slug + '/coupons' + '/list/' + this.current_list_id]);
		} else {
			this.router.navigate(['/' + this.store_name_slug + '/coupons']);
		}
	}

	openRecipesPage() {
		if (this.is_list_view) {
			this.router.navigate(['/' + this.store_name_slug + '/recipes' + '/list/' + this.current_list_id]);
		} else {
			this.router.navigate(['/' + this.store_name_slug + '/recipes']);
		}
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes['serializedData'] && changes['serializedData'].currentValue && changes['serializedBundleData'] && changes['serializedBundleData'].currentValue && changes['serializedMenuData'] && changes['serializedMenuData'].currentValue) {
			if(this.serializedData.length > 0 || this.serializedBundleData.length > 0 ||this.serializedMenuData.length > 0){
			console.log("changes")	
			
			if (this.current_list_id !== '') {
				console.log(this.serializedBundleData,this.serializedData,"this.serializedBundleData");
				this.combinedData=[]
				if(this.serializedData?.length>0){
					this.combinedData.push(...this.serializedData);
				   }
				   if(this.serializedBundleData?.length>0){
					this.combinedData.push(...this.serializedBundleData);
				   }
				   if(this.serializedMenuData?.length>0){
					this.combinedData.push(...this.serializedMenuData);
				   }				 
					 this.qr_value = window.location.href + '/list/' + this.current_list_id;
		 
					 try {
						 const currentListSnapshot = await this.afs.collection('/list').doc(this.current_list_id).get().toPromise();
						 const currentListData: any = currentListSnapshot.data();
		 
						 this.cartItem = currentListData?.items;
						 let finalCurrentListData;
						 if (currentListData?.past_items) {
							 finalCurrentListData = [...currentListData?.items, ...currentListData?.past_items];
						 } else {
							 finalCurrentListData = currentListData?.items;
						 }
		 
						 const commonItems = [];
						 for (const storeItem of this.combinedData) {
							 const storeItemUid = storeItem.id;
							 if (storeItem?.id) {
								 const isCommon = finalCurrentListData.some(cartItem => {
									 return cartItem.product.id == storeItem.id || cartItem.product.uid == storeItem.id;
								 });
								 if (isCommon) {
									 commonItems.push(storeItem);
								 }
							 }
						 }
						 for (const storeItem of this.store_item) {
							 const storeItemUid = storeItem.id;
							 if (storeItem?.uid) {
								 const isCommon = finalCurrentListData.some(cartItem => {
									 return cartItem.product.uid == storeItem.uid;
								 });
								 if (isCommon) {
									 commonItems.push(storeItem);
								 }
							 }
						 }
		 
						 this.past_item = [];
						 this.cartItem = [];
		 
						 for (const currentCartItem of finalCurrentListData) {
							 let storeItem
							 if (currentCartItem.product.id) {
								 storeItem = commonItems.find(item => item.id == currentCartItem.product.id);
							 } else {
								 storeItem = commonItems.find(item => item.uid == currentCartItem.product.uid);
							 }
							 console.log(storeItem,currentCartItem,"storeItem")
									 console.log(currentCartItem?.product?.price_label_value1 !== storeItem?.price_label_value1,currentCartItem?.product?.price_label_value1 ,storeItem?.price_label_value1,"currentCartItem?.product?.price_label_value1 !== storeItem?.price_label_value1")
									 console.log(currentCartItem?.product?.price_label_value2 !== storeItem?.price_label_value2,currentCartItem?.product?.price_label_value2 ,storeItem?.price_label_value2,"currentCartItem?.product?.price_label_value2 !== storeItem?.price_label_value2")
									 console.log(currentCartItem?.product?.price_label_value3 !== storeItem?.price_label_value3,currentCartItem?.product?.price_label_value3 ,storeItem?.price_label_value3,"currentCartItem?.product?.price_label_value3 !== storeItem?.price_label_value3")
									 console.log(currentCartItem?.product?.sale_price_label_value1 !== storeItem?.sale_price_label_value1,currentCartItem?.product?.sale_price_label_value1 ,storeItem?.sale_price_label_value1,"currentCartItem?.product?.sale_price_label_value1 !== storeItem?.sale_price_label_value1")
									 console.log(currentCartItem?.product?.sale_price_label_value2 !== storeItem?.sale_price_label_value2,currentCartItem?.product?.sale_price_label_value2 ,storeItem?.sale_price_label_value2,"currentCartItem?.product?.sale_price_label_value2 !== storeItem?.sale_price_label_value2")
									 console.log(currentCartItem?.product?.sale_price_label_value3 !== storeItem?.sale_price_label_value3,currentCartItem?.product?.sale_price_label_value3 ,storeItem?.sale_price_label_value3,"currentCartItem?.product?.sale_price_label_value3 !== storeItem?.sale_price_label_value3")
		 
							 if (storeItem && (
								 currentCartItem?.product?.price_label_value1 !== storeItem?.price_label_value1 ||
								 currentCartItem?.product?.price_label_value2 !== storeItem?.price_label_value2 ||
								 currentCartItem?.product?.price_label_value3 !== storeItem?.price_label_value3 ||
								 currentCartItem?.product?.sale_price_label_value1 !== storeItem?.sale_price_label_value1 ||
								 currentCartItem?.product?.sale_price_label_value2 !== storeItem?.sale_price_label_value2 ||
								 currentCartItem?.product?.sale_price_label_value3 !== storeItem?.sale_price_label_value3)
		 
							 ) {
								 this.past_item.push(currentCartItem);
							 } else {
								 this.cartItem.push(currentCartItem);
							 }
						 }
						 console.log(this.cartItem,this.past_item,"checkheder")
		 
						 await this.afs.collection('/list').doc(this.current_list_id).update({
							 items: this.cartItem,
							 past_items: this.past_item
						 });
					 } catch (error) {
						 console.error("Error:", error);
					 }
					 if (this.current_list_id !== '') {
						 this.afs.collection('/coupons', ref => ref.where('store_uid', '==', this.current_store_uid).where('is_publish', '==', true).orderBy('created_at', 'desc')).snapshotChanges().subscribe(async data => {
							 this.coupons_list = data.map(e => {
								 const validTillTimestamp = e.payload.doc.data()['valid_till'].seconds * 1000;
								 const validTillDate = new Date(validTillTimestamp);
								 validTillDate.setHours(0, 0, 0, 0);
								 const currentDate = new Date();
								 currentDate.setHours(0, 0, 0, 0);
								 if (validTillDate >= currentDate) {
									 return {
										 uid: e.payload.doc.id,
										 coupon_image: e.payload.doc.data()['coupon_image'] ? e.payload.doc.data()['coupon_image'] : "https://via.placeholder.com/300x200/2e2e2e/2e2e2e",
										 upc: e.payload.doc.data()['upc'],
										 coupon_description: e.payload.doc.data()['coupon_description'],
										 label: e.payload.doc.data()['label'],
										 coupon_code: e.payload.doc.data()['coupon_code'],
										 valid_from: e.payload.doc.data()['valid_from'],
										 valid_till: e.payload.doc.data()['valid_till'],
									 };
								 } else {
									 return {
										 uid: e.payload.doc.id,
										 valid_till: 'expired'
									 }
								 }
							 });
							 console.log(this.combinedData,"combinedData")
						 this.serializedIds = this.combinedData?.map(s => s.id)
		 
						 const currentListSnapshot = await this.afs.collection('/list').doc(this.current_list_id).get().toPromise();
						 const currentListData: any = currentListSnapshot.data();
						 console.log(currentListSnapshot.data(),"currentListSnapshot.data()")
		 
						 if (currentListData?.items) {
							 console.log(currentListData,"currentListData")
							 const updatedCartItems = [];
							 const updatedPastItems = [];
							 const CouponItems = currentListData.items.filter((a) => a.isCoupon == true)
							 const RecipeItems = currentListData.items.filter((a) => a.isRecipe == true)
							 const filterCartItems = currentListData.items.filter((a) => a.isRecipe != true && a.isCoupon != true);
							 const filterPastItems = currentListData.past_items.filter((a) => a.isRecipe != true && a.isCoupon != true);
							 updatedCartItems.push(...RecipeItems)
							 CouponItems.forEach(currentCartItem => {
								 if (currentCartItem.isCoupon) {
									 this.coupons_list?.forEach(item => {
										 if (item?.uid == currentCartItem?.product?.uid) {
											const validTillDate = new Date(currentCartItem.product.valid_till);
											validTillDate.setHours(0, 0, 0, 0);
											const currentDate = new Date();
											currentDate.setHours(0, 0, 0, 0);
											 if (item.valid_till == 'expired' || validTillDate < currentDate) {
												 currentCartItem.product = {
													 ...currentCartItem.product,
													 isExpired: 'Yes'
												 };
												 updatedCartItems.push(currentCartItem)
											 } else {
												 currentCartItem.product = {
													 ...currentCartItem.product,
													 isExpired: 'No'
												 };
												 updatedCartItems.push(currentCartItem)
											 }
										 }
									 })
		 
								 }
							 })
							 for (const currentCartItem of filterCartItems) {
								 if (currentCartItem.product.id) {
									 console.log(currentCartItem.product.id,"currentCartItem.product.id",this.serializedIds,"id")
									 if (!this.serializedIds.includes(currentCartItem.product.id) && !currentCartItem.static) {
										 updatedPastItems.push(currentCartItem);
									 } else {
										 updatedCartItems.push(currentCartItem);
									 }
								 } else {
									 if (!this.serializedIds.includes(currentCartItem.product.uid) && !currentCartItem.static) {
										 updatedPastItems.push(currentCartItem);
									 } else {
										 updatedCartItems.push(currentCartItem);
									 }
								 }
							 }
							 for (const currentPastItem of filterPastItems) {
								 updatedPastItems.push(currentPastItem);
							 }
							 console.log(updatedCartItems,updatedPastItems,"cjclheadet")
							 this.past_item =updatedPastItems;
							 this.cartItem =updatedCartItems
		                     console.log(this.past_item,this.cartItem,"cart")
							 await this.afs.collection('/list').doc(this.current_list_id).update({
								 items: updatedCartItems,
								 past_items: updatedPastItems
							 });
						 }
					 });
					 }
					//  await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
					// 	 console.log(this.serializedBundleData,this.serializedData,this.serializedMenuData,"data")
					// 	 this.current_list_data = data.map(e => {
					// 		 return {
					// 			 uid: e.payload.doc.id,
					// 			 items: e.payload.doc.data().items ? e.payload.doc.data().items : [],
					// 			 past_items: e.payload.doc.data().past_items ? e.payload.doc.data().past_items : []
					// 		 };
					// 	 });
					// 	 this.current_list_data = this.current_list_data[0]
					// 	 this.cartItem = this.current_list_data?.items
					// 	 this.past_item = this.current_list_data?.past_items;
					// 	 this.cart_ids = []
					// 	 this.current_list_data?.items.forEach(i => {
					// 		 if (i?.product?.id) {
					// 			 this.cart_ids.push(i?.product?.id)
					// 		 } else {
					// 			 this.cart_ids.push(i?.product?.uid)
					// 		 }
					// 	 });
					//  });
				 }  }
				}
	  
	}
	ngOnDestroy(): void {
		this.subscription.unsubscribe()
	}
}

@Component({
	selector: 'dialog-animations-example-dialog',
	templateUrl: 'dialog-animations-example-dialog.html',
})
export class DialogAnimationsExampleDialog {
	constructor(public dialogRef: MatDialogRef<DialogAnimationsExampleDialog>) { }
}
@Component({
	selector: 'dialog-animations-example-dialog',
	templateUrl: 'dialog-animations-example-dialog-android.html',
})
export class DialogAnimationsExampleDialogAndroid {
	constructor(public dialogRef: MatDialogRef<DialogAnimationsExampleDialogAndroid>) { }
}