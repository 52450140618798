import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import {finalize,catchError,tap} from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
	selector: 'app-product-images',
	templateUrl: './product-images.component.html',
	styleUrls: ['./product-images.component.scss']
})
export class ProductImagesComponent implements OnInit {


datasource = [];
tableColumns  :  string[] = ['checkbox','id', 'upc', 'image','product_desc','category','price','action'];
title = 'appBootstrap';
saved_items_list:any;
user:any;
current_store_uid:any;
importcsvdata:any = [];
saved_items_list_temp:any;
duplicates_temp:any;
closeResult:any;
current_selected_product:any;
imageurl1:any = '';
imageurl2:any = '';
ref:any;
task:any;
imageSrc:any;
imageurl:any;
show_add_product:boolean = false;
checkbox_delete_array:any = [];
checkbox_select_all:any;
categories_list:any;

product_edit_form: FormGroup;
product_add_form: FormGroup;
image_uploading:boolean = false;
show_icon_results:any;
icons:any;
current_unsplash_type:any;
product_store_images:any = [];
search_icon_form:FormGroup;
@ViewChild('iconSearchModal') iconSearchModal;
@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
@ViewChild('uploadExistModal') uploadExistModal;
@ViewChild('deleteModal') deleteModal;
@ViewChild('storeImageSearchModal') storeImageSearchModal;


	constructor( @Inject(PLATFORM_ID) 
	private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,
	public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private afStorage : AngularFireStorage,
	private http: HttpClient, 
	private modalService: NgbModal,
	private storage: AngularFireStorage,
	private _snackBar: MatSnackBar
	) {

	this.product_edit_form = new FormGroup({
		uid           : new FormControl(""),
		product_image       : new FormControl(""),  
		upc_code        : new FormControl(""), 
		product_description   : new FormControl(""),  
		pack          : new FormControl(""),
		product_url       : new FormControl(""),
		category        : new FormControl(""),
		price_label_format    : new FormControl(""),
		hide_price_label    : new FormControl(false),
		hide_addtolist_label  : new FormControl(false),
		hide_productname_label  : new FormControl(false),
	});

	this.product_add_form = new FormGroup({
		uid           : new FormControl(""),
		product_image       : new FormControl(""),  
		upc_code        : new FormControl(""), 
		product_description   : new FormControl(""),  
		pack          : new FormControl(""),
		product_url       : new FormControl(""),
		category        : new FormControl(""),
		price_label_format    : new FormControl(""),
		hide_price_label    : new FormControl(false),
		hide_addtolist_label  : new FormControl(false),
		hide_productname_label  : new FormControl(false),
	});

	this.search_icon_form = new FormGroup({
		query: new FormControl(null, Validators.compose([Validators.required])),
	});

	}


	downloadFile(data: any) {
		const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
		const header = Object.keys(data[0]);
		let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
		csv.unshift(header.join(','));
		let csvArray = csv.join('\r\n');

		var blob = new Blob([csvArray], {type: 'text/csv' })
		saveAs(blob, "saved-items-"+new Date().getTime()+".csv");
	}

	openIconModal(type)
	{
	this.current_unsplash_type = type;
	this.open(this.iconSearchModal,'modal-basic-title5');
	//https://api.iconify.design/search?query=dashboard&limit=120
	//https://api.iconify.design/akar-icons/home.svg?color=%2371B01D&height=56
	}

	openStoreImgModal(type)
	{
		this.current_unsplash_type = type;
		this.open(this.storeImageSearchModal,'modal-basic-title6');
	}


	submit_search_icon(value)
	{
	console.log(value);
	this.http
			.get('https://api.unsplash.com/search/photos?orientation=landscape&per_page=12&page=1&query='+value.query+'&client_id=qfLb-ZkcyZtshc0JsgKhGAsHo-37J8DBQYLO_Hjerp4')
			.subscribe(res => { console.log(res); this.icons  = res['results'];console.log('thisicons',this.icons); this.show_icon_results = true; });
	}



	export_csv()
	{
	this.downloadFile(this.saved_items_list_temp);
	this._snackBar.open("File downloaded successfully!",'',{
		duration: 1500,
	});
	}

set_current_image(value)
{
//setting image from unsplash;
console.log("VALUE IS ==>> ",value);
console.log(this.current_unsplash_type);
if(this.current_unsplash_type == 'edit')
{
//setting unsplash image when editing a product
this.imageurl1 = value; 
this.product_edit_form.patchValue({
product_image   : value,
});
} 
if(this.current_unsplash_type == 'add')
{
//setting unsplash image wheen adding a product
this.imageurl2 = value; 
this.product_add_form.patchValue({
product_image   :  value,
});
} 
let element: HTMLElement = document.getElementsByClassName('close_unsplash_modal')[0] as HTMLElement;
element.click();
}


set_current_image_store(value)
{
	//setting image from other stores;
	console.log("VALUE IS ==>> ",value);
	console.log(this.current_unsplash_type);
	if(this.current_unsplash_type == 'store_image_edit')
	{
		//setting unsplash image when editing a product
		this.imageurl1 = value; 
		this.product_edit_form.patchValue({
		product_image   : value,
		});
	} 
	if(this.current_unsplash_type == 'store_image_add')
	{
		//setting unsplash image wheen adding a product
		this.imageurl2 = value; 
		this.product_add_form.patchValue({
		product_image   :  value,
		});
	} 

	let element: HTMLElement = document.getElementsByClassName('close_store_image_modal')[0] as HTMLElement;
	element.click();
}

	ngOnInit() {
	this.user = JSON.parse(localStorage.getItem('userrole'));
	this.current_store_uid = this.user.store_uid;
	this.afs.collection('/master-items',ref => ref.where('product_image','==','').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		let i = 0;
		this.saved_items_list_temp = data.map(e => {
		i++;
		return{
			
			upc_code                : e.payload.doc.data()['upc_code'],
			product_description     : e.payload.doc.data()['product_description'],
			pack                    : e.payload.doc.data()['pack'],
			price_label_format      : e.payload.doc.data()['price_label_format'],
			price_label_value1      : e.payload.doc.data()['price_label_value1'],
			price_label_value2      : e.payload.doc.data()['price_label_value2'],
			price_label_value3      : e.payload.doc.data()['price_label_value3'],
			price_label_highlight   : e.payload.doc.data()['price_label_highlight'],
			product_url             : e.payload.doc.data()['product_url'],
			hide_price_label        : e.payload.doc.data()['hide_price_label'],
			hide_addtolist_label    : e.payload.doc.data()['hide_addtolist_label'],
			hide_productname_label  : e.payload.doc.data()['hide_productname_label'],
			cost                    : e.payload.doc.data()['cost'],
			srp                     : e.payload.doc.data()['srp'],
			category                : e.payload.doc.data()['category'],
			uid                     : e.payload.doc.id,
			product_image           : e.payload.doc.data()['product_image']?e.payload.doc.data()['product_image']:"https://via.placeholder.com/30/cecece/cecece",
			price_label_svg         : e.payload.doc.data()['price_label_svg'],
			serial                  : i-1,
			is_saved                : e.payload.doc.data()['is_saved']?e.payload.doc.data()['is_saved']:false
			//created_at            : e.payload.doc.data()['created_at'],
		};
		}); 
		console.log('this.saved_items_list',this.saved_items_list_temp);
		this.saved_items_list = this.saved_items_list_temp;
		this.saved_items_list = new MatTableDataSource(this.saved_items_list);
		this.saved_items_list.sort = this.leadsSort;
		this.saved_items_list.paginator = this.leadsPaginator;
		});


		this.afs.collection('/lookup-categories').snapshotChanges().subscribe(data => {
		this.categories_list = data.map(e => {
		return{
			uid           : e.payload.doc.id,
			value           : e.payload.doc.data()['value'],
		};
		}); 
		console.log("Categories list", this.categories_list);
	});
	}

	goToAddNewStore(){
	this.router.navigate(['./add-new-store']);
	}

	doFilter = (value: string) => {
		this.saved_items_list.filter = value.trim().toLocaleLowerCase();
	}

	view_product(data)
	{
	this.show_add_product = false;
	this.current_selected_product = data;
	this.imageurl1 = data.product_image+ "&size=408&thumbnail=true&palette=RGB";
	this.product_edit_form.patchValue({
		uid         : data.uid,
		product_image   : data.product_image,
		upc_code      : data.upc_code,
		product_description : data.product_description,
		pack        : data.pack,
		price_label_format  : data.price_label_format,
		category      : data.category,
		product_url     : data.product_url,
		hide_price_label  : data.hide_price_label,
		hide_addtolist_label: data.hide_addtolist_label,
		hide_productname_label : data.hide_productname_label,

	});

	this.afs.collection('/store-items',ref => ref.where('product_image','!=','').where('upc_code','==',data.upc_code)).snapshotChanges().subscribe(data => {
		this.product_store_images = data.map(e => {
			return{
			upc_code                : e.payload.doc.data()['upc_code'],
			product_image     : e.payload.doc.data()['product_image'],
			};
		});
		console.log("THE PRODUCT STORE IMAGES FROM OTHER",this.product_store_images);
	});

	}
	
	import_csv(files: FileList){
	console.log(files);
	if(files && files.length > 0) {
		let file : File = files.item(0); 
		console.log(file.name);
		console.log(file.size);
		console.log(file.type);
		let reader: FileReader = new FileReader();
		let fileid = new Date().getTime();      //setting temporary name so that we can know what was the source of the upload
		reader.readAsText(file);
		reader.onload = (e) => {
		let filestring: string = reader.result as string;
		// console.log("DATA HERE IS ---",csv);
		// reader.result.forEach((value,key) => {
		//  console.log("VALUE is",value);
		// });
		const lines = filestring.split('\n');
		lines.forEach( (element,key) => {
			const cols: string[] = element.split(',');
			if(key > 0)
			{
			if(cols[0] != "")
			{
				let product_upload_data = {
				product_image       : "",
				upc_code          : cols[0],
				product_description   : cols[1]?cols[1]:"",
				pack          : cols[2]?cols[2]:"",
				price_label_format    : cols[3]?cols[3]:"",
				price_label_value1    : cols[4]?cols[4]:"",
				price_label_value2    : cols[5]?cols[5]:"",
				price_label_value3    : cols[6]?cols[6]:"",
				price_label_highlight : cols[7]?cols[7]:"",
				product_url       : cols[8]?cols[8]:"",
				hide_price_label    : cols[9]?cols[9]:"",
				hide_addtolist_label  : cols[10]?cols[10]:"",
				hide_productname_label  : cols[11]?cols[11]:"",
				cost          : cols[12]?cols[12]:"",
				srp           : cols[13]?cols[13]:"",
				category        : cols[14]?cols[14]:"",
				store_uid         : this.current_store_uid,
				system_notes      : "Uploaded using csv from saved-items",
				upload_file_id      : fileid
				};
				this.importcsvdata.push(product_upload_data);
			}

			}
			
			console.log("COLS----",cols);
		
			// let save_to_db = this.afs.collection('/saved-items').add(product_upload_data).then(docRef => {
			//  const itemRefx2= this.afs.doc('/saved-items/' + docRef.id);
			//  itemRefx2.update({ uid: docRef.id}); 
			// });
		});
		console.log("SAVED ITEMS LIST---",this.saved_items_list_temp);
		console.log("CSV UPLOADED DATA",this.importcsvdata);

		// var result = this.saved_items_list_temp.filter(function (o1) {
		//     return !this.importcsvdata.some(function (o2) {
		//         return o1.id === o2.id; // return the ones with equal id
		//    });
		// });
		// console.log(result);
		// // if you want to be more clever...
		let result = this.saved_items_list_temp.filter(o1 => this.importcsvdata.some(o2 => o1.upc_code === o2.upc_code));
		console.log("RESULTLTT",result);
		this.duplicates_temp = result;
		if(this.importcsvdata.length == 0)
		{
			this._snackBar.open("No products to import.",'',{
			duration: 1500,
			});
		} 
		if(result.length > 0)
		{
			// this._snackBar.open("Some products already exist in the system.",'',{
			//  duration: 1500,
			// });
			this.open(this.uploadExistModal,'modal-basic-title1'); 


		}
		if(result.length == 0)
		{
			console.log("RESULT LENGTH ---",result.length);
			this.importcsvdata.forEach((value,key)=>{
			value.created_at = new Date();
			let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
				const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
				itemRefx2.update({ uid: docRef.id}); 
				console.log('saved with uid----',docRef.id);
			});
			});
			this._snackBar.open("Items imported successfully!",'',{
			duration: 1500,
			});
			
		}
		}
	}
	}

	set_current_icon(data)
	{
	
	}


	upload(event,ind) {
	this.image_uploading = true;
	if (event.target.files && event.target.files[0]) {
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onload = e => 
		console.log("INDDDDD--",ind); let tempimg = reader.result;
		// this.imageurl1 = reader.result;
		// this.imageurl2 = reader.result;
	
		console.log("READER RESULT",reader.result);
		reader.readAsDataURL(file);
	
	}

	// create a random id
	const randomId = "digital-menus-"+ new Date().getTime() +  Math.random().toString(36).substring(2);
	// create a reference to the storage bucket location
	this.ref = this.afStorage.ref('uploads/'+randomId);
	// the put method creates an AngularFireUploadTask
	// and kicks off the upload
	this.task =  this.ref.put(event.target.files[0]);

	//this.imageurl = this.task.downloadURL();
	this.task.snapshotChanges().pipe(
		finalize(() => {
		this.ref.getDownloadURL().subscribe(url => {

			if(ind == '1')  
			{ 
			this.imageurl1 = url; 
			this.product_edit_form.patchValue({
				product_image   :  url,
			});
			}
 
			if(ind == '2')  
			{ 
			this.imageurl2 = url; 
			this.product_add_form.patchValue({
				product_image   :  url,
			});
			}
		
			this.image_uploading= false;
			console.log('THE IMAGE URL IIS>>>>',url); // <-- do what ever you want with the url..
		
		});
		})
	).subscribe(); 


	// this.imageurl = "https://firebasestorage.googleapis.com/v0/b/cyrme-417e6.appspot.com/o/uploads/"+ randomId+"?alt=media";
	console.log('THE IMAGE URL',this.imageurl);
	
	}

	openAddProduct()
	{
	 this.show_add_product = true;
	 this.current_selected_product = [];
	 this.product_add_form.reset();
	}

	trigger_import_csv()
	{
	let element: HTMLElement = document.getElementsByClassName('upload_file_import')[0] as HTMLElement;
	element.click();
	}

	trigger_upload_image()
	{
	let element: HTMLElement = document.getElementsByClassName('update_image_upload')[0] as HTMLElement;
	element.click();
	}

	trigger_upload_image_add()
	{
	let element: HTMLElement = document.getElementsByClassName('update_image_upload2')[0] as HTMLElement;
	element.click(); 
	}
	private getDismissReason(reason: any): string {
	if (reason === ModalDismissReasons.ESC) {
		return 'by pressing ESC';
	} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		return 'by clicking on a backdrop';
	} else {
		return  `with: ${reason}`;
	}
	}

	open(content,title) {
	this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
	this.closeResult = `Closed with: ${result}`;
	}, (reason) => {
	this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
	});
	}

	import_ignore_duplicate()
	{
	console.log('ignoreee the duplicate values');

	this.importcsvdata.forEach((value,key) => {
		let value2 = [];
		value2.push(value);
		let result2 = value2.filter(o1 => this.saved_items_list_temp.some(o2 => o1.upc_code === o2.upc_code));
		console.log(result2);
		if(result2.length == 0)
		{
		//only import unique values thats why storing the unique values here
		console.log('only storing unique- ',value);
		let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
			const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
			itemRefx2.update({ uid: docRef.id}); 
			console.log('only stored unique - ',docRef.id);
		});
		}
	});

	let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
	element.click();

	this._snackBar.open(this.importcsvdata.length + " Products imported successfully!",'',{
			duration: 2000,
			});

	}

	import_update_duplicate()
	{

	this.importcsvdata.forEach((value,key) => {
		
		let item = this.duplicates_temp.find(i => i.upc_code === value.upc_code);
		console.log("ITEMM",item);
		if(item)
		{
		console.log('update');

		//this product already exists in the system so update it.
		let update_to_db = this.afs.doc('/master-items/'+item.uid).set(value,{merge:true}).then(docRef => {
		});

		} else {
		console.log('new');
		value.created_at = new Date();
		//this product is new so create a new record.
		let save_to_db = this.afs.collection('/master-items').add(value).then(docRef => {
			const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
			itemRefx2.update({ uid: docRef.id}); 
		});

		}
	
	});

	let element: HTMLElement = document.getElementsByClassName('close_upload_exist_modal')[0] as HTMLElement;
	element.click();

	this._snackBar.open( this.importcsvdata.length +" Products imported successfully!",'',{
		duration: 2000,
	});
	}


	product_update(data)
	{
		console.log(data);
		if(data.product_image.includes("via.placeholder.com"))
		{
			data.product_image = "";
		}
		const itemRefx = this.afs.doc('/master-items/'+ data.uid).set(data,{merge:true}).then(docRef => {});
		this._snackBar.open("Product details updated successfully!",'',{
			duration: 1500,
		});
	}


	product_store(data)
	{
		if(data.upc_code == "" || data.upc_code == null)
		{
			this._snackBar.open("Error:UPC Code is Required",'',{
			duration: 1500,
			});
			return false;
		}
		if(data.product_description == "" || data.product_description == null)
		{
			this._snackBar.open("Error:Product description is Required",'',{
			duration: 1500,
			});
			return false;
		}
		let temp_store = [];
		temp_store.push(data);
		let result = this.saved_items_list_temp.filter(o1 => temp_store.some(o2 => o1.upc_code === o2.upc_code));
		if(result.length > 0)
		{
			this._snackBar.open("Error: This Product already exists in your saved items!",'',{
			duration: 1500,
			});
			return false;
		}

		data.price_label_value1 = "";
		data.price_label_value2 = "";
		data.price_label_value3 = "";
		data.price_label_highlight =  "";
		data.cost = "";
		data.srp  = "";

		data.created_at = new Date();
		data.system_notes = "Created manually using form";
		data.store_uid = this.current_store_uid;
		const itemRefx = this.afs.collection('/master-items').add(data).then(docRef => {
				const itemRefx2= this.afs.doc('/master-items/' + docRef.id);
				itemRefx2.update({ uid: docRef.id}); 
				this.product_add_form.reset();
			});
		this._snackBar.open("Product added successfully!",'',{
			duration: 1500,
		});


		console.log('in add product',data);

	}


	checkbox_select_all_event(event)
	{
	console.log(event.target.checked);
	let value = event.target.checked;
	let cnt = this.saved_items_list_temp.length;
	for(let i = 0;i<cnt;i++)
	{
		if(value == true)
		{
		this.checkbox_delete_array[i] = true;
		} else {
		this.checkbox_delete_array[i] = false;
		}
	}
	
	}
	checkbox_single_select_event(event,i)
	{
	if(event.target.checked == true)
	{
		this.checkbox_delete_array[i] = true;
	} else {
		this.checkbox_delete_array[i] = false;
	}
	console.log(event.target.checked);
	console.log('index',i);
	}

	openDeleteModal()
	{
	if(this.checkbox_delete_array.length  == 0)
	{
		this._snackBar.open("Error: Please select some products to delete!",'',{
		duration: 1500,
		});
		return false;
	}
	this.open(this.deleteModal,'modal-basic-title3'); 
	console.log("indexes to delete",this.checkbox_delete_array);
	}

	delete_products()
	{
	console.log("array--",this.checkbox_delete_array);
	let cnt = this.checkbox_delete_array.length;
	for(let d=0;d<cnt;d++)
	{
		let val = this.checkbox_delete_array[d];
		if(val == true)
		{
		this.checkbox_delete_array[d] = 0;
		console.log("INDEX DELETE----",d);
		console.log("UID",this.saved_items_list_temp[d].uid);
		const itemRefx = this.afs.doc('/master-items/'+ this.saved_items_list_temp[d].uid).delete();
			this.ngOnInit();

		}
	}
	let element: HTMLElement = document.getElementsByClassName('delete_product_modal')[0] as HTMLElement;
	element.click();
	}

	gotoAll()
	{
	this.router.navigate(['/store/all-items']);
	}
	gotoSaved()
	{
	this.router.navigate(['/store/saved-items']);
	}

	save_item(uid)
	{
	console.log("save this item",uid);
	let update_to_db = this.afs.doc('/master-items/'+ uid).set({is_saved: true},{merge:true}).then(docRef => {
		});
	this._snackBar.open("Item has been moved to saved items!",'',{
		duration: 1500,
	});
	}

	unsave_item(uid)
	{
		console.log("unsave this item ",uid);
		let update_to_db = this.afs.doc('/master-items/'+ uid).set({is_saved: false},{merge:true}).then(docRef => {
			});

		this._snackBar.open("Item removed from saved!",'',{
			duration: 1500,
		});
	}


}
