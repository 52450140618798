import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ComponentService {

  constructor(    
    public afs: AngularFirestore) { }
    async setTempLayout(data, uid) {
      await this.afs
        .doc('/layout-json-temp/' + uid)
        .set(
          {
            layout_json_temp: data
          }, 
          { merge: true }
        )
        .then(() => {
          console.log("updated");
        })
        .catch(error => {
          console.error("Error updating layout:", error);
        });
    }
    
  async setSavedLayout(data, uid) {
    await this.afs
      .doc('/layout-json-saved/' + uid)
      .set({
        layout_json_saved: data
      }, { merge: true }).then(() => {
        console.log("updated");
      })
      .catch(error => {
        console.error("Error updating layout:", error);
      });
  }

  async getTempLayout(uid) {
    const layoutRefTemp = this.afs.doc('/layout-json-temp/' + uid);
    const docTemp: any = await layoutRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
  async getSavedLayout(uid) {
    const layoutRefTemp = this.afs.doc('/layout-json-saved/' + uid);
    const docTemp: any = await layoutRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
 //for bundle
  async getBundleTempLayout(uid) {
    const bundleRefTemp = this.afs.doc('/bundle-json-temp/' + uid);
    const docTemp: any = await bundleRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
  async getBundleSavedLayout(uid) {
    const bundleRefTemp = this.afs.doc('/bundle-json-saved/' + uid);
    const docTemp: any = await bundleRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }

  async setBundleTempLayout(data, uid) {
    await this.afs
      .doc('/bundle-json-temp/' + uid)
      .set({
        bundle_json_temp: data
      }, { merge: true })
  }
  async setBundleSavedLayout(data, uid) {
    await this.afs
      .doc('/bundle-json-saved/' + uid)
      .set({
        bundle_json_saved: data
      }, { merge: true })
  }

  //for menu 
  async getMenuTempLayout(uid) {
    const menuRefTemp = this.afs.doc('/menu-json-temp/' + uid);
    const docTemp: any = await menuRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }
  async getMenuSavedLayout(uid) {
    const menuRefTemp = this.afs.doc('/menu-json-saved/' + uid);
    const docTemp: any = await menuRefTemp.get().toPromise();
    const currentDataTemp = await docTemp.data();
    return currentDataTemp
  }

  async setMenuTempLayout(data, uid) {
    await this.afs
      .doc('/menu-json-temp/' + uid)
      .set({
        menu_json_temp: data
      }, { merge: true })
  }
  async setMenuSavedLayout(data, uid) {
    await this.afs
      .doc('/menu-json-saved/' + uid)
      .set({
        menu_json_saved: data
      }, { merge: true })
  }
}
