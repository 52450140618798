<div class="pagecontent-wrapper">
	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
				<div class="leads-visualization visualizations-section">
					<div class="row no-gutters">
						<div class=" col-3">
							<div class="heading-info">
								<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Stores</h4>
							</div>
						</div>			
					</div>
				</div>
			<div class="row no-gutters">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="stores_list">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<ng-container matColumnDef="company_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Company Name </th>
							<td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
						</ng-container>
						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
								<button class="btn btn-sm  btnicon" style="border:1px solid #71B01D;margin-right: 5px;"  (click)="edit_store(element.company_name_slug)"><img src="assets/imgs/icon-pencil.svg" ></button>
							<button class="btn btn-sm btn-outline-success" (click)="edit_store(element.company_name_slug)">Edit Site</button>
							</td>
						</ng-container>  
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>
		</div>

	</div>
</div>
