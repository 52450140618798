<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/gridstack.js/6.0.0/gridstack-extra.min.css"
    integrity="sha512-xO0M5KAPcgkZEVPqvAQ2wakjrrsJAcrWROvDE4y1YaNuoB2+Q31kJAE48v520QxUgx/G9MmiOzw4q1eC/nV2cw=="
    crossorigin="anonymous" referrerpolicy="no-referrer" />
<!-- Start Header-Top -->
<ng-container *ngIf="isDisplay && this.displayBundlePage">
    <!------------- main content ---------------->

    <div class="main-container" style="background-color: white;">

        <div class="alert alert-warning reload_notification"
            style="margin-bottom:1rem;display: flex;justify-content: space-between;" *ngIf="layout_change">
            Circular is updated. Please refresh page.
            <div (click)="close_notification()">
                <svg clip-rule="evenodd" height="24px" width="24px" fill-rule="evenodd" stroke-linejoin="round"
                    stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
                </svg>
            </div>
        </div> 
        <app-header class="app_header" [store_data]="store_data" [branch_list]="branch_list" [cartItem]="cartItem" [past_item]="past_item"
            [textColor]="textColor" [current_layout_data]="current_bundle_data" [serializedData]="serializedData"[serializedBundleData]="serializedBundleData" [serializedMenuData]="serializedMenuData"
            (categoryList)="categoryProd($event)" (productChange)="product_select_change($event)"
            (addToList)="add_to_list($event)" (decreaseProduct)="decrease_product($event)" [packDetails]="packDetails"
            (increaseProduct)="increase_product($event)" (showLoader)="showLoader($event)"
            [categories_list]="categories_list" (removeFilter)="removeFilter()" [store_category]="store_category"
            [all_items]="all_items" [showHelpIcon]="showHelpIcon"></app-header>
        <!-- End Bottom Header -->

        <div class="slider close">
            <div (click)="bottomSliderDiv()" class="close-arrow text-center cursor-pointer">
                <img src="../../assets/images/black-down.png" alt="" />
            </div>
            <div class="container">
                <div class="header-social">
                    <div class="envelope social-icons" data-toggle="modal" data-target="#EmailModel">
                        <a (click)="bottomSliderDiv()" target="_blank">
                            <img src="../../assets/images/envelope.png" alt="" />
                            <span>Email</span>
                        </a>
                    </div>
                    <div class="clip-board social-icons">
                        <div (click)="copy_link()">
                            <img src="../../assets/images/clip.png" alt="" />
                            <span>Copy Link</span>
                        </div>
                    </div>
                    <div class="share-to-phone social-icons">
                        <div (click)="bottomSliderDiv()" data-toggle="modal" data-target="#QrCodeModel">
                            <img class="ShareWithPhone" src="../../assets/images/shartophone.png" alt="" />
                            <img class="ShareVia d-none" src="../../assets/images/sharevia.png" alt="" />
                            <span class="ShareWithPhone">Send to phone</span>
                            <span class="ShareVia d-none">Share Via</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- EMAIL POPUP -->

        <div class="modal fade" id="EmailModel" tabindex="-1" role="dialog" aria-labelledby="EmailModel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header justify-content-start">
                        <h5 class="modal-title pl-5" id="EmailModel">
                            Send Link to your Email
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="qr-code mt-4 mb-4 text-center">
                            <form [formGroup]="emailForm" (ngSubmit)="sendMail()">
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Email</mat-label>
                                    <input type="email" matInput placeholder="Ex. john.deo@gmail.com" name="email"
                                        formControlName="email">
                                </mat-form-field>
                                <div>
                                    <button mat-button class="submit-button" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- QR CODE POPUP -->
        <!-- Button trigger modal -->

        <!-- Modal -->
        <div class="modal fade" id="QrCodeModel" tabindex="-1" role="dialog" aria-labelledby="QrCodeModel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header justify-content-start">
                        <span class="close" data-dismiss="modal" aria-label="Close"><img style="width: 10px"
                                src="../../assets/images/Arrow.png" alt="" /></span>
                        <h5 class="modal-title pl-5" id="QrCodeModel">
                            Send List to your Phone
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div class="qr-code mt-4 mb-4 text-center">
                            <qrcode [elementType]="qr_elementType" [qrdata]="qr_value" cssClass="aclass"
                                errorCorrectionLevel="L" [allowEmptyString]="true" >
                            </qrcode>
                        </div>
                        <div class="qr-msg text-center">
                            <p style="color: #000000">Point Phone Camera Here and Click Link</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Mobile Modal -->
        <div class="modal fade" id="ContactModel" tabindex="-1" role="dialog" aria-labelledby="ContactModel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body p-0">

                        <div aria-labelledby="LocationDropdown">
                            <div class="zip-code-input-div">
                                <div class="select-store-location-headline">

                                    <h5 class="d-flex align-items-center"><span class="close" data-dismiss="modal"
                                            aria-label="Close"><img style="width: 10px"
                                                src="../../assets/images/Arrow.png" alt="" /></span>Selected
                                        store name </h5>
                                    <div class="contact-popup">
                                        <a href="https://www.google.com/maps"><img
                                                src="../../assets/images/sqaure location.png" alt=""></a>
                                        <a href="tel:+1234567890"><img src="../../assets/images/squarePhoone.png"
                                                alt=""></a>

                                    </div>
                                    <h5>Change Store Location </h5>
                                </div>

                                <input type="number" id="SearchByZipCode" class="form-control"
                                    placeholder="Search by Zip code" />
                            </div>
                            <a class="dropdown-item" href="" *ngFor="let branch of branch_list">Branch 1 Name</a>
                        </div>


                    </div>
                </div>
            </div>
        </div>


        <!-- BODY CONTENT HERE -->

        <div class="swipe-event-class" style="height: 100vh !important;" (touchstart)="onTouchStart($event)"
            (mousedown)="onTouchStart($event)" (mouseup)="onTouchEnd($event)" (touchend)="onTouchEnd($event)">
            <div class="bundle_content layout-types middle_sec" [ngClass]="show_dynamic_header">

                <div class="">
                    <div class="layout-selection">

                        <!--LAYOUT TYPE 1-->
                        <div class="product-thumbnail"
                            *ngIf="current_bundle_data && current_bundle_data[0]?.layout_type == 'page'">
                            <div class="row">

                                <div class="add-new-product"
                                    *ngIf="current_bundle_data && current_bundle_data[0]?.page1_url !=''">
                                    <div class="btn-group dropright">
                                        <img src="{{current_bundle_data && current_bundle_data[0]?.page1_url}}" alt=""
                                            class="img-fluid">
                                    </div>
                                </div>

                                <div class="add-new-product"
                                    *ngIf="current_bundle_data && current_bundle_data[0]?.page2_url !=''">
                                    <div class="btn-group dropright">
                                        <img src="{{current_bundle_data && current_bundle_data[0]?.page2_url}}" alt=""
                                            class="img-fluid">
                                    </div>
                                </div>
                                <div class="add-new-product"
                                    *ngIf="current_bundle_data && current_bundle_data[0]?.page3_url !=''">
                                    <div class="btn-group dropright">
                                        <img src="{{current_bundle_data && current_bundle_data[0]?.page3_url}}" alt=""
                                            class="img-fluid">

                                    </div>
                                </div>
                                <div class="add-new-product"
                                    *ngIf="current_bundle_data && current_bundle_data[0]?.page4_url !=''">
                                    <div class="btn-group dropright">
                                        <img src="{{current_bundle_data && current_bundle_data[0]?.page4_url}}" alt=""
                                            class="img-fluid">
                                    </div>
                                </div>
                                <div class="add-new-product"
                                    *ngIf="current_bundle_data && current_bundle_data[0]?.page5_url !=''">
                                    <div class="btn-group dropright">
                                        <img src="{{current_bundle_data && current_bundle_data[0]?.page5_url}}" alt=""
                                            class="img-fluid">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!--LAYOUT TYPE 1-->
                        <div class="ifream_images"
                            *ngIf="current_bundle_data && current_bundle_data[0]?.layout_type == 'grid'">
                            <div class="text-right d-md-none">
                                <button class="btn btn-success mr-2 font-size-12-sm" (click)="largeView()">Large View</button>
                                <button class="btn btn-success mr-2 font-size-12-sm" (click)="listView()">List View</button>
                                <button class="btn btn-success mt-2 mb-2 mr-2 font-size-12-sm" (click)="normalView()">Circular View</button>
                            </div>
                            <div class="row test_scroll" style="padding:0;margin:0;">
                                <div class="col-12" style="padding:0;margin:0;">
                                    <div class="grid-stack bundle grid-background">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- LAYOUT TYPE 2 -->

                    </div>
                </div>
            </div>
        </div>


        <!-- FOOTER STARTS HERE -->
        <!-- Start Footer -->
        <footer class="footer_style">

            <div class="container-fluid">
                <div class="plain-text-footer-area">
                    <div class="footer-text scroll_hide">
                        {{current_bundle_data && current_bundle_data[0]?.terms}}
                    </div>
                </div>
                <div class="footer-social">
                    <div class="row"
                        style="color:{{textColor}}; background:{{store_data && store_data[0]?.theme_base_color}}">
                        <div
                            class="col-md-6 col-sm-12 px-0 d-flex align-items-center  mod-mid-header-menu d-block d-md-none">
                            <div class="cart-area">
                                <div (click)="ShowShoppingList()" class="shopping-list-icon chervon-up-icon">
                                    <div class="main-counter">
                                        <img src="../../../assets/imgs/right-with-border.png">
                                        <div class="counter" style="border: 1px solid {{textColor}};">
                                            <span>{{cartItem?.length}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div (click)="CloseShoppingList()" class="shopping-list-icon chervon-down-icon d-none">
                                    <div class="main-counter">
                                        <img src="../../../assets/imgs/right-with-border.png">
                                        <div class="counter" style="border: 1px solid {{textColor}};">
                                            <span>{{cartItem?.length}}</span>
                                        </div>
                                    </div>
                                </div>
                                <span class="shopping-list-headline">
                                    <span class="shopping-list-headline">
                                        <h4 (click)="CloseShoppingList()" class="mb-0 chervon-up-icon d-none"
                                            style="color:{{textColor}};">
                                            My Shopping List
                                        </h4>
                                        <h4 (click)="ShowShoppingList()" class="mb-0 chervon-down-icon"
                                            style="color:{{textColor}};">
                                            My Shopping List
                                        </h4>
                                    </span>
                                </span>
                                <span (click)="CloseShoppingList()" class="chervon-up-icon d-none">
                                    <svg width="24px" height="24px" fill="#fff" class="footer-arrow-icon"
                                        viewBox="0 0 24 24" id="magicoon-Regular" xmlns="http://www.w3.org/2000/svg">
                                        <title>chevron-circle-right</title>
                                        <path id="chevron-circle-right-Regular-2"
                                            data-name="chevron-circle-right-Regular" class="cls-1"
                                            d="M12,2.25A9.75,9.75,0,1,0,21.75,12,9.761,9.761,0,0,0,12,2.25Zm0,18A8.25,8.25,0,1,1,20.25,12,8.259,8.259,0,0,1,12,20.25Zm2.53-8.78a.749.749,0,0,1,0,1.06l-3,3a.75.75,0,0,1-1.06-1.06L12.939,12,10.47,9.53a.75.75,0,0,1,1.06-1.06Z" />
                                    </svg>
                                </span>
                                <span (click)="ShowShoppingList()" class="chervon-down-icon">
                                    <svg width="24px" height="24px" fill="#fff" class="footer-arrow-icon"
                                        viewBox="0 0 24 24" id="magicoon-Regular" xmlns="http://www.w3.org/2000/svg">
                                        <title>chevron-circle-left</title>
                                        <path id="chevron-circle-left-Regular-2" data-name="chevron-circle-left-Regular"
                                            class="cls-1"
                                            d="M13.53,9.53,11.061,12l2.469,2.47a.75.75,0,1,1-1.06,1.06l-3-3a.749.749,0,0,1,0-1.06l3-3a.75.75,0,0,1,1.06,1.06ZM21.75,12A9.75,9.75,0,1,1,12,2.25,9.761,9.761,0,0,1,21.75,12Zm-1.5,0A8.25,8.25,0,1,0,12,20.25,8.259,8.259,0,0,0,20.25,12Z" />
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 px-0 sociel-hide ">
                            <div class="social-links" >
                                <div class="follow-headline"
                                    *ngIf="display_social_icons.twitter || display_social_icons.facebook || display_social_icons.instagram || display_social_icons.youtube">
                                    <span>Follow us on</span>
                                </div>

                                <div class="social-media" *ngIf="display_social_icons.twitter">
                                    <a href="{{store_data && store_data[0]?.social_twitter_url }}" target="_blank">
                                        <svg width="36" height="35" viewBox="0 0 36 35" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M18 0C8.33594 0 0.5 7.83594 0.5 17.5C0.5 27.1641 8.33594 35 18 35C27.6641 35 35.5 27.1641 35.5 17.5C35.5 7.83594 27.6641 0 18 0ZM26.4102 13.1914C26.4219 13.375 26.4219 13.5664 26.4219 13.7539C26.4219 19.4883 22.0547 26.0938 14.0742 26.0938C11.6133 26.0938 9.33203 25.3789 7.41016 24.1484C7.76172 24.1875 8.09766 24.2031 8.45703 24.2031C10.4883 24.2031 12.3555 23.5156 13.8438 22.3516C11.9375 22.3125 10.3359 21.0625 9.78906 19.3438C10.457 19.4414 11.0586 19.4414 11.7461 19.2656C10.7646 19.0662 9.88232 18.5331 9.24928 17.757C8.61624 16.9808 8.27143 16.0094 8.27344 15.0078V14.9531C8.84766 15.2773 9.52344 15.4766 10.2305 15.5039C9.6361 15.1078 9.14866 14.5711 8.81137 13.9415C8.47408 13.3119 8.29736 12.6088 8.29688 11.8945C8.29688 11.0859 8.50781 10.3477 8.88672 9.70703C9.97618 11.0482 11.3357 12.1451 12.8768 12.9264C14.418 13.7078 16.1063 14.1561 17.832 14.2422C17.2188 11.293 19.4219 8.90625 22.0703 8.90625C23.3203 8.90625 24.4453 9.42969 25.2383 10.2734C26.2188 10.0898 27.1562 9.72266 27.9922 9.23047C27.668 10.2344 26.9883 11.082 26.0859 11.6172C26.9609 11.5234 27.8047 11.2812 28.5859 10.9414C27.9961 11.8086 27.2578 12.5781 26.4102 13.1914Z"
                                                [attr.fill]="textColor" />
                                        </svg>
                                    </a>
                                </div>

                                <div class="social-media" *ngIf="display_social_icons.facebook">
                                    <a href="{{store_data && store_data[0]?.social_facebook_url }}" target="_blank">
                                        <svg width="35" height="35" viewBox="0 0 35 35" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M0 17.5977C0 26.2981 6.31896 33.5329 14.5833 35V22.3606H10.2083V17.5H14.5833V13.6106C14.5833 9.23563 17.4023 6.80604 21.3894 6.80604C22.6523 6.80604 24.0144 7 25.2773 7.19396V11.6667H23.0417C20.9023 11.6667 20.4167 12.7356 20.4167 14.0977V17.5H25.0833L24.306 22.3606H20.4167V35C28.681 33.5329 35 26.2996 35 17.5977C35 7.91875 27.125 0 17.5 0C7.875 0 0 7.91875 0 17.5977Z"
                                                [attr.fill]="textColor" />
                                        </svg>
                                    </a>
                                </div>

                                <div class="social-media" *ngIf="display_social_icons.instagram">
                                    <a href="{{store_data && store_data[0]?.social_instagram_url }}" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38"
                                            viewBox="0 0 24 24" fill="white">
                                            <path
                                                d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
                                        </svg>
                                    </a>
                                </div>

                                <div class="social-media" *ngIf="display_social_icons.youtube">
                                    <a href="{{store_data && store_data[0]?.social_youtube_url }}" target="_blank">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38"
                                            viewBox="0 0 24 24" fill="white">
                                            <path
                                                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.441 16.892c-2.102.144-6.784.144-8.883 0-2.276-.156-2.541-1.27-2.558-4.892.017-3.629.285-4.736 2.558-4.892 2.099-.144 6.782-.144 8.883 0 2.277.156 2.541 1.27 2.559 4.892-.018 3.629-.285 4.736-2.559 4.892zm-6.441-7.234l4.917 2.338-4.917 2.346v-4.684z" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-sm-12 px-0">
                            <!-- <div class="price-goods"
                                *ngIf="store_data[0]?.access_layout != 'Upload Page’s Images/url layout only' && !this.store_data[0]?.override_noad_layout">
                                <span id="price-goods" *ngIf="this.current_bundle_data">Prices Good</span>
                                <span>{{current_bundle_data && (current_bundle_data[0]?.actual_start_date ?
                                    current_bundle_data[0]?.actual_start_date :
                                    (current_bundle_data[0]?.start_date.seconds* 1000)) |
                                    date:"MM/dd/yyyy"}}</span>
                                <span *ngIf="this.current_bundle_data">-</span>
                                <span>{{current_bundle_data && (current_bundle_data[0]?.actual_end_date ?
                                    current_bundle_data[0]?.actual_end_date : (current_bundle_data[0]?.end_date.seconds*
                                    1000)) |
                                    date:"MM/dd/yyyy"}}</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12  align-items-center justify-content-end d-none" id="ShowListButton">
                <div class="cart-area footer-cart" style="color:{{textColor}};">
                    <div (click)="CloseShoppingList()" class="shopping-list-icon chervon-up-icon ">
                        <img src="{{counter_img}}">
                    </div>
                    <div (click)="ShowShoppingList()" class="shopping-list-icon chervon-down-icon d-none">
                        <img src="{{counter_img}}">
                    </div>
                    <span class="shopping-list-headline">
                        <h4 (click)="ShowShoppingList()" class="mb-0 chervon-up-icon" style="color:{{textColor}};">My
                            Shopping List </h4>
                        <h4 (click)="CloseShoppingList()" class="mb-0 chervon-down-icon d-none"
                            style="color:{{textColor}};">My Shopping List</h4>
                    </span>
                    <span (click)="CloseShoppingList()" class="chervon-up-icon d-none">
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="white" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M31.5 16.015C31.5 24.5841 24.56 31.53 16 31.53C7.44003 31.53 0.5 24.5841 0.5 16.015C0.5 7.44586 7.44003 0.5 16 0.5C24.56 0.5 31.5 7.44586 31.5 16.015Z"
                                [attr.stroke]="textColor" />
                            <path
                                d="M9.00021 17.8302C8.9997 17.6808 9.03268 17.5332 9.09671 17.3982C9.16073 17.2632 9.25419 17.1443 9.37021 17.0502L15.3702 12.2202C15.5491 12.0731 15.7736 11.9927 16.0052 11.9927C16.2368 11.9927 16.4613 12.0731 16.6402 12.2202L22.6402 17.2202C22.8444 17.3899 22.9729 17.6338 22.9972 17.8982C23.0216 18.1627 22.94 18.4259 22.7702 18.6302C22.6005 18.8344 22.3566 18.9628 22.0921 18.9872C21.8277 19.0116 21.5644 18.9299 21.3602 18.7602L16.0002 14.2902L10.6402 18.6102C10.4934 18.7324 10.3147 18.8101 10.1251 18.834C9.93559 18.8579 9.74316 18.827 9.57064 18.7449C9.39811 18.6629 9.2527 18.5331 9.15161 18.371C9.05052 18.2089 8.99798 18.0212 9.00021 17.8302Z"
                                [attr.fill]="textColor" />
                        </svg>
                    </span>
                    <span (click)="ShowShoppingList()" class="chervon-down-icon">
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M31.5 16.985C31.5 8.41586 24.56 1.47 16 1.47C7.44003 1.47 0.5 8.41586 0.5 16.985C0.5 25.5541 7.44003 32.5 16 32.5C24.56 32.5 31.5 25.5541 31.5 16.985Z"
                                fill="none" [attr.stroke]="textColor" />
                            <path
                                d="M8.99972 15.17C8.99922 15.3194 9.03219 15.467 9.09622 15.602C9.16025 15.737 9.2537 15.8559 9.36973 15.95L15.3697 20.78C15.5487 20.9271 15.7731 21.0075 16.0047 21.0075C16.2363 21.0075 16.4608 20.9271 16.6397 20.78L22.6397 15.78C22.8439 15.6103 22.9724 15.3663 22.9967 15.1019C23.0211 14.8375 22.9395 14.5742 22.7697 14.37C22.6 14.1658 22.3561 14.0374 22.0916 14.013C21.8272 13.9886 21.5639 14.0703 21.3597 14.24L15.9997 18.71L10.6397 14.39C10.4929 14.2677 10.3142 14.19 10.1246 14.1662C9.9351 14.1423 9.74268 14.1732 9.57015 14.2553C9.39762 14.3373 9.25221 14.4671 9.15112 14.6292C9.05003 14.7913 8.99749 14.979 8.99972 15.17Z"
                                [attr.fill]="textColor" />
                        </svg>
                    </span>
                </div>
            </div>

        </footer>
    </div>

</ng-container>
<!-- End Footer -->
    <!-- <div *ngIf=" isDisplay && !this.displayBundlePage" >This page is not accessible.you do not have permission to access this page</div> -->
<div class="loader1" *ngIf="show_loader1 ">
    <mat-spinner mode="indeterminate"
        style=" position: fixed; top: 50%; margin: 0 auto; right: 0; left: 0; transform: translate(-50%);">
    </mat-spinner>
</div>
<div class="loader" *ngIf="show_loader ">
    <mat-spinner mode="indeterminate"
        style=" position: fixed; top: 50%; margin: 0 auto; right: 0; left: 0; transform: translate(-50%);">
    </mat-spinner>
</div>