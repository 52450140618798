import { Component, PLATFORM_ID, Inject, OnInit, HostListener } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare var $: any;
import { DomSanitizer } from '@angular/platform-browser';
import { AppRecipeTourService } from '../../shared/services/app-recipe-tour.service';
import { AppRecipeSlideoutTourService } from 'src/app/shared/services/app-recipe-slideout-tour.service';
import { AppShoppinglistTourService } from 'src/app/shared/services/app-shoppinglist-tour.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';


@Component({
  selector: 'app-store-recipes',
  templateUrl: './store-recipes.component.html',
  styleUrls: ['./store-recipes.component.scss'],
  providers: [DatePipe]
})
export class StoreRecipesComponent implements OnInit {
  name = 'Jquery Integration With Angular!';
  isJqueryWorking: any;
  store_name_slug: any;
  // branch_name_slug: any;
  categories_list: any = [];
  branch_list: any = [];
  store_data: any = [];
  current_store_uid: any;
  current_layout_data: any;
  counter_img = ''
  serializedData: any
  current_bundle_data: any;
	serializedBundleData: any =[];
	serializedMenuData: any =[];
	combinedData: any=[];
	current_menu_data: any;
  iframe_src: any;
  recipes_list: any = [];
  recipes_box_list: any = [];
  selected_recipe_id: any = '';
  products_array_data: any = [];
  isDisplay = false;
  textColor = 'white';
  display_social_icons = { "facebook": false, "instagram": false, "twitter": false, "youtube": false }
  show_instructions = false
  recipe_list: any;
  loading: boolean = false;
  current_list_id: any;
  current_list_data: any;
  qr_elementType: 'url' | 'canvas' | 'img' = 'url';
  qr_value: any;
  show_add_item = false;
  all_items = []
  show_loader = false;
  filtered_items = [];
  serializedIds = [];
  product_image_data: any;
  selected_national_recipe: any
  recipe_categories = ['All', 'Main Course', 'Bread', 'Marinade', 'Side Dish', 'Breakfast', 'Fingerfood', 'Dessert', 'Soup', 'Snack', 'Appetizer', 'Beverage', 'Drink', 'Salad', 'Sauce']
  cartItem = localStorage.getItem('cart-item') ? JSON.parse(localStorage.getItem('cart-item')) : []
  cart_ids = localStorage.getItem('cart-item') ? JSON.parse(localStorage.getItem('cart-item')).map(i => i.product.uid) : []
  store_items = []
  selected_cart_items = []
  isAllSelected = false
  currentCategory: any;
  private recipeSlideoutTourSeenFlag = 'recipeSlideOutTourSeen';
  private shoppingTourSeenFlag = 'shoppingTourSeen';
  displayRecipePage: boolean;
  show_dynamic_header: string;


  constructor(@Inject(PLATFORM_ID)
  private platformId: object,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private afStorage: AngularFireStorage,
    private modalService: NgbModal,
    private storage: AngularFireStorage,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private datePipe: DatePipe,
    private apptourService: AppRecipeTourService,
    private appRecipeTourService: AppRecipeTourService,
    private appRecipeSlideoutTourService: AppRecipeSlideoutTourService,
    private appshoppinglistTourService: AppShoppinglistTourService,
    private analytics: AngularFireAnalytics
  ) { }

  // code for swipe down event
  private startX: number;
  private startY: number;
  countRight: number = 0;
  countLeft: number = 0;

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:touchmove', ['$event'])
  onTouchMove(event: MouseEvent | TouchEvent) {
    if (document.documentElement.clientWidth <= 767) {
      if (this.startX === null || this.startY === null) {
        return;
      }

      const currentX = this.getCurrentX(event);
      const currentY = this.getCurrentY(event);
      const deltaX = currentX - this.startX;
      const deltaY = currentY - this.startY;

      if (Math.abs(deltaX) > 40) {
        const directionX = deltaX > 0 ? 'right' : 'left';
        if (directionX == 'left') {
          this.countLeft = this.countLeft + 1;
        }
        if (this.countLeft > 20) {
          // this.ShowShoppingList()
          this.countLeft = 0;
        }
        if (directionX == 'right') {
          this.countRight = this.countRight + 1;
        }
        if (this.countRight > 20) {
          this.CloseFilePanel();
          this.countRight = 0;
        }
      }
    }
  }

  onTouchStart(event: MouseEvent | TouchEvent) {
    this.startX = this.getCurrentX(event);
    this.startY = this.getCurrentY(event);
  }

  onTouchEnd(event: MouseEvent | TouchEvent) {
    this.startX = null;
    this.startY = null;
  }

  private getCurrentX(event: MouseEvent | TouchEvent): number {
    if (event instanceof MouseEvent) {
      return event.clientX;
    } else if (event instanceof TouchEvent) {
      return event.touches[0].clientX;
    }

    return 0;
  }

  private getCurrentY(event: MouseEvent | TouchEvent): number {
    if (event instanceof MouseEvent) {
      return event.clientY;
    } else if (event instanceof TouchEvent) {
      return event.touches[0].clientY;
    }

    return 0;
  }

  async ngOnInit(): Promise<void> {

    localStorage.setItem("printButton",'NotDisplay')
    this.currentCategory = this.route.snapshot.params['recipe_categories'] ? this.route.snapshot.params['recipe_categories'] : 'all';

    if (localStorage.getItem('showAppList') == 'true') {
      if (window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://')) {
        window.location.href = localStorage.getItem('appRedirectUrl');
        localStorage.setItem('showAppList', 'false');
        localStorage.removeItem('appRedirectUrl');
      } else {
      }
    } else {
      if (!(window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://'))) {
        this.route.data.subscribe(data => {
          if (data.showAppList === true) {
            localStorage.setItem('showAppList', 'true');
            localStorage.setItem('appRedirectUrl', window.location.href);
          } else {
            localStorage.setItem('showAppList', 'false');
            localStorage.removeItem('appRedirectUrl');
          }
        })
      }
    }

    let json_recipe = document.cookie
    if (json_recipe.includes('my_recipe_item')) {
      this.recipes_box_list = JSON.parse(('; ' + document.cookie).split(`; my_recipe_item=`).pop().split(';')[0])
    }
    if (this.route.snapshot.params?.list_id) {
      this.current_list_id = this.route.snapshot.params?.list_id
    } else {
      this.current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
    }
    const headerDict = {
      'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
      'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.loading = true
    if (this.currentCategory != 'all') {
      this.http
        .get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/complexSearch?number=300000&addRecipeInformation=true&fillIngredients=true&type=' + this.currentCategory,
          requestOptions)
        .subscribe((res: any) => {
          this.recipe_list = res.results;
          this.loading = false;
        });
    } else {
      this.http
        .get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/complexSearch?number=300000&addRecipeInformation=true&fillIngredients=true',
          requestOptions)
        .subscribe((res: any) => {
          this.recipe_list = res.results;
          this.loading = false;
        });
    }
    this.store_name_slug = this.route.snapshot.params['store_name'];

    $(document).ready(() => {
      const body = document.body;
      const scrollUp = "scroll-up";
      const scrollDown = "scroll-down";
      let lastScroll = 0;

      window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 200) {
          body.classList.remove(scrollUp);
          body.classList.remove(scrollDown);
          return;
        }

        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
          // down
          body.classList.remove(scrollUp);
          body.classList.add(scrollDown);

        } else if (
          currentScroll < lastScroll &&
          body.classList.contains(scrollDown)
        ) {
          // up
          body.classList.remove(scrollDown);
          body.classList.add(scrollUp);

        }
        lastScroll = currentScroll;
      });

      const LocalURL = 'http://localhost:4200';
      const FireBaseURL = 'https://digital-circular-pro-admin.web.app';
      if (document.location.href == FireBaseURL + '/home') {
        $('.browse-menu').addClass('menu-active');
      }
      if (document.location.href == FireBaseURL + '/coupon-list') {
        $('.Coupons').addClass('menu-active');
        $('.bottom-header-section').addClass('d-none');
      }
      if (document.location.href == FireBaseURL + '/recipe-list') {
        $('.Recipes').addClass('menu-active');
        $('.bottom-header-section').addClass('d-none');
      }
    });

   await this.afs.collection('stores', ref => ref.where('company_name_slug', '==', this.store_name_slug)).snapshotChanges().subscribe(async (data: any) => {
      this.store_data = await data.map(e => {
        return {
          uid: e.payload.doc.id,
          access_coupon: e.payload.doc.data()['access_coupon'],
          access_layout: e.payload.doc.data()['access_layout'],
          access_recipe: e.payload.doc.data()['access_recipe'],
          address: e.payload.doc.data()['address'],
          zip_code: e.payload.doc.data()['zip_code'],
          company_name_slug: e.payload.doc.data()['company_name_slug'],
          company_name: e.payload.doc.data()['company_name'],
          email: e.payload.doc.data()['email'],
          menu1_link: e.payload.doc.data()['menu1_link'] ? e.payload.doc.data()['menu1_link'] : "",
          menu1_name: e.payload.doc.data()['menu1_name'],
          menu2_name: e.payload.doc.data()['menu2_name'],
          menu2_link: e.payload.doc.data()['menu2_link'],
          menu3_name: e.payload.doc.data()['menu3_name'],
          menu3_link: e.payload.doc.data()['menu3_link'],
          menu4_name: e.payload.doc.data()['menu4_name'],
          menu4_link: e.payload.doc.data()['menu4_link'],
          menu5_name: e.payload.doc.data()['menu5_name'],
          menu5_link: e.payload.doc.data()['menu5_link'],
          menu6_name: e.payload.doc.data()['menu6_name'],
          menu6_link: e.payload.doc.data()['menu6_link'],
          menu7_name: e.payload.doc.data()['menu7_name'],
          menu7_link: e.payload.doc.data()['menu7_link'],
          menu8_name: e.payload.doc.data()['menu8_name'],
          menu8_link: e.payload.doc.data()['menu8_link'],
          menu9_name: e.payload.doc.data()['menu9_name'],
          menu9_link: e.payload.doc.data()['menu9_link'],
          parent_id: e.payload.doc.data()['parent_id'],
          type: e.payload.doc.data()['type'],
          store_logo: e.payload.doc.data()['store_logo'],
          phone: e.payload.doc.data()['phone'],
          social_facebook_url: e.payload.doc.data()['social_facebook_url'],
          social_twitter_url: e.payload.doc.data()['social_twitter_url'],
          social_instagram_url: e.payload.doc.data()['social_instagram_url'],
          social_youtube_url: e.payload.doc.data()['social_youtube_url'],
          theme_base_color: e.payload.doc.data()['theme_base_color'],
          branch_name_color: e.payload.doc.data().branch_name_color || "#666666",
          theme_text_color: e.payload.doc.data()['theme_text_color'],
          override_noad_layout: e.payload.doc.data().override_noad_layout ? e.payload.doc.data().override_noad_layout : false,
          displayCircularPage: e.payload.doc.data().displayCircularPage ? e.payload.doc.data().displayCircularPage : false,
          displayBundlePage: e.payload.doc.data().displayBundlePage ? e.payload.doc.data().displayBundlePage : false,
          displayMenuPage: e.payload.doc.data().displayMenuPage ? e.payload.doc.data().displayMenuPage : false,
          displayCouponPage: e.payload.doc.data().displayCouponPage ? e.payload.doc.data().displayCouponPage : false,
          displayRecipePage: e.payload.doc.data().displayRecipePage ? e.payload.doc.data().displayRecipePage : false,
        };
      });
      if(this.store_data[0]?.displayRecipePage){
        this.displayRecipePage = true
        if(this.store_data[0]?.company_name){
          document.title = `Recipes - ${this.store_data[0]?.company_name}`;
          this.analytics.logEvent('page_view', { page_title:`Recipes - ${this.store_data[0]?.company_name}` });       
        }
      }else{
        this.displayRecipePage = false
        if(this.store_data[0]?.displayCircularPage){
					this.router.navigate(['/' + this.store_name_slug ]);
				}else if (this.store_data[0]?.displayBundlePage){
					this.router.navigate(['/' + this.store_name_slug + '/bundles']);
				}else if (this.store_data[0]?.displayMenuPage){
					this.router.navigate(['/' + this.store_name_slug + '/menu']);
				}else if (this.store_data[0]?.displayCouponPage){
					this.router.navigate(['/' + this.store_name_slug + '/coupons']);
				}
      }   
      console.log("recie[es",this.store_data)        
      this.textColor = this.store_data[0]?.theme_text_color ? this.store_data && this.store_data[0]?.theme_text_color : 'white';
      if (this.textColor === 'white' || this.textColor === '#ffffff') {
        this.counter_img = "../../../assets//imgs/white-counter.svg"
      } else {
        this.counter_img = '../../../assets//imgs/black-counter.svg'
      }
      this.isDisplay = true;
      if (this.store_data[0]?.social_facebook_url !== "" && this.store_data[0].social_facebook_url !== undefined) { this.display_social_icons.facebook = true }
      if (this.store_data[0]?.social_twitter_url !== "" && this.store_data[0].social_twitter_url !== undefined) { this.display_social_icons.twitter = true }
      if (this.store_data[0]?.social_instagram_url !== "" && this.store_data[0].social_instagram_url !== undefined) { this.display_social_icons.instagram = true }
      if (this.store_data[0]?.social_youtube_url !== "" && this.store_data[0].social_youtube_url !== undefined) { this.display_social_icons.youtube = true }

      if (this.store_data && this.store_data.length > 0) {
        this.current_store_uid = this.store_data[0]?.uid;
        this.afs.collection('/stores', ref => ref.where('parent_id', '==', this.store_data[0]?.parent_id).orderBy('created_at', 'asc')).snapshotChanges().subscribe(data => {
          this.branch_list = data.map(e => {
            return {
              uid: e.payload.doc.id,
              branch_name: e.payload.doc.data()['branch_name'] ? e.payload.doc.data()['branch_name'] : "",
              branch_name_slug: e.payload.doc.data()['branch_name_slug'] ? e.payload.doc.data()['branch_name_slug'] : ""
            };
          });
        });

        this.afs.collection('/recipes', ref => ref.where('store_list', 'array-contains', this.current_store_uid).where('status', '==', 'show').orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
          this.recipes_list = data.map(e => {
            return {
              uid: e.payload.doc.id,
              recipe_cover_image: e.payload.doc.data()['recipe_cover_image'] ? e.payload.doc.data()['recipe_cover_image'] : "https://via.placeholder.com/300x200/2f2f2f/2f2f2f",
              recipe_name: e.payload.doc.data()['recipe_name'] ? e.payload.doc.data()['recipe_name'] : "",
              instructions: e.payload.doc.data()['instructions'] ? e.payload.doc.data()['instructions'] : "",
              ingredients: e.payload.doc.data()['ingredients'] ? e.payload.doc.data()['ingredients'] : "",
              products_array: e.payload.doc.data()['products_array'] ? e.payload.doc.data()['products_array'] : "",
            };
          });
          this.serializedIds = this.recipes_list.map(s => s.uid)
        });

        let start_date = new Date();
        this.current_bundle_data = await this.getBundleData();
				this.current_menu_data = await this.getMenuData();
        if (!this.store_data[0].override_noad_layout) {
          this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).snapshotChanges().subscribe(data => {
            this.current_layout_data = []
            data.forEach((docs: any) => {
              const layout_start_date = docs.payload.doc.data().start_date.toDate()
              const endDate = docs.payload.doc.data().end_date.toDate()
              if (layout_start_date <= start_date && endDate > start_date && (docs.payload.doc.data().layout_json.length > 0 || docs.payload.doc.data().layout_type == 'page')) {
                this.current_layout_data.push(docs.payload.doc.data())
              }
            })
            this.serializedData = this.current_layout_data[0]?.layout_json;
					  this.serializedMenuData = this.current_menu_data[0]?.menu_json;
					  this.serializedBundleData = this.current_bundle_data[0]?.bundle_json;
					  this.combinedData = [];
            if(this.serializedData?.length>0){
              this.combinedData.push(...this.serializedData);
               }
            if(this.serializedBundleData?.length>0){
                this.combinedData.push(...this.serializedBundleData);
               }
            if(this.serializedMenuData?.length>0){
                this.combinedData.push(...this.serializedMenuData);
              }
					  console.log(this.serializedData, "serializedData")
					  console.log(this.serializedMenuData, "serializedMenuData")
					  console.log(this.serializedBundleData, "serializedBundleData")
					  this.serializedIds = this.combinedData?.map(s => s.id)

            if (this.current_layout_data[0]?.layout_type == "grid") {
              this.iframe_src = this.sanitizer.bypassSecurityTrustResourceUrl("assets/dclive.html?store_uid=" + this.current_store_uid + "&template_id=" + this.current_layout_data[0]?.uid + "&r=11");
            } else {
              this.iframe_src = "";
            }

          });
        }
        else {
          this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid)).snapshotChanges().subscribe((data: any) => {
            this.current_layout_data = data.map(e => {
              return {
                uid: e.payload.doc.id,
                selected_product_id: e.payload.doc.data().selected_product_id ? e.payload.doc.data().selected_product_id : '',
                layout_json: e.payload.doc.data().layout_json ? e.payload.doc.data().layout_json : [],
                layout_type: e.payload.doc.data().layout_type ? e.payload.doc.data().layout_type : '',
                include_header_checkbox: e.payload.doc.data().include_header_checkbox,
                start_date: e.payload.doc.data().start_date,
                end_date: e.payload.doc.data().end_date,
                header_text: e.payload.doc.data().header_text,
                terms: e.payload.doc.data().terms ? e.payload.doc.data().terms : '',
                layout_json_temp: e.payload.doc.data().layout_json_temp ? e.payload.doc.data().layout_json_temp : '',
                is_publish: e.payload.doc.data().is_publish ? e.payload.doc.data().is_publish : '',
                page1_type: e.payload.doc.data().page1_type ? e.payload.doc.data().page1_type : '',
                page2_type: e.payload.doc.data().page2_type ? e.payload.doc.data().page2_type : '',
                page3_type: e.payload.doc.data().page3_type ? e.payload.doc.data().page3_type : '',
                page4_type: e.payload.doc.data().page4_type ? e.payload.doc.data().page4_type : '',
                page5_type: e.payload.doc.data().page5_type ? e.payload.doc.data().page5_type : '',
                page1_url: e.payload.doc.data().page1_url ? e.payload.doc.data().page1_url : '',
                page2_url: e.payload.doc.data().page2_url ? e.payload.doc.data().page2_url : '',
                page3_url: e.payload.doc.data().page3_url ? e.payload.doc.data().page3_url : '',
                page4_url: e.payload.doc.data().page4_url ? e.payload.doc.data().page4_url : '',
                page5_url: e.payload.doc.data().page5_url ? e.payload.doc.data().page5_url : '',
                last_save_timestamp: e.payload.doc.data().last_save_timestamp,
                last_publish_timestamp: e.payload.doc.data().last_publish_timestamp,
                store_uid: e.payload.doc.data().store_uid
              };
            });
            this.serializedData = this.current_layout_data[0]?.layout_json;
					  this.serializedMenuData = this.current_menu_data[0]?.menu_json;
					  this.serializedBundleData = this.current_bundle_data[0]?.bundle_json;
					  this.combinedData = [];
            if(this.serializedData?.length>0){
              this.combinedData.push(...this.serializedData);
               }
            if(this.serializedBundleData?.length>0){
                this.combinedData.push(...this.serializedBundleData);
               }
            if(this.serializedMenuData?.length>0){
                this.combinedData.push(...this.serializedMenuData);
              }
					  console.log(this.serializedData, "serializedData")
					  console.log(this.serializedMenuData, "serializedMenuData")
					  console.log(this.serializedBundleData, "serializedBundleData")
					  this.serializedIds = this.combinedData?.map(s => s.id)
          })
        }
      }
    });

    this.loadCartItem()

    this.afs.collection('/lookup-categories').snapshotChanges().subscribe(data => {
      this.categories_list = data.map(e => {
        return {
          uid: e.payload.doc.id,
          value: e.payload.doc.data()['value'],
        };
      });
    });
  }
  async getBundleData() {
		const start_date = new Date();
		const layout_data = await this.afs.collection('/bundles', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

		const layouts = layout_data.docs.map(doc => doc.data());

		const current_layout = layouts.filter((docs: any) => {
			if (docs.include_header_checkbox) {
				this.show_dynamic_header = 'show_dynamic_header';
			} else {
				this.show_dynamic_header = '';
			}

			const layout_start_date = docs.start_date.toDate();
			const endDate = docs.end_date.toDate();

			return layout_start_date <= start_date && endDate > start_date;
		});
		return current_layout;
	}
	async getMenuData() {
		const start_date = new Date();
		const layout_data = await this.afs.collection('/menus', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

		const layouts = layout_data.docs.map(doc => doc.data());

		const current_layout = layouts.filter((docs: any) => {
			if (docs.include_header_checkbox) {
				this.show_dynamic_header = 'show_dynamic_header';
			} else {
				this.show_dynamic_header = '';
			}

			const layout_start_date = docs.start_date.toDate();
			const endDate = docs.end_date.toDate();

			return layout_start_date <= start_date && endDate > start_date;
		});
		return current_layout;
	}
  async add_to_list(item) {
    if (this.cart_ids.includes(item.uid)) {
      this._snackBar.open('Selected item is already present in your list', '', {
        duration: 2000,
      });
    } else {
      item.hide_addtolist_label = item.hide_addtolist_label ? item.hide_addtolist_label : ''
      item.price_format_value1 = item.price_format_value1 ? item.price_format_value1 : ''
      item.price_format_value2 = item.price_format_value2 ? item.price_format_value2 : ''
      item.price_format_value3 = item.price_format_value3 ? item.price_format_value3 : ''
      item.price_label_format = item.price_label_format ? item.price_label_format : ''
      this.show_loader = true
      let msg = this.make_price_message(item?.price_label_format, item?.price_format_value1, item?.price_format_value2, item?.price_format_value3)
      this.cartItem.push({ product: item, count: 1, static: false, priceMessage: msg })
      this.cart_ids.push(item?.uid)
      if (this.current_list_id !== '') {
        await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
        });
      }
      else {
        this.createCartproduct();
      }
      this.show_loader = false
      if (this.serializedIds.includes(item.uid)) {
        let main_item = document.getElementById(item.uid)
        let title_bar: any = document.getElementById(item.uid).parentElement.childNodes[3]
        let delete_icon = main_item.parentElement.lastElementChild.getElementsByTagName('img')
        delete_icon[0].src = '../../../assets/imgs/right.png'
        title_bar.classList.add("test")
      }
      this.filtered_items = []
      this._snackBar.open('Item added in your list', '', {
        duration: 2000,
      });
    }
  }

  createCartproduct() {
    this.afs.collection('/list').add({ items: this.cartItem }).then(docRef => {
      const itemRefx2 = this.afs.doc('/list/' + docRef.id);
      itemRefx2.update({ uid: docRef.id });
      localStorage.setItem('list_id', docRef.id)
      this.current_list_id = docRef.id
      this.qr_value = window.location.href + '/list/' + this.current_list_id;
    });
  }
  make_price_message(formate, value1, value2, value3) {
    if ((value1 !== null || value1 !== '') && (value2 !== null || value2 !== '')) {
      if (formate === 'format6') {
        return `Buy ${value1} Get ${value2} ${value3}`
      }
      if (formate === 'format2' || formate === 'format3' || formate === 'format4') {
        return `$${value1}.${value2}`
      }
      if (formate === 'format5') {
        return `${value1} for $${value2}`
      }
    }
    if (formate === 'format1' && (value1 !== null || value1 !== '')) {
      return `${value1}`
    }
    if ((formate == '' || formate == null) && (value1 == null || value1 == '')) {
      return ''
    }
  }

  async loadCartItem() {
    if (this.current_list_id !== '') {
      this.qr_value = window.location.href + '/list/' + this.current_list_id;
      await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
        this.current_list_data = data.map(e => {
          return {
            uid: e.payload.doc.id,
            items: e.payload.doc.data().items,
          };
        });
        this.current_list_data = this.current_list_data[0]
        this.cartItem = this.current_list_data?.items
        this.current_list_data.items.forEach(i => {
          if (!i.static) {
            this.cart_ids.push(i.product.uid)
          }
        })
      });
    }
  }

  increase_product(i) {
    let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
    temp_itm.count += 1
    this.cartItem[this.cartItem.indexOf(i)] = temp_itm
    localStorage.setItem('cart-item', JSON.stringify(this.cartItem))
  }

  hendle_Add_item(type) {
    if (type === 'add') {
      this.show_add_item = true
    }
    if (type === 'close') {
      this.show_add_item = false
      this.filtered_items = []
    }
  }
  decrease_product(i) {
    let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
    if (temp_itm.count > 1) {
      temp_itm.count -= 1
      this.cartItem[this.cartItem.indexOf(i)] = temp_itm
    } else if (temp_itm.count === 1) {
      this.cartItem.splice(this.cartItem.indexOf(i), 1)
    }
    localStorage.setItem('cart-item', JSON.stringify(this.cartItem))
  }

  remove_one_from_cart(i, type) {
    if (type === 'one') {
      this.cartItem.splice(this.cartItem.indexOf(i), 1)
    }
    if (type == 'selected') {
      this.cartItem = this.cartItem.filter(itm => {
        if (!this.selected_cart_items.includes(itm)) {
          return true
        } else {
          return false
        }
      })
    }
    if (type === 'all') {
      this.cartItem = []
    }
    localStorage.setItem('cart-item', JSON.stringify(this.cartItem))
  }

  product_select_change(event, type, product) {
    if (event.target.checked && (type === 'all')) {
      this.selected_cart_items = this.cartItem
      this.isAllSelected = true
    } else if (!event.target.checked && (type === 'all')) {
      this.selected_cart_items = []
      this.isAllSelected = false
    } else if (event.target.checked && (type === 'single')) {
      this.selected_cart_items.push(product)
    } else if (!event.target.checked && (type === 'single')) {
      this.selected_cart_items.splice(this.selected_cart_items.indexOf(product), 1)
    }
  }

  recipe_search(search_value) {
    this.recipe_list = [];
    if (search_value == "" || search_value == undefined) {
      return false;
    }
    this.loading = true;
    const headerDict = {
      'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
      'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.http
      .get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/findByIngredients?ingredients=' + encodeURIComponent(search_value) + '&number=100&ignorePantry=true&ranking=1&addRecipeInformation=true&fillIngredients=true',
        requestOptions)
      .subscribe(res => {
        this.recipe_list = res;
        this.loading = false;
      });
  }

  sendMail() {
    if (this.current_list_id) {
      let sub = 'Digital Circular Shopping List'
      let body = 'Here is a link to your Digital Circular Shopping List:' + window.location.href + '/list/' + this.current_list_id;
      return `mailto:?subject=${sub}&body=${body}`
    }
  }

  upc_search(search_value) {
    if (search_value == "" || search_value == undefined) {
      return false;
    }
    this.product_image_data = [];

    const headerDict = {
      'Authorization': 'EN AkVOAUiVxPGl4EGqpN2ds4dlnRRncm9jZXJ5c3RvcmVzZXJ2aWNlc30dzywiy9cBfl2zVaLQ1wEBAAAAD0VOLlVzZXJGdWxsTmFtZRRncm9jZXJ5c3RvcmVzZXJ2aWNlcwAAk5x35WQSfpgtUStZMHfkKi+EVJYrbwSimiQ3G45EU4AuV1V0tUUnoLXKrjfiR0VMmG+7PpKyNAxyqWS8+0TRtfh8/5AKKtoRpoL0lyLrCKr+Xr8/GaRKli2MFbP1m+diEBJi0x6ceTH0y0UQjL26On/+jOKPX6IHesK+6UohS/8=',
    }
    const bodyData = {
      "OrderBy": "26834672-7c90-4918-9b19-5bd419023b12",
      "Desc": true,
      "AttributeFilterOperator": "And",
      "AttributeFilters": [
        {
          "AttributeId": "6d030ff8-72ce-4f42-ba53-023f55c53a20",
          "Values": [
            search_value
          ],
          "SearchType": null
        },
        {
          "AttributeId": "26834672-7c90-4918-9b19-5bd419023b12",
          "DateFilter": {
            "Operator": "GreaterThanOrEqualTo",
            "Value": "2019-08-01T05:00:00.000Z"
          }
        }
      ],
      "DataOwner": "07725f7b-db7a-4a89-9ac6-5429a9a51638",
      "PreferredProducts": null,
      "OnHold": false,
      "Language": "en-US"
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.http
      .post('https://api.syndigo.com/api/marketplace/search?skip=0&take=100&companyId=07725f7b-db7a-4a89-9ac6-5429a9a51638',
        bodyData, requestOptions)
      .subscribe(res => { this.product_image_data = res; this.loading = false; });
  }

  gotoRecipeDetail(recipe) {
    this.selected_national_recipe = recipe
    let { analyzedInstructions, extendedIngredients, missedIngredients } = recipe
    let intro = []
    let ingri = []
    if (!analyzedInstructions) {
      const headerDict = {
        'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
        'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
        'Access-Control-Allow-Headers': 'Content-Type',
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      this.http
        .get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/' + recipe.id + '/information?number=12&ignorePantry=true&ranking=1&addRecipeInformation=true&fillIngredients=true',
          requestOptions)
        .subscribe((res: any) => {
          res?.analyzedInstructions[0]?.steps.forEach(i => intro.push(i.step))
          this.selected_national_recipe.analyzedInstructions = intro.join('')
          this.loading = false;
        });
    } else {
      if (typeof analyzedInstructions == 'object') {
        analyzedInstructions[0]?.steps.forEach(i => intro.push(i.step))
        this.selected_national_recipe.analyzedInstructions = intro.join('')
      } else {
        this.selected_national_recipe.analyzedInstructions = analyzedInstructions
      }
    }
    if (recipe.usedIngredients.length > 0) {
      this.selected_national_recipe.missedIngredients = [...this.selected_national_recipe.missedIngredients, ...this.selected_national_recipe.usedIngredients]
    }
    this.selected_national_recipe.missedIngredients.forEach(i => ingri.push(i.name))
    this.selected_national_recipe['ingredients'] = ingri.join(', ')
    if ($('.notification-container').hasClass('dismiss')) {
      $('.notification-container').removeClass('dismiss').addClass('selected').show();
      $("body").addClass("OverlayBody");
      $(".sticky-nav").css("z-index", "0");
    }
    this.appRecipeTourService.cancelTour();

  }

  filter_recipe(name) {
    this.currentCategory = name
    this.openRecipeCategoryPage(name);
  }

  ShowShoppingList() {
    if ($('.notification-container').hasClass('selected')) {
      $('.notification-container').removeClass('selected').addClass('dismiss');
      $("body").removeClass("OverlayBody");
      $(".sticky-nav").css("z-index", "1030");
    }
    $("#ShopDropdown").animate({
      right: "0"
    });
    $('.chervon-down-icon').addClass('d-none');
    $('.chervon-up-icon').removeClass('d-none');
    this.apptourService.cancelTour();
  }
  CloseShoppingList() {
    $("#ShopDropdown").animate({
      right: "-100%"
    });
    $('.chervon-up-icon').addClass('d-none');
    $('.chervon-down-icon').removeClass('d-none');
  }
  goToHome() {
    this.router.navigate(['./home']);
  }
  goToCouponPage() {
    this.router.navigate(['./coupon-list']);
  }
  goToRecipePage() {
    this.router.navigate(['./recipe-list']);
  }
  allSelection() {
    $('.all-selection-btn a').addClass('current');
    $('.coupons-only-selection-btn a').removeClass('current');
    $('.items-list').removeClass('d-none');
  }
  couponSelection() {
    $('.all-selection-btn a').removeClass('current');
    $('.coupons-only-selection-btn a').addClass('current');
    $('.items-list').addClass('d-none');
    $('.with-coupon').removeClass('d-none');
    $('.with-coupon').addClass('d-block');
  }
  bottomSliderDiv() {
    $('.slider').toggleClass('close');
  }

  openHomePage() {
    this.router.navigate(['/' + this.store_name_slug]);
  }

  openCouponsPage() {
    this.router.navigate(['/' + this.store_name_slug + '/coupons']);
  }

  openRecipesPage() {
    this.router.navigate(['/' + this.store_name_slug + '/recipes']);
  }
  openRecipeCategoryPage(category: string) {
    const headerDict = {
      'x-rapidapi-host': 'spoonacular-recipe-food-nutrition-v1.p.rapidapi.com',
      'x-rapidapi-key': 'ac11993d4dmshdb330c5eced5971p172ee8jsn5f25ece18c84',
      'Access-Control-Allow-Headers': 'Content-Type',
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    this.recipe_list = []
    this.loading = true
    if (category == 'all') {
      this.http
        .get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/complexSearch?number=300000&addRecipeInformation=true&fillIngredients=true',
          requestOptions)
        .subscribe((res: any) => {
          this.recipe_list = res.results;
          this.loading = false;
        });
      this.router.navigate(['/' + this.store_name_slug + '/recipes']);
    }
    else {
      this.http
        .get('https://spoonacular-recipe-food-nutrition-v1.p.rapidapi.com/recipes/complexSearch?number=300000&addRecipeInformation=true&fillIngredients=true&type=' + category,
          requestOptions)
        .subscribe((res: any) => {
          this.recipe_list = res.results;
          this.loading = false;
        });
      this.router.navigate(['/' + this.store_name_slug + '/recipes', category]);
    }
  }
  ShowRecipe() {
    $(".recipe-btn").addClass("active");
    $(".recipe-box-btn").removeClass("active");
    $(".Recipe").removeClass("d-none");
    $(".RecipeBox").addClass("d-none")
  }
  ShowRecipeBox() {
    $(".recipe-box-btn").addClass("active");
    $(".recipe-btn").removeClass("active");
    $(".RecipeBox").removeClass("d-none");
    $(".Recipe").addClass("d-none")
  }
  ShowFilePanel(uid) {
    this.selected_recipe_id = uid;
    this.products_array_data = this.recipes_list[this.selected_recipe_id].products_array;
    if ($('.notification-container').hasClass('dismiss')) {
      $('.notification-container').removeClass('dismiss').addClass('selected').show();
      $("body").addClass("OverlayBody");
      $(".sticky-nav").css("z-index", "0");
    }
    //preventDefault();
  }

  ShowRecipeboxFilePanel(uid) {
    this.selected_recipe_id = uid;
    this.products_array_data = this.recipes_box_list[this.selected_recipe_id].products_array;
    if ($('.notification-container-recipebox').hasClass('dismiss')) {
      $('.notification-container-recipebox').removeClass('dismiss').addClass('selected').show();
      $("body").addClass("OverlayBody");
      $(".sticky-nav").css("z-index", "0");
    }
  }

  CloseRecipeboxFilePanel() {
    if ($('.notification-container-recipebox').hasClass('selected')) {
      $('.notification-container-recipebox').removeClass('selected').addClass('dismiss');
      $("body").removeClass("OverlayBody");
      $(".sticky-nav").css("z-index", "1030");
    }
  }

  showInstructions() {
    this.show_instructions = !this.show_instructions
    let instructions_icon = document.getElementById('instructions_icon')
    instructions_icon.style.transform = this.show_instructions ? 'rotate(180deg)' : 'unset'

  }

  CloseFilePanel() {
    if ($('.notification-container').hasClass('selected')) {
      $('.notification-container').removeClass('selected').addClass('dismiss');
      $("body").removeClass("OverlayBody");
      $(".sticky-nav").css("z-index", "1030");
    }
  }

  copy_link() {
    var textarea = document.createElement("textarea");
    textarea.textContent = window.location.href + '/list/' + this.current_list_id;
    textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      document.execCommand("copy");  // Security exception may be thrown by some browsers.
    }
    catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
    }
    finally {
      document.body.removeChild(textarea);
    }
  }

  getImage(val) {
    if (this.cartItem && !this.cartItem.some(x => x.product.uid == val)) {
      return '../../assets/imgs/plus.png'
    }
    else {
      return '../../assets/imgs/right.png'
    }
  }

  add_recipe_ingredients(val) {
    let data = { ...val }
    if (!this.recipes_box_list.some(x => x.uid == data.uid)) {
      this.recipes_box_list.push(data)
      let json_recipes_box_list = JSON.stringify(this.recipes_box_list)
      document.cookie = `my_recipe_item=${json_recipes_box_list}`
      this._snackBar.open("Recipe Added succesfully", '', {
        duration: 2000,
      });
    } else {
      this._snackBar.open("Recipe is already in recipe box", '', {
        duration: 2000,
      });
    }
    this.CloseFilePanel()
    this.analytics.logEvent('@shoppingList_addRecipe_count', {
      message: 'Recipe add successfully.'
  })
  }

  async add_all_ingredients(val) {
    if (this.current_list_id) {
      let ingredient
      if (val.missedIngredients) {
        ingredient = val.missedIngredients
      } else if (val.products_array) {
        ingredient = val.products_array
      } else {
        ingredient = val
      }
      for (let a = 0; a < ingredient.length; a++) {
        if (!this.cartItem.some(x => x.product.uid == (ingredient[a].id ? ingredient[a].id : ingredient[a].product_uid))) {
          let data = { ...ingredient[a] }
          if (!data?.uid && data?.product_uid) {
            data.uid = data.product_uid.toString()
          }
          if (!data?.name && data?.product_description) {
            data.name = data.product_description
          }
          if (!data?.image && data?.product_image) {
            data.image = data.product_image
          }
          if (!data?.uid && data?.id) {
            data.uid = data.id.toString()
          }
          this.cartItem.push({ product: data, count: 1, static: false, isRecipe: true })
          this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
          });
         await this.loadCartItem();
        }
      }
    }
    else {
      await this.createCart(val, 'ingredients')
    }
    this.analytics.logEvent('@shoppingList_addRecipe_count', {
      message: 'Recipe add successfully.'
  })
  }

  async add_recipe(val) {
    if (this.current_list_id) {
      if (!this.cartItem.some(x => x.product.uid == (val?.id ? val?.id : val?.product_uid))) {
        // let data = {...val}
        if (!val?.uid && val?.id) {
          val.uid = val.id.toString()
        }
        if (!val?.uid && val?.product_uid) {
          val.uid = val.product_uid.toString()
        }
        if (!val?.name && val?.title) {
          val.name = val.title
        }
        if (!val?.name && val?.product_description) {
          val.name = val.product_description
        }
        if (!val?.image && val?.product_image) {
          val.image = val.product_image
        }
        this.cartItem.push({ product: val, count: 1, static: false, isRecipe: true })
        this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
        });
       await this.loadCartItem();
      } else {
        this._snackBar.open('Selected recipe ingredient is already present in your list', '', {
          duration: 2000,
        });
      }
    }
    else {
     await this.createCart(val, "recipe_item")
    }
    this.analytics.logEvent('@shoppingList_addRecipe_count', {
      message: 'Recipe add successfully.'
  })
  }

  async createCart(val, type) {
    // let data = {...val}
    if (type == 'recipe_item') {
      if (!val?.uid && val?.id) {
        val.uid = val.id.toString()
      }
      if (!val?.uid && val?.product_uid) {
        val.uid = val.product_uid.toString()
      }
      if (!val?.name && val?.title) {
        val.name = val.title
      }
      if (!val?.name && val?.product_description) {
        val.name = val.product_description
      }
      if (!val?.image && val?.product_image) {
        val.image = val.product_image
      }
      this.cartItem.push({ product: val, count: 1, static: false, isRecipe: true })
      await this.afs.collection('/list/').add({ items: this.cartItem }).then(docRef => {
        const itemRefx2 = this.afs.doc('/list/' + docRef.id);
        itemRefx2.update({ uid: docRef.id });
        localStorage.setItem('list_id', docRef.id)
        this.current_list_id = docRef.id
        this.qr_value = window.location.href + '/list/' + this.current_list_id;
      });
    } else if (type == 'ingredients') {
      let ingredient
      if (val.missedIngredients) {
        ingredient = val.missedIngredients
      } else if (val.products_array) {
        ingredient = val.products_array
      } else {
        ingredient = val
      }
      for (let a = 0; a < ingredient.length; a++) {
        if (!this.cartItem.some(x => x.product.uid == (ingredient[a]?.id ? ingredient[a]?.id : ingredient[a]?.product_uid))) {
          let data = { ...ingredient[a] }
          if (!data?.uid && data?.product_uid) {
            data.uid = data.product_uid.toString()
          }
          if (!data?.name && data?.product_description) {
            data.name = data.product_description
          }
          if (!data?.image && data?.product_image) {
            data.image = data.product_image
          }
          if (!data?.uid && data?.id) {
            data.uid = data.id.toString()
          }
          this.cartItem.push({ product: data, count: 1, static: false, isRecipe: true })
          await this.afs.collection('/list/').add({ items: this.cartItem }).then(docRef => {
            const itemRefx2 = this.afs.doc('/list/' + docRef.id);
            itemRefx2.update({ uid: docRef.id });
            localStorage.setItem('list_id', docRef.id)
            this.current_list_id = docRef.id
            this.qr_value = window.location.href + '/list/' + this.current_list_id;
          });
        }
      }
    }
    this.loadCartItem();
  }
  startRecipeSlideTour() {
    this.appRecipeSlideoutTourService.cancelTour();
    localStorage.setItem(this.recipeSlideoutTourSeenFlag, 'false')
    this.appRecipeSlideoutTourService.startTour();

  }
}
